import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { Capacitor } from '@capacitor/core'

// uikit things
//@ts-ignore-next-line
import Vuikit from 'vuikit'
//@ts-ignore-next-line
import VuikitIcons from '@vuikit/icons'
import './assets/scss/uikit-theme.scss'
import 'uikit/dist/js/uikit.min'
import { AzureLogin, azureConfig } from '@/azure/azureLogin'

Vue.use(Vuikit)
Vue.use(VuikitIcons)

// custom css
import './assets/scss/custom.scss'

// bootstrap
import './assets/scss/bootstrap-custom.scss'
import '@popperjs/core'
//@ts-ignore-next-line
import * as bootstrap from 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import Multiselect from 'vue-multiselect'

Vue.component('multiselect', Multiselect)

// good tables things
//@ts-ignore-next-line
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin)

/**
 * set msal instance and azure handler to vue prototype
 * to have an unique instance for all use
 */
Vue.prototype.$azureAuth = process.env.VUE_APP_AUTH_AZURE === 'true'
Vue.prototype.$azureHandler = new AzureLogin(azureConfig, async (authenticationResult) => {
    await store.dispatch('login/handleResponse', authenticationResult)
})

// fontawesome
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import {
    faFileArrowDown,
    faPlus,
    faScrewdriverWrench,
    faUsers,
    faLock,
    faEyeSlash,
    faGear,
    faPen,
    faBell,
    faCamera,
    faBars,
    faImage,
    faVideo,
    faPlay,
    faStop,
    faClose,
    faRefresh,
} from '@fortawesome/free-solid-svg-icons'
import {
    faEye,
    faUser,
    faClipboard,
    faPenToSquare,
    faFlag,
    faCircleQuestion,
    faFloppyDisk,
    faTrashCan,
} from '@fortawesome/free-regular-svg-icons'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(
    faEye,
    faFileArrowDown,
    faBars,
    faPlus,
    faScrewdriverWrench,
    faUsers,
    faUser,
    faClipboard,
    faPenToSquare,
    faFlag,
    faCircleQuestion,
    faLock,
    faEyeSlash,
    faGear,
    faPen,
    faFloppyDisk,
    faTrashCan,
    faBell,
    faCamera,
    faImage,
    faVideo,
    faPlay,
    faStop,
    faClose,
    faRefresh,
)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'leaflet/dist/leaflet.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
//@ts-ignore-next-line
import leaflet from 'leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'
leaflet.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

//@ts-ignore-next-line
import VueMq from 'vue-mq'
Vue.use(VueMq, {
    breakpoints: {
        xs: 576,
        sm: 749,
        md: 929,
        lg: 1106,
        xl: 1272,
        xxl: 1425,
        xxxl: 1568,
        big: 1694,
        giga: Infinity,
    },
})

Vue.config.productionTip = false

Vue.prototype.$bootstrap = bootstrap
Vue.prototype.$leaflet = leaflet
Vue.prototype.$env = process.env
Vue.prototype.$capacitor = Capacitor

// prototypes
import util from './helpers/util'
import camera from './helpers/camera'
import backendApi from '@/backend/api'
import * as Status from './Enum/Status'

Vue.prototype.$util = util
Vue.prototype.$http = backendApi
Vue.prototype.$camera = camera
Vue.prototype.$Status = Status

// injection des composants
//@ts-ignore-next-line
import SimpleAccordion from '@/components/SimpleAccordion'
//@ts-ignore-next-line
import BlankModal from '@/components/BlankModal'
//@ts-ignore-next-line
import DatePicker from '@/components/commons/DatePicker'
//@ts-ignore-next-line
import UploadPhoto from '@/components/commons/UploadPhoto'
//@ts-ignore-next-line
import FormInput from '@/components/commons/FormInput'
//@ts-ignore-next-line
import MeasurementMap from '@/components/commons/MeasurementMap'
//@ts-ignore-next-line
import Icon from '@/components/commons/Icon'
//@ts-ignore-next-line
import Signature from '@/components/commons/Signature'
//@ts-ignore-next-line

Vue.component('SimpleAccordion', SimpleAccordion)
Vue.component('BlankModal', BlankModal)
Vue.component('DatePicker', DatePicker)
Vue.component('UploadPhoto', UploadPhoto)
Vue.component('FormInput', FormInput)
Vue.component('MeasurementMap', MeasurementMap)
Vue.component('Icon', Icon)
Vue.component('Signature', Signature)

Vue.filter('humanReadableDate', function (value: string) {
    if (!value) return ''
    const date = new Date(value)
    return new Intl.DateTimeFormat(navigator.language, { dateStyle: 'medium', timeStyle: 'medium' } as never).format(date)
})

Vue.filter('amountFormat', function (value: number) {
    if (!value) return ''
    return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'EUR' }).format(value)
})

if (process.env.VUE_APP_AUTH_AZURE === 'true') {
    store.dispatch('login/loginAzureUpdate', { init: true }).finally(() => {
        new Vue({
            router,
            store,
            render: (h) => h(App),
        }).$mount('#app')
    })
} else {
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app')
}
