<template>
    <div class="p-20">
        <vue-good-table :columns="columns" :rows="documents">
            <template slot="loadingContent">
                <span class="vgt-loading__content">Téléchargement...</span>
            </template>
            <template v-slot:table-row="props">
                <div v-if="props.column.field === 'createdAt'">
                    {{ `${$util.formatDate(props.row.createdAt, 'DD/MM/YYYY', true)}` }}
                </div>
                <div v-else-if="props.column.field === 'updatedAt'">
                    {{ `${$util.formatDate(props.row.updatedAt, 'DD/MM/YYYY', true)}` }}
                </div>
            </template>
        </vue-good-table>
    </div>
</template>
<script>
export default {
    props: {
        documents: {
            type: Array,
            require: false,
        },
    },
    data() {
        return {
            columns: [
                {
                    label: 'Nom',
                    field: 'originalName',
                },
                {
                    label: 'Date de création',
                    field: 'createdAt',
                },
                {
                    label: 'Date de modification',
                    field: 'updatedAt',
                },
            ],
        }
    },
}
</script>
