export default {
    namespaced: true,
    state: () => ({
        domains: [],
        actions: [],
        families: [],
        types: [],
        options: [],
    }),
    mutations: {
        SET_DOMAINS(store, payload) {
            store.domains = payload
        },
        SET_ACTIONS(store, payload) {
            store.actions = payload
        },
        SET_FAMILIES(store, payload) {
            store.families = payload
        },
        SET_TYPES(store, payload) {
            store.types = payload
        },
        SET_OPTIONS(store, payload) {
            store.options = payload
        },
    },
    actions: {
        setDomains(context, payload) {
            context.commit('SET_DOMAINS', payload)
        },
        setActions(context, payload) {
            context.commit('SET_ACTIONS', payload)
        },
        setFamilies(context, payload) {
            context.commit('SET_FAMILIES', payload)
        },
        setTypes(context, payload) {
            context.commit('SET_TYPES', payload)
        },
        setOptions(context, payload) {
            context.commit('SET_OPTIONS', payload)
        },
    },
    getters: {
        getDomains: (state) => {
            return state.domains
        },
        getActions: (state) => {
            return state.actions
        },
        getFamilies: (state) => {
            return state.families
        },
        getTypes: (state) => {
            return state.types
        },
        getOptions: (state) => {
            return state.options
        },
    },
}
