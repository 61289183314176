export class PerfeauUrlGenerator {
    static baseUrl = process.env.VUE_APP_PERFEAU_BASE_URL

    /**
     * @waring The final shchema of the url is not defined yet
     * @param osNumber
     * @return {string}
     */
    static generateOsLinkUrl(osNumber) {
        return `${PerfeauUrlGenerator.baseUrl}/travaux/os?statutFiltre=tous&OsTr%5Bindex_form_date_debut%5D=1970-01-01&OsTr%5Bnumero_eboost%5D=${osNumber}`
    }
}
