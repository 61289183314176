<template>
    <div>
        <form ref="form">
            <fieldset class="uk-fieldset">
                <div class="uk-margin d-none">
                    <label for="action">Action</label>
                    <select
                        :disabled="
                            terminerChantier ||
                            createInventory ||
                            makeMarkup ||
                            declareMyArrival ||
                            makeMarking ||
                            makeMeasurement ||
                            resumeIntervention ||
                            definitelyEnd
                        "
                        id="action"
                        class="uk-select"
                        v-model="action"
                    >
                        <option :value="undefined" v-if="!actions[0]">Pas de list</option>
                        <option v-for="(it, idx) in actions" :key="idx" :value="it">{{ it.wording }}</option>
                    </select>
                </div>

                <div
                    class="uk-margin"
                    v-if="
                        action &&
                        !(
                            terminerChantier ||
                            createInventory ||
                            makeMarkup ||
                            declareMyArrival ||
                            makeMarking ||
                            makeMeasurement ||
                            resumeIntervention ||
                            definitelyEnd
                        )
                    "
                >
                    <label for="famille">Famille</label>
                    <!-- <select :disabled="terminerChantier || createInventory || makeMarkup || declareMyArrival || makeMarking || makeMeasurement || resumeIntervention || definitelyEnd" id="action" class="uk-select" v-model="family">
            <option :value="undefined" v-if="!action || !families[0]">Pas de list</option>
            <option v-else v-for="(it, idx) in families" :key="idx" :value="it">{{it.wording}}</option>
          </select> -->

                    <div class="radio-container" id="famille">
                        <div class="row">
                            <div class="col-lg-4" v-if="!action || !families[0]">
                                <div class="d-flex align-items-center">
                                    <input
                                        type="radio"
                                        name="famille"
                                        v-model="family"
                                        :value="undefined"
                                        :disabled="
                                            terminerChantier ||
                                            createInventory ||
                                            makeMarkup ||
                                            declareMyArrival ||
                                            makeMarking ||
                                            makeMeasurement ||
                                            resumeIntervention ||
                                            definitelyEnd
                                        "
                                    />
                                    <label class="ms-1 mb-0">Pas de list</label>
                                </div>
                            </div>

                            <div class="col-lg-4 my-1" v-else v-for="(it, idx) in families" :key="`family-${idx}`">
                                <div class="d-flex align-items-center">
                                    <input
                                        :id="`family-${it.id}`"
                                        type="radio"
                                        name="famille"
                                        v-model="family"
                                        :value="it"
                                        :disabled="
                                            terminerChantier ||
                                            createInventory ||
                                            makeMarkup ||
                                            declareMyArrival ||
                                            makeMarking ||
                                            makeMeasurement ||
                                            resumeIntervention ||
                                            definitelyEnd
                                        "
                                    />
                                    <label :for="`family-${it.id}`" class="ms-1 mb-0">{{ it.wording }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="uk-margin"
                    v-if="
                        family &&
                        !(
                            createInventory ||
                            makeMarkup ||
                            declareMyArrival ||
                            makeMarking ||
                            makeMeasurement ||
                            resumeIntervention ||
                            definitelyEnd
                        )
                    "
                >
                    <label for="event">Type d'évenements</label>
                    <!-- <select :disabled="createInventory || makeMarkup || declareMyArrival || makeMarking || makeMeasurement || resumeIntervention || definitelyEnd" id="event" class="uk-select" v-model="type">
            <option :value="undefined" v-if="!family || !family.types[0]">Pas de list</option>
            <option v-else v-for="(it, idx) in family.types" :key="idx" :value="it">{{it.wording}}</option>
          </select> -->

                    <div class="radio-container" id="event">
                        <div class="row">
                            <div class="col-lg-4" v-if="!family || !family.types[0]">
                                <div class="d-flex align-items-center">
                                    <input
                                        id="event-undefined"
                                        type="radio"
                                        name="event"
                                        v-model="type"
                                        :value="undefined"
                                        :disabled="
                                            createInventory ||
                                            makeMarkup ||
                                            declareMyArrival ||
                                            makeMarking ||
                                            makeMeasurement ||
                                            resumeIntervention ||
                                            definitelyEnd
                                        "
                                    />
                                    <label :for="`event-undefined`" class="ms-1 mb-0">Pas de list</label>
                                </div>
                            </div>

                            <div class="col-lg-4 my-1" v-else v-for="(it, idx) in family.types" :key="`event-${idx}`">
                                <div class="d-flex align-items-center">
                                    <input
                                        :id="`event-${it.id}`"
                                        type="radio"
                                        name="event"
                                        v-model="type"
                                        :value="it"
                                        :disabled="
                                            createInventory ||
                                            makeMarkup ||
                                            declareMyArrival ||
                                            makeMarking ||
                                            makeMeasurement ||
                                            resumeIntervention ||
                                            definitelyEnd
                                        "
                                    />
                                    <label :for="`event-${it.id}`" class="ms-1 mb-0">{{ it.wording }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="uk-margin" v-if="type && type.has_photo !== 'OFF'">
                    <label for="photos">{{
                        `Photos | Vidéos [ ${type.has_photo == 'ON' ? 'Obligatoire' : 'Optionnel'} ]`
                    }}</label>
                    <div id="photos">
                        <upload-photo
                            :num-photo="1"
                            ref="photo1"
                            class="photo photo1"
                            v-model="form.photo1"
                            :upload="true"
                            :event="true"
                            :openMettrage="openMettrage"
                            :makeMeasurement="makeMeasurement"
                        ></upload-photo>
                        <upload-photo
                            :num-photo="2"
                            ref="photo2"
                            class="photo photo2"
                            v-model="form.photo2"
                            :upload="true"
                            :openMettrage="openMettrage"
                            :makeMeasurement="makeMeasurement"
                        ></upload-photo>
                    </div>
                </div>
                <div class="uk-margin" v-if="type && type.has_comment !== 'OFF'">
                    <label for="comment"
                        >Commentaire {{ this.type.has_comment === 'ON' ? '[ Obligatoire ]' : '[Optionnel]' }}</label
                    >
                    <textarea id="comment" class="uk-textarea" rows="4" v-model="form.comment"></textarea>
                </div>
                <div class="uk-margin" v-if="shouldSign">
                    <label for="signature">Signature</label>
                    <upload-photo
                        ref="signature"
                        class="photo photo1"
                        v-model="signature"
                        :upload="true"
                        :clickable="false"
                        :signature="true"
                        @click="showSignature"
                    >
                    </upload-photo>
                </div>
            </fieldset>
        </form>
        <div v-if="makeMeasurement && isShowMap">
            <div class="d-flex justify-content-center pb-2">
                <button @click="downloadImageCarte()" class="btn btn-success btn-sm">
                    Importer
                    <div v-if="loadingImportPhoto" uk-spinner="ratio:0.5"></div>
                </button>
            </div>
            <measurement-map ref="mycarte" id="mycarte" />
        </div>
        <blank-modal ref="modalSignature" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Signature</legend>
            </template>
            <template #modalBody>
                <signature ref="sign"></signature>
            </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="takeSignature">Ok</button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import domtoimage from 'dom-to-image'
export default {
    name: 'NewEventModal',
    props: {
        orderMission: {
            type: Object,
            required: true,
        },
        terminerChantier: {
            type: Boolean,
            required: false,
            default: false,
        },
        createInventory: {
            type: Boolean,
            required: false,
            default: false,
        },
        declareMyArrival: {
            type: Boolean,
            required: false,
            default: false,
        },
        makeMarkup: {
            type: Boolean,
            required: false,
            default: false,
        },
        makeMarking: {
            type: Boolean,
            required: false,
            default: false,
        },
        makeMeasurement: {
            type: Boolean,
            required: false,
            default: false,
        },
        resumeIntervention: {
            type: Boolean,
            required: false,
            default: false,
        },
        definitelyEnd: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDeclareEvent: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isShowMap: false,
            loadingImportPhoto: false,
            form: {
                event: undefined,
                comment: '',
                photo1: undefined,
                photo2: undefined,
            },
            numPhoto: 1,
            type: undefined,
            action: undefined,
            family: undefined,
            accountType: this.$store.state.login.user.userData.accountType,
            families: [],
            signature: undefined,
            isSignature: false,
        }
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        convertBase64ToFile(b64, filename) {
            let arr = b64.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n)

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }

            return new File([u8arr], filename, { type: mime })
        },
        async downloadImageCarte() {
            this.loadingImportPhoto = true
            const that = this
            domtoimage.toJpeg(document.getElementById('mycarte'), { quality: 0.95 }).then(function (dataUrl) {
                const file = that.convertBase64ToFile(dataUrl, 'carte.png')
                if (that.numPhoto === 1) {
                    that.$refs.photo1.uploadFile(file)
                } else {
                    that.$refs.photo2.uploadFile(file)
                }
                that.isShowMap = false
                that.loadingImportPhoto = false
            })
        },
        /**
         * @param afterSubmit {("comment" | "terminated") | undefined}
         */
        submit(afterSubmit) {
            if (!this.type) {
                this.$store.dispatch('notifications/showWarning', 'Vous devriez sélectionner un type d`évenement')
                return
            }
            if (
                this.type.has_photo === 'ON' &&
                (!this.form.photo1 || !this.form.photo1.file) &&
                (!this.form.photo2 || !this.form.photo2.file)
            ) {
                this.$store.dispatch('notifications/showWarning', 'Photos obligatoires')
                return
            }
            if (this.type.has_comment === 'ON' && !this.form.comment) {
                this.$store.dispatch('notifications/showWarning', 'Commentaire obligatoire')
                return
            }
            if (
                this.type.code === 'TERMIN' &&
                this.orderMission.is_signature_required &&
                (!this.signature || !this.signature.file)
            ) {
                this.$store.dispatch('notifications/showWarning', 'Signature obligatoire')
                return
            }
            const payload = this.formatData(this.form)
            this.$emit('add-event', payload, afterSubmit)
        },
        formatData(data) {
            const ret = {
                event_definition_id: this.type.id,
            }
            const photos = []
            if (this.type.has_comment !== 'OFF') ret.comment = data.comment
            if (this.type.has_photo !== 'OFF') {
                if (this.form.photo1 && this.form.photo1.file) {
                    photos.push(this.form.photo1.doc)
                }
                if (this.form.photo2 && this.form.photo2.file) {
                    photos.push(this.form.photo2.doc)
                }
            }
            if (this.terminerChantier) {
                ret.photos = photos.map((p) => {
                    return {
                        id: p.id,
                    }
                })
            } else {
                let i = 0
                for (const doc of photos) {
                    ret[`photos[${i}][id]`] = doc.id
                    i++
                }
            }
            if (this.type.code === 'TERMIN' && this.orderMission.is_signature_required) {
                ret.signature = this.signature.doc.id
            }
            return ret
        },
        async getAllTypes() {
            const rows = await this.$http.getAllTypes()
            this.$store.dispatch('adminEvent/setTypes', rows)
        },
        clearInput() {
            setTimeout(() => {
                this.form = {
                    event: undefined,
                    comment: '',
                    photo1: undefined,
                    photo2: undefined,
                }
                if (this.type && this.type.has_photo !== 'OFF') {
                    // add condition if type is not undefined
                    this.$refs.photo1.removePhoto()
                    this.$refs.photo2.removePhoto()
                }
            }, 200)
        },
        makeTerminateToDefault() {
            this.clearInput()
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'EVT')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.family = this.action.event_families.find((a) => a.code === 'INFOS')
            if (!this.family) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.type = this.family.event_definitions.find((element) => element.code === 'ECARTS')
        },
        makeDefinitelyEndToDefault() {
            this.clearInput()
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'ETA')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.family = this.action.event_families.find((a) => a.code === 'REALI')
            if (!this.family) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.type = this.family.event_definitions.find((element) => element.code === 'TERMIN')
            if (!this.type) {
                this.$emit('no-terminer-chantier')
            }
        },
        makeEventToDefault() {
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'EVT')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }

            this.family = undefined
            this.type = undefined
            // this.family = this.action.event_families.find(a => a.code === 'DEMAR')
            // if (!this.family) {
            //   this.$emit('no-terminer-chantier')
            //   return
            // }
            // this.type = this.family.event_definitions.find(element => element.code === "AUTORI")
            // if (!this.type) {
            //   this.$emit('no-terminer-chantier')
            // }
        },
        createInventoryToDefault() {
            this.clearInput()
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'ETA')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.family = this.action.event_families.find((a) => a.code === 'ARRIV')
            if (!this.family) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.type = this.family.event_definitions.find((element) => element.code === 'ETATLI')
            if (!this.type) {
                this.$emit('no-terminer-chantier')
            }
        },
        makeMarkupToDefault() {
            this.clearInput()
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'ETA')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.family = this.action.event_families.find((a) => a.code === 'ARRIV')
            if (!this.family) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.type = this.family.event_definitions.find((element) => element.code === 'BALISE')
            if (!this.type) {
                this.$emit('no-terminer-chantier')
            }
        },
        declareMyArrivalToDefault() {
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'ETA')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.family = this.action.event_families.find((a) => a.code === 'ARRIV')
            if (!this.family) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.type = this.family.event_definitions.find((element) => element.code === 'ARRIVE')
            if (!this.type) {
                this.$emit('no-terminer-chantier')
            }
        },
        makeMarkingAndPiqueToDefault() {
            this.clearInput()
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'ETA')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.family = this.action.event_families.find((a) => a.code === 'ARRIV')
            if (!this.family) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.type = this.family.event_definitions.find((element) => element.code === 'MARQUA')
            if (!this.type) {
                this.$emit('no-terminer-chantier')
            }
        },
        makeMeasurementToDefault() {
            this.clearInput()
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'ETA')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.family = this.action.event_families.find((a) => a.code === 'ARRIV')
            if (!this.family) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.type = this.family.event_definitions.find((element) => element.code === 'METRES')
            if (!this.type) {
                this.$emit('no-terminer-chantier')
            }
        },
        resumeInterventionToDefault() {
            this.clearInput()
            const domain = this.domaines.find((d) => d.code == 'TRA')
            if (!domain) {
                // this.$emit('no-terminer-chantier')
                return
            }
            this.action = domain.event_actions.find((a) => a.code === 'ETA')
            if (!this.action) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.family = this.action.event_families.find((a) => a.code === 'REALI')
            if (!this.family) {
                this.$emit('no-terminer-chantier')
                return
            }
            this.type = this.family.event_definitions.find((element) => element.code === 'REPREN')
            if (!this.type) {
                this.$emit('no-terminer-chantier')
            }
        },
        getAvailableEventFromFamily(family) {
            if (!family) {
                return []
            }
            let filtered = []
            if (!this.orderMission.status) filtered = JSON.parse(JSON.stringify(family.event_definitions))
            else {
                filtered = family.event_definitions.filter((t) => {
                    if (!t.status) return true
                    return t.status.find((s) => s.id === this.orderMission.status.id)
                })
            }
            if (this.accountType === 'workerAccount') {
                return filtered.filter((t) => t.available_for_worker_profile)
            } else if (this.accountType === 'agencyAccount') {
                return filtered.filter((t) => t.available_for_agency_profile)
            }
            return []
        },
        showSignature() {
            this.$refs.sign.clear()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalSignature.$el).show()
        },
        async takeSignature() {
            const file = this.$refs.sign.getFile()
            if (file) {
                await this.$util.fileTob64(file)
                this.$refs.signature.uploadFile(file)
                this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalSignature.$el).hide()
            }
        },
        async openMettrage(numPhoto) {
            this.numPhoto = numPhoto
            const coordinates = await this.$store.dispatch('users/updatePosition').catch(() => {})
            this.isShowMap = true
            if (!coordinates) {
                this.$store.dispatch(
                    'notifications/showWarning',
                    'Veuillez autoriser la géolocalisation sur votre appareil',
                )
            }
        },
    },
    computed: {
        ...mapState('domain', {
            domaines: 'domains',
        }),
        ...mapState('adminEvent', { types: 'types' }),
        ...mapGetters({
            optionsEvent: 'event/getOptionsEvent',
        }),
        actions() {
            const doms = []
            for (const d of this.domaines) {
                for (const action of d.event_actions) {
                    const eventFamily = action.event_families.map((item) =>
                        item.event_definitions.map((elem) => elem.available_for_agency_profile),
                    )
                    const flatEventFamily = eventFamily.flat()
                    if (this.$store.getters['login/isWorker']) {
                        doms.push(action)
                    } else {
                        if (flatEventFamily.some((item) => item)) {
                            doms.push(action)
                        }
                    }
                }
            }
            return doms
        },
        shouldSign() {
            if (!this.type) return false
            return this.type.code === 'TERMIN' && this.orderMission.is_signature_required
        },
        isSubmitable() {
            if (!this.type) return false
            if (
                this.type.has_photo === 'ON' &&
                (!this.form.photo1 || !this.form.photo1.file) &&
                (!this.form.photo2 || !this.form.photo2.file)
            )
                return false
            if (this.type.has_comment === 'ON' && !this.form.comment) return false
            if (
                this.type.code === 'TERMIN' &&
                this.orderMission.is_signature_required &&
                (!this.signature || !this.signature.file)
            )
                return false

            return true
        },
    },
    mounted() {
        Promise.all([this.$store.dispatch('domain/updateDomains'), this.getAllTypes()]).then(() => {
            /**
             * emit event to alert parent that child is mounted
             * this event make parent to save in state that child is mounted
             * to allow parent use computed child as conditional display
             */
            this.$emit('mounted')
        })
    },
    watch: {
        actions(val) {
            if (val && val[0]) {
                this.action = val[0]
            }
        },
        action(val) {
            this.families = []
            if (!val || !val.event_families || !val.event_families[0]) {
                this.family = undefined
            } else {
                // this.family = undefined
                for (const f of val.event_families) {
                    const types = this.getAvailableEventFromFamily(f)
                    f.types = []
                    if (types[0]) {
                        f.types = types
                        this.families.push(f)
                    }
                }

                if (this.isDeclareEvent) {
                    this.family = undefined
                } else {
                    if (this.families[0] && !this.terminerChantier && !this.definitelyEnd)
                        this.family = this.families[0]
                    else if (!this.terminerChantier && !this.definitelyEnd) {
                        this.family = undefined
                    }
                }
            }
        },
        family(val) {
            if (this.isDeclareEvent) {
                this.type = undefined
            } else if (this.definitelyEnd) {
                this.type = this.family.event_definitions.find((element) => element.code === 'TERMIN')
            } else {
                if (!val) this.type = undefined
                else if (
                    val.types &&
                    val.types[0] &&
                    !this.createInventory &&
                    !this.makeMarkup &&
                    !this.makeMarking &&
                    !this.makeMeasurement
                )
                    this.type = val.types[0]
            }
        },
    },
}
</script>

<style scoped>
label {
    margin-bottom: 10px;
}

#photos {
    display: flex;
    height: auto;
}

.photo {
    width: 100px;
}

.photo1 {
    margin-right: 20px;
}

.mr {
    margin-left: 7px;
}

.radio-container {
    padding: 2px;
    /* border: 1px solid #999999; */
}
</style>
