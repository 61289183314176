<template>
    <div>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <li>
                <a
                    href="#"
                    @click.prevent="
                        $router.push({ name: rootBaseName })
                        $emit('nav:close')
                    "
                    style="color: white"
                >
                    <font-awesome-icon icon="fa-regular fa-clipboard" class="uk-margin-small-right" />
                    Accueil</a
                >
            </li>
            <vk-nav-item-divider />
        </ul>
        <ItemsAgencyAccount v-if="user.userData.accountType === 'agencyAccount'" v-on="$listeners" />
        <ItemsWorkerAccount v-if="user.userData.accountType === 'workerAccount'" v-on="$listeners" />
        <item-sub-contracting-companies
            v-if="$store.state.login.user.userData.roles.includes('admin_func')"
            @nav:close="$emit('nav:close')"
        ></item-sub-contracting-companies>
        <item-repository-of-suez-regions
            v-if="$store.state.login.user.userData.roles.includes('admin_func')"
            @nav:close="$emit('nav:close')"
        ></item-repository-of-suez-regions>
        <item-flow
            v-if="$store.state.login.user.userData.roles.includes('admin_func')"
            @nav:close="$emit('nav:close')"
        ></item-flow>
        <items-event
            v-if="$store.state.login.user.userData.roles.includes('admin_func')"
            @nav:close="$emit('nav:close')"
        ></items-event>
        <items-form
            v-if="$store.state.login.user.userData.roles.includes('admin_func')"
            @nav:close="$emit('nav:close')"
        ></items-form>
        <ul
            v-if="$store.state.login.user.userData.roles.includes('admin_func')"
            class="uk-nav-default uk-nav-parent-icon"
            uk-nav
        >
            <li>
                <a
                    href="#"
                    @click.prevent="
                        $router.push({ name: 'relance_os' })
                        $emit('nav:close')
                    "
                    style="color: white"
                >
                    <font-awesome-icon icon="fa-solid fa-gear" class="uk-margin-small-right" />
                    Relance clôture des OS</a
                >
            </li>
            <li>
                <a
                    href="#"
                    @click.prevent="
                        $router.push({ name: 'code_standards' })
                        $emit('nav:close')
                    "
                    style="color: white"
                >
                    <font-awesome-icon icon="fa-solid fa-gear" class="uk-margin-small-right" />
                    Code standards</a
                >
            </li>
            <vk-nav-item-divider />
        </ul>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <item-manage-users-suez
                v-on="$listeners"
                v-if="$store.getters['login/isAdminFunc']"
            ></item-manage-users-suez>
            <item-manage-users-subcontractor
                v-on="$listeners"
                v-if="$store.getters['login/isAdminFunc']"
            ></item-manage-users-subcontractor>
            <vk-nav-item-divider />
            <li>
                <a
                    href="#"
                    @click.prevent="
                        $router.push({ name: 'parameters' })
                        $emit('nav:close')
                    "
                    style="color: white"
                >
                    <font-awesome-icon icon="fa-solid fa-gear" class="uk-margin-small-right" />
                    Paramètres</a
                >
            </li>
        </ul>
        <button
            type="button"
            class="btn btn-danger mt-4"
            @click="
                logout()
                $emit('nav:close')
            "
        >
            Déconnexion
        </button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ItemsAgencyAccount from '@/components/menuItems/ItemsAgencyAccount'
import ItemsWorkerAccount from '@/components/menuItems/ItemsWorkerAccount'
import ItemsEvent from '@/components/menuItems/ItemsEvent'
import ItemsForm from '@/components/menuItems/ItemsForm'
import ItemSubContractingCompanies from '@/components/menuItems/ItemSubContractingCompanies.vue'
import ItemRepositoryOfSuezRegions from './menuItems/ ItemRepositoryOfSuezRegions.vue'
import ItemFlow from './menuItems/ItemFlow.vue'
import ItemManageUsersSuez from './menuItems/ItemManageUsersSuez.vue'
import ItemManageUsersSubcontractor from './menuItems/ItemManageUsersSubcontractor.vue'
import { ROOT_BASE_NAME } from '@/constantes'

export default {
    name: 'NavMenu',
    components: {
        ItemsWorkerAccount,
        ItemsAgencyAccount,
        ItemsEvent,
        ItemsForm,
        ItemSubContractingCompanies,
        ItemRepositoryOfSuezRegions,
        ItemFlow,
        ItemManageUsersSuez,
        ItemManageUsersSubcontractor,
    },
    emits: ['nav:close'],
    computed: {
        ...mapState('login', ['user']),
    },
    methods: {
        ...mapActions('login', ['logout']),
    },
    data: () => ({
        rootBaseName: ROOT_BASE_NAME,
    }),
}
</script>

<style scoped>
.uk-nav-default .uk-nav-sub a {
    color: #999 !important;
}
</style>
