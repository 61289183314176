<template>
    <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
        <vk-nav-item-header title="Interventions" style="color: white" />
        <li>
            <a
                href="#"
                @click.prevent="
                    $router.push({ name: 'mission_orders' })
                    $emit('nav:close')
                "
                style="color: white"
                ><vk-icon icon="nut" class="uk-margin-small-right" />Liste des interventions</a
            >
        </li>
        <vk-nav-item-divider />
        <vk-nav-item-header title="Gestion interne" style="color: white" />
        <li class="uk-parent">
            <a href="#" style="color: white"><vk-icon icon="bookmark" class="uk-margin-small-right" />Absences</a>
            <ul class="uk-nav-sub">
                <vk-nav-item
                    @click="
                        $router.push({ name: 'absences' })
                        $emit('nav:close')
                    "
                    title="Report de notifications"
                />
            </ul>
        </li>
    </ul>
</template>

<script>
export default {}
</script>

<style scoped>
.uk-nav-default .uk-nav-sub a {
    color: #999 !important;
}
</style>
