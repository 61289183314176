<template>
    <div>
        <vue-good-table :columns="columns" :rows="events">
            <template slot="loadingContent">
                <span class="vgt-loading__content">Téléchargement...</span>
            </template>
            <template #table-row="props" class="d-flex flex-row flex-wrap gap-2">
                <div v-if="props.column.field === 'label'">
                    {{ `${props.row.event_definition.wording}` }}
                </div>
                <div v-else-if="props.column.field === 'date'">
                    {{ `${$util.formatDate(props.row.createdAt, 'DD/MM/YYYY', true)}` }}
                </div>
                <div v-else-if="props.column.field === 'geo'">
                    <img
                        v-if="props.row.lat && props.row.lng"
                        style="cursor: pointer"
                        src="@/assets/aim.png"
                        alt=""
                        width="100"
                        @click="$util.showMap(props.row.lat, props.row.lng)"
                    />
                </div>
                <div v-else-if="props.column.field === 'photo'" style="max-width: 100px">
                    <div v-for="(it, idx) in props.row.photos" :key="idx" class="uk-margin-small">
                        <div>
                            <img
                                :src="it.mimeType.includes('image') ? it.preview : play"
                                class="flex-fill"
                                :alt="it.name"
                            />
                            <button
                                class="btn btn-primary mt-2 mb-2 flex-fill"
                                style="width: 100%"
                                @click="downloadDocument(it.randomIdentifier, it.originalName, it.id)"
                            >
                                <font-awesome-icon icon="fa-solid fa-file-arrow-down" />
                            </button>
                            <div v-if="downloadsInProgress[it.id] !== undefined" class="flex-fill">
                                <ProgressBar
                                    :progress="downloadsInProgress[it.id].progress"
                                    class="mt-2 mb-2"
                                    style="width: 100%"
                                />
                                <button
                                    class="btn btn-warning mt-2 mb-2"
                                    type="button"
                                    style="width: 100%"
                                    @click="cancelDownload(it.id)"
                                >
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'
import play from '@/assets/play.png'
import ProgressBar from '@/components/ProgressBar'
export default {
    components: { ProgressBar },
    props: {
        events: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            downloadsInProgress: {},
            columns: [
                {
                    label: 'Date de création',
                    field: 'date',
                },
                {
                    label: 'Libellé',
                    field: 'label',
                },
                {
                    label: 'Photo',
                    field: 'photo',
                },
                {
                    label: 'Commentaire',
                    field: 'comment',
                },
                {
                    label: 'Géo',
                    field: 'geo',
                    hidden: true,
                },
            ],
            play,
        }
    },
    methods: {
        downloadDocument(identifier, name, fileId) {
            if (this.downloadsInProgress[fileId] !== undefined) {
                return
            }

            this.$set(this.downloadsInProgress, fileId, {
                name,
                progress: 0,
                abort: new AbortController(),
            })

            backendApi
                .downloadDocument(
                    store.state.login.user.token,
                    identifier,
                    name,
                    true,
                    (progressEvent) => {
                        this.downloadsInProgress[fileId].progress = Math.floor(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                    },
                    this.downloadsInProgress[fileId].abort,
                )
                .finally(() => {
                    this.$delete(this.downloadsInProgress, fileId)
                })
        },
        cancelDownload(fileId) {
            this.downloadsInProgress[fileId].abort.abort('canceled by user')
            this.$delete(this.downloadsInProgress, fileId)
        },
    },
    mounted() {},
}
</script>
