<template>
    <div id="app">
        <vk-sticky :top="0" width-element="100%">
            <vk-navbar class="uk-margin" :style="{ 'z-index': '1100' }">
                <vk-navbar-toggle title="Menu" style="color: white" @click="displayMenu = true"></vk-navbar-toggle>
                <vk-navbar-logo slot="center" style="color: white">
                    <router-link style="width: 12rem" class="logo-url" to="/">
                        <img alt="S" src="./assets/opo-waf-logo.png" />
                    </router-link>
                </vk-navbar-logo>
                <div slot="right" class="me-4">
                    <button
                        type="button"
                        class="btn btn-light text-primary position-relative"
                        @click="displayRemoteNotifications = true"
                    >
                        <font-awesome-icon icon="fa-solid fa-bell" />
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {{ notificationsFiltered.length }}
                            <span class="visually-hidden">unread messages</span>
                        </span>
                    </button>
                </div>
            </vk-navbar>
        </vk-sticky>
        <router-view />
        <vk-offcanvas-content>
            <vk-offcanvas overlay mode="reveal" :show.sync="displayMenu">
                <vk-offcanvas-close @click="displayMenu = false"></vk-offcanvas-close>
                <nav-menu v-on:nav:close="displayMenu = false" />
            </vk-offcanvas>
        </vk-offcanvas-content>
        <vk-offcanvas-content>
            <vk-offcanvas flipped overlay mode="reveal" :show.sync="displayRemoteNotifications">
                <vk-offcanvas-close @click="displayRemoteNotifications = false"></vk-offcanvas-close>
                <h5 class="text-white mb-4">Notifications</h5>
                <div
                    v-for="(remoteNotification, key) in notificationsFiltered"
                    :key="remoteNotification.type + '-' + key"
                    class="card mb-4"
                    style="font-size: 1rem"
                >
                    <div class="card-body">
                        <h5 class="card-title text-uppercase" style="font-size: smaller">Nouveau message</h5>
                        <h6 class="card-subtitle mb-2 text-muted small">
                            Le {{ remoteNotification.messageReadHistory.createdAt | humanReadableDate }}
                        </h6>
                        <p class="card-text">
                            {{ remoteNotification.messageReadHistory.message.value.substring(0, 100) + '...' }}
                        </p>
                        <div class="d-flex justify-content-between gap-2">
                            <button
                                v-if="remoteNotification.messageReadHistory.missionOrder"
                                type="button"
                                class="btn btn-outline-primary btn-sm"
                                @click="
                                    $router.push({
                                        name: 'mission_order_view',
                                        params: {
                                            missionOrderId: remoteNotification.messageReadHistory.missionOrder.id,
                                        },
                                    })
                                    displayRemoteNotifications = false
                                "
                            >
                                Voir l'ordre {{ remoteNotification.messageReadHistory.missionOrder.id }}
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-danger btn-sm"
                                @click="deleteRemoteNotification(remoteNotification.messageReadHistory.id)"
                            >
                                X
                            </button>
                        </div>
                    </div>
                </div>
            </vk-offcanvas>
        </vk-offcanvas-content>
        <vk-notification :messages.sync="notifications"></vk-notification>
        <login-modal />
    </div>
</template>

<script>
import NavMenu from '@/components/NavMenu'
import LoginModal from '@/components/LoginModal'
import { mapActions, mapState } from 'vuex'

export default {
    components: {
        LoginModal,
        NavMenu,
    },
    data: () => ({
        displayMenu: false,
        displayRemoteNotifications: false,
        loginHeartBeatDelay: 1000 * 60 * 15, // 15 minutes
    }),
    computed: {
        ...mapState('notifications', { storeNotifications: 'notifications' }),
        ...mapState('remoteNotifications', { remoteNotifications: 'notifications' }),
        notifications: {
            get() {
                return this.storeNotifications
            },
            set(notifications) {
                this.setNotifications(notifications)
            },
        },
        notificationsFiltered() {
            return this.remoteNotifications.filter((item) => item.messageReadHistory.shouldShow === true)
        },
    },
    watch: {
        $mq: {
            immediate: true,
            handler(newValue) {
                const scale = {
                    xs: 0.65,
                    sm: 0.7,
                    md: 0.8,
                    lg: 0.9,
                    xl: 1,
                    xxl: 1,
                    xxxl: 1,
                    big: 1,
                    giga: 1,
                }

                document.documentElement.style.fontSize = (scale[newValue] || 1) + 'rem'
            },
        },
    },
    methods: {
        ...mapActions('notifications', ['setNotifications']),
        ...mapActions('login', ['loginUpdate', 'loginAzureUpdate']),
        ...mapActions('remoteNotifications', { deleteRemoteNotification: 'deleteNotification' }),
        loginUpdateHeartBeat() {
            if (this.$azureAuth) this.loginAzureUpdate()
            else this.loginUpdate()
            setTimeout(this.loginUpdateHeartBeat, this.loginHeartBeatDelay)
        },
    },
    mounted() {
        this.loginUpdateHeartBeat()
        this.$store.dispatch('users/updatePosition')
    },
}
</script>

<style lang="scss">
#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

#vgt-table {
    scroll-behavior: smooth;
}

.vgt-inner-wrap * {
    font-size: 0.8rem !important;
}

.vgt-table {
    font-size: 0.9rem !important;
}

.footer__navigation * {
    margin: 0 !important;
}

.uk-offcanvas {
    z-index: 1200 !important;
}

.uk-notification {
    z-index: 1300 !important;
}

.modal {
    z-index: 1250 !important;
}

.uk-modal {
    z-index: 1260 !important;
}
</style>
