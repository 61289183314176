<template>
    <div>
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
    </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
export default {
    components: {
        StreamBarcodeReader,
    },
    data() {
        return {}
    },
    methods: {
        onDecode(data) {
            this.$emit('find', data)
        },
        onLoaded() {},
    },
    mounted() {},
}
</script>

<style></style>
