const crypto = require('crypto')

class random {
    htmlId(length = 32) {
        let id = ''

        while (id.length < length) {
            id += crypto
                .randomBytes(Math.ceil(length * 1.25))
                .toString('hex')
                .replace(/^[^a-z]+/g, '')
        }

        return id.substring(0, length)
    }
}

export default new random()
