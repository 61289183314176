<template>
    <div>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <li class="uk-parent">
                <a href="#" style="color: white">
                    <i class="fa-solid fa-file-export uk-margin-small-right"></i>
                    Export des statuts (FlagTSMS)
                </a>
                <ul class="uk-nav-sub">
                    <vk-nav-item
                        @click="
                            $router.push({ name: 'log-flag-tsms' })
                            $emit('nav:close')
                        "
                        title="Export des statuts (FlagTSMS)"
                    />
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {}
</script>

<style>
.uk-nav-default .uk-nav-sub a {
    color: #999 !important;
}
</style>
