import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import { ROOT_BASE_NAME } from '@/constantes'

Vue.use(VueRouter)

const routes = [
    {
        name: ROOT_BASE_NAME,
        path: '/',
        component: () => import('../views/HomeDashboard.vue'),
    },
    {
        path: '/absences',
        name: 'absences',
        component: () => import('../views/Absences.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.login.user.userData?.accountType !== 'agencyAccount') {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/sub-contracting-companies',
        name: 'subcontracting_companies',
        component: () => import('../views/SubContractingCompanies.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin_func')) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/repository-of-suez-regions',
        name: 'repository_suez_regions',
        component: () => import('../views/RepositoryOfSuezRegions.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin_func')) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/import-export-dq',
        name: 'import-export-dq',
        component: () => import('../views/ImportExportDq.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin_func')) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/import-suez-users',
        name: 'import-suez-users',
        component: () => import('../views/ImportSuezUsers.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin_func')) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/import-external-users',
        name: 'import-external-users',
        component: () => import('../views/ImportExternalUsers.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin_func')) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/log-flag-tsms',
        name: 'log-flag-tsms',
        component: () => import('../views/LogFlagTSms.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin_func')) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/mission-orders',
        name: 'mission_orders',
        component: () => import('../views/MissionOrders.vue'),
    },
    {
        path: '/assignment-list',
        name: 'assignment_list',
        component: () => import('../views/AssignmentList.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin')) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/mission-order/:missionOrderId',
        name: 'mission_order_view',
        component: () => import('../views/MissionOrderView.vue'),
    },
    {
        path: '/status',
        name: 'status',
        component: () => import('../views/Status.vue'),
    },
    {
        path: '/parameters',
        name: 'parameters',
        component: () => import('../views/Parameters.vue'),
    },
    {
        path: '/events',
        name: 'event',
        component: () => import('../views/Event.vue'),
    },
    {
        path: '/fields',
        name: 'field',
        component: () => import('../views/Field.vue'),
    },
    {
        path: '/forms',
        name: 'form',
        component: () => import('../views/Form.vue'),
    },
    {
        path: '/forms/:formId',
        name: 'form_view',
        component: () => import('../views/FormView.vue'),
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/UsersWorkers.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin') && !store.getters['login/isAdminFunc']) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/users-suez',
        name: 'users_suez',
        component: () => import('../views/UsersSuez.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin') && !store.getters['login/isAdminFunc']) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/user',
        name: 'user_new',
        component: () => import('../views/UserCreate.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin') && !store.getters['login/isAdminFunc']) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/user-st',
        name: 'user_st_new',
        component: () => import('../views/UserCreateSubcontractor.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin') && !store.getters['login/isAdminFunc']) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/user/:userId',
        name: 'user_edit',
        component: () => import('../views/UserEdit.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin') && !store.getters['login/isAdminFunc']) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/user-st/:userId',
        name: 'user_st_edit',
        component: () => import('../views/UserSubContractorEdit.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin') && !store.getters['login/isAdminFunc']) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/relance-os',
        name: 'relance_os',
        component: () => import('../views/RelanceOS.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['login/isAdminFunc']) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '/code-standards',
        name: 'code_standards',
        component: () => import('../views/CodeStandard.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.state.login.user.userData.roles.includes('admin_func')) {
                return next({ name: ROOT_BASE_NAME })
            }
            next()
        },
    },
    {
        path: '*',
        redirect: '/',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    store.commit('breadcrumbs/UPDATE_PREVIOUS_ROUTE', from.name)
    if (!store.state.login.user.token && to.name !== ROOT_BASE_NAME) {
        return next({ name: ROOT_BASE_NAME })
    }
    next()
})

export default router
