<template>
    <div>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <li>
                <a
                    href="#"
                    @click.prevent="
                        $router.push({ name: 'repository_suez_regions' })
                        $emit('nav:close')
                    "
                    style="color: white"
                    ><font-awesome-icon icon="fa-regular fa-clipboard" class="uk-margin-small-right" />Référentiel des
                    Régions Suez</a
                >
            </li>
            <vk-nav-item-divider />
        </ul>
    </div>
</template>
<script>
export default {}
</script>
