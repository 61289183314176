<template>
    <div class="boxIcon" :style="`background-color: ${VALUES[type].color}`" @click="$emit('click')">
        <div class="divicon">
            <img class="img" :src="VALUES[type].icon" alt="" srcset="" />
        </div>
        <div class="text" style="line-height: 13px">
            {{ VALUES[type].text }}
        </div>
        <div v-if="loading" uk-spinner="ratio:0.5"></div>
    </div>
</template>

<script>
import checked from '@/assets/checked.png'
import clipboard from '@/assets/clipboard.png'
import plus from '@/assets/plus.png'
import programming from '@/assets/programming.png'
import ruler from '@/assets/ruler.png'
import target from '@/assets/target.png'
import watch from '@/assets/watch.png'
import stopwatch from '@/assets/stopwatch.png'
import report from '@/assets/report.png'
import doc from '@/assets/doc.png'
import pdf from '@/assets/pdf.png'
export default {
    props: {
        type: {
            required: false,
            default: 'ADD_EVENT',
        },
        loading: {
            required: false,
            default: false,
        },
    },
    data() {
        return {
            VALUES: {
                ADD_EVENT: { icon: plus, color: '#E5C57C', text: 'Déclarer un évènement' },
                ETATLI: { icon: clipboard, color: '#257CA0', text: `Effectuer l'état des lieux` },
                TERMIN: { icon: checked, color: '#D75061', text: 'Terminer le chantier' },
                BALISE: { icon: programming, color: '#257CA0', text: 'Effectuer le balisage' },
                MARQUA: { icon: target, color: '#27A090', text: 'Effectuer mar./piq.' },
                METRES: { icon: ruler, color: '#29C2DC', text: 'Effectuer un métré' },
                ARRIVE: { icon: stopwatch, color: '#47986A', text: 'Déclarer l`arrivée' },
                REPREN: { icon: watch, color: '#47986A', text: 'Reprendre le chantier' },
                REPORT: { icon: report, color: '#00FFFF', text: 'Générer le rapport de travaux' },
                DOC: { icon: doc, color: '#FFF', text: 'Format Document' },
                PDF: { icon: pdf, color: '#FFF', text: 'Format Pdf' },
            },
        }
    },
}
</script>

<style>
.boxIcon {
    width: 70px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.divicon {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading {
    position: absolute;
    left: -20px;
    right: 0;
}

.img {
    width: 40px;
}
.text {
    text-align: center;
    font-weight: bold;
    position: relative;
    font-size: 10px;
}
</style>
