<template>
    <div class="card">
        <vk-button @click="reseMap">Réinitialiser la carte</vk-button>
        <div
            class="card-body d-flex justify-content-center align-items-center"
            ref="mapMeasurement"
            style="height: 60vh; min-height: 250px"
        >
            <div v-if="isLocationFind" uk-spinner="ratio:0.5"></div>
        </div>
    </div>
</template>

<script>
import { Icon } from 'leaflet'
import * as turf from '@turf/turf'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
export default {
    data() {
        return {
            isLocationFind: false,
            map: null,
        }
    },
    methods: {
        reseMap() {
            this.map.off()
            this.map.remove()
            this.initializeMap()
        },
        async initializeMap() {
            this.isLocationFind = true
            const coordinates = await this.$store.dispatch('users/updatePosition').catch(() => {
                this.$store.dispatch(
                    'notifications/showWarning',
                    'Veuillez autoriser la géolocalisation sur votre appareil',
                )
            })
            if (coordinates) {
                this.isLocationFind = false
                this.map = this.$leaflet.map(this.$refs.mapMeasurement, {
                    center: [coordinates.coords.latitude, coordinates.coords.longitude],
                    zoom: 16,
                    gestureHandling: true,
                })

                this.$leaflet
                    .tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
                        attribution:
                            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        maxNativeZoom: 21,
                        maxZoom: 25,
                    })
                    .addTo(this.map)

                this.map.pm.setLang('fr')

                this.map.pm.addControls({
                    position: 'topleft',
                    drawCircle: false,
                    drawRectangle: false,
                    drawMarker: false,
                    drawCircleMarker: false,
                    dragMode: false,
                    cutPolygon: false,
                    editMode: false,
                })

                let latlngs = []

                this.map.on('pm:create', (e) => {
                    latlngs = e.layer._latlngs
                    let lat1 = latlngs[0].lat
                    let lon1 = latlngs[0].lng
                    let lat2 = latlngs[latlngs.length - 1].lat
                    let lon2 = latlngs[latlngs.length - 1].lng

                    if (latlngs[0].length >= 3) {
                        const areaCordinate = [...latlngs[0]]
                        const polygoneCordinate = areaCordinate.map((item) => [item.lat, item.lng])

                        polygoneCordinate.push(polygoneCordinate[0])

                        var polygonArea = turf.polygon([polygoneCordinate])
                        var area = turf.area(polygonArea).toFixed(2)
                    }

                    // new approach
                    if (distance(lat1, lat2, lon1, lon2)) {
                        e.layer
                            .bindTooltip(`<b>Distance :</b> ${distance(lat1, lat2, lon1, lon2)} m`, {
                                permanent: true,
                                direction: 'top',
                            })
                            .openTooltip()
                    }
                    if (area) {
                        e.layer
                            .bindTooltip(`<b>Surface :</b> ${area} ㎡`, { permanent: true, direction: 'top' })
                            .openTooltip()
                    }
                })

                this.$leaflet.marker([coordinates.coords.latitude, coordinates.coords.longitude]).addTo(this.map)
            }

            //calcul distance between two coordinate geographic
            function distance(lat1, lat2, lon1, lon2) {
                const R = 6371e3 // metres
                const φ1 = (lat1 * Math.PI) / 180
                const φ2 = (lat2 * Math.PI) / 180
                const Δφ = ((lat2 - lat1) * Math.PI) / 180
                const Δλ = ((lon2 - lon1) * Math.PI) / 180

                const a =
                    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
                const d = R * c

                return d.toFixed(2)
            }
        },
    },
    mounted() {
        this.initializeMap()
    },
}
</script>

<style scoped></style>
