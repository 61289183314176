<template>
    <div>
        <SimpleAccordion>
            <template v-slot:accordionTitle> Adresse </template>
            <template v-slot:accordionContent>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Numéro de voie</th>
                            <th scope="col">Voie</th>
                            <th scope="col">Complément d’adresse</th>
                            <th scope="col">Code postal</th>
                            <th scope="col">Commune</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ orderMission.property.address.streetNumber }}</td>
                            <td>{{ orderMission.property.address.route }}</td>
                            <td>
                                {{ orderMission.property.address.district }}
                                {{ orderMission.property.address.country }}
                            </td>
                            <td>{{ orderMission.property.address.postalCode }}</td>
                            <td>{{ orderMission.property.address.locality }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="m-3 d-flex justify-content-end">
                    <button
                        class="btn btn-primary"
                        v-if="locationOfUser"
                        @click="
                            $util.itinerary(
                                locationOfUser,
                                orderMission.property.address.lat + ',' + orderMission.property.address.lng,
                            )
                        "
                    >
                        Itinéraire
                    </button>
                </div>
            </template>
        </SimpleAccordion>
    </div>
</template>
<script>
export default {
    props: {
        orderMission: {
            type: Object,
            require: true,
        },
    },
    computed: {
        locationOfUser() {
            const coordinates = this.$store.getters['users/getPosition']
            if (coordinates) {
                return coordinates.coords.latitude + ',' + coordinates.coords.longitude
            }
            return null
        },
    },
}
</script>
<style lang=""></style>
