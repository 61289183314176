export const inProgress = 'En cours'
export const toDo = 'À faire'
export const suspended = 'Suspendu'
export const ended = 'Terminé'
export const permanentlySuspended = 'Suspendu définitivement'
export const canceled = 'Annulé'

/**
 * @type {{
 *      inProgress: string
 *      toDo: string
 *      suspended: string
 *      ended: string
 *      permanentlySuspended: string
 *      canceled: string
 * }}
 */
export const statusEnum = {
    inProgress,
    toDo,
    suspended,
    ended,
    permanentlySuspended,
    canceled,
}

export const statusSymbol = (() => {
    const symbols = {}
    symbols[inProgress] = 'EC'
    symbols[toDo] = 'AF'
    symbols[suspended] = 'Susp'
    symbols[ended] = 'Te'
    symbols[permanentlySuspended] = 'Susp D.'
    symbols[canceled] = 'An'

    return symbols
})()

export const statusColor = (() => {
    const colors = {}

    colors[inProgress] = '#00e600'
    colors[toDo] = '#0080ff'
    colors[suspended] = '#a3a3a3'
    colors[ended] = '#000000'
    colors[permanentlySuspended] = '#FF0000'
    colors[canceled] = '#0f395d'

    return colors
})()
