<template>
    <div>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <li class="uk-parent">
                <a href="#" style="color: white">
                    <i class="fa-sharp fa-solid fa-bars uk-margin-small-right"></i>
                    Formulaires
                </a>
                <ul class="uk-nav-sub">
                    <vk-nav-item
                        @click="
                            $router.push({ name: 'field' })
                            $emit('nav:close')
                        "
                        title="Administrer les champs"
                    />
                    <vk-nav-item
                        @click="
                            $router.push({ name: 'form' })
                            $emit('nav:close')
                        "
                        title="Administrer les formulaires"
                    />
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {}
</script>

<style>
.uk-nav-default .uk-nav-sub a {
    color: #999 !important;
}
</style>
