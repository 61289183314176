<template>
    <div class="boxSignature">
        <canvas id="canvas"></canvas>
    </div>
</template>

<script>
import SignaturePad from 'signature_pad'

export default {
    data() {
        return {
            signaturePad: undefined,
            optionSignature: {
                backgroundColor: '#eee',
            },
        }
    },
    methods: {
        getFile() {
            if (this.signaturePad.isEmpty()) {
                this.$store.dispatch('notifications/showWarning', 'Signature vide')
                return undefined
            }
            const b64 = this.signaturePad.toDataURL()
            return this.$util.base64ToFile(b64, `${Date.now()}.jpg`)
        },
        clear() {
            this.signaturePad.clear()
        },
    },
    mounted() {
        const canvas = document.querySelector('#canvas')
        this.signaturePad = new SignaturePad(canvas, this.optionSignature)
    },
}
</script>

<style>
.boxSignature {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
</style>
