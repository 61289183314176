import { LocalNotifications } from '@capacitor/local-notifications'
import { AndroidSettings, NativeSettings } from 'capacitor-native-settings'
import { Capacitor } from '@capacitor/core'
import { ANDROID_MOBILE } from '@/constantes/platform'

const platform = Capacitor.getPlatform()
export const requestNotificationPermission = async () => {
    /**
     * for android notification permission
     * open notification permission settings for current app
     *
     * for ios, only app setting can be accessed
     * accessing another setting can break the OS
     * according to the package documentation
     */
    if (platform === ANDROID_MOBILE && (await LocalNotifications.requestPermissions()).display !== 'granted') {
        await NativeSettings.openAndroid({
            option: AndroidSettings.AppNotification,
        })
    }

    /**
     * execute the standard request permissions for capacitor
     */
    await LocalNotifications.requestPermissions()
    return await LocalNotifications.checkPermissions()
}
