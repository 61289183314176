<template>
    <div>
        <vk-modal center :show.sync="show">
            <vk-modal-title>Choisissez le type de média</vk-modal-title>

            <div class="media-chooser-body">
                <button class="media-chooser-item" @click.prevent="() => choose(type.image)">
                    <font-awesome-icon icon="fa-solid fa-image" size="4x" />
                </button>
                <button class="media-chooser-item" @click.prevent="() => choose(type.video)">
                    <font-awesome-icon icon="fa-solid fa-video" size="4x" />
                </button>
            </div>

            <p class="uk-text-right">
                <vk-button @click="close" class="uk-margin-small-right">Annuler</vk-button>
            </p>
        </vk-modal>
    </div>
</template>

<script>
import { FileImage, FileVideo } from '@/Enum/FileType'
export default {
    name: 'MobileRecorderChooser',
    data: () => ({
        show: false,
        type: {
            image: FileImage,
            video: FileVideo,
        },
    }),
    methods: {
        choose(type) {
            this.$emit('choosed', type)
            this.show = false
        },
        close() {
            this.$emit('close')
            this.show = false
        },
        open() {
            this.show = true
        },
    },
}
</script>

<style scoped>
.media-chooser-body {
    display: flex;
    justify-content: space-around;
    margin: 10px 0px;
}

.media-chooser-item {
    width: 100px;
    height: 100px;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
</style>
