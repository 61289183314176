<template>
    <div v-if="missionOrder.mission_order_form_data">
        <table class="tg">
            <tbody v-for="(item, idx) in dataVisible" :key="idx">
                <tr>
                    <td class="tg-0pky">
                        {{
                            item.field_definition.wording
                                ? item.field_definition.wording
                                : item.field_definition.g2_code
                        }}
                    </td>
                    <td style="padding: 0">
                        <div
                            class="d-flex justify-content-between item-value"
                            :class="`${item.required && !item.data ? 'item-required' : ''}`"
                        >
                            <p v-if="item.data">
                                {{
                                    typeof item.data === 'object'
                                        ? item.data.wording
                                        : item.field_definition.field_type.type === 'datetime_iso'
                                          ? $util.formatDate(item.data, 'DD/MM/YYYY', true)
                                          : item.data
                                }}
                            </p>
                            <div v-if="userCanUpdate && item.editable">
                                <i
                                    v-if="
                                        item.field_definition.field_type.type === 'matricule_compteur' &&
                                        item.field_definition.scannable === true
                                    "
                                    style="margin-right: 10px"
                                    class="fa-solid fa-camera"
                                    @click="onScanClicked(idx)"
                                ></i>
                                <i class="fa-solid fa-pen-to-square" @click="onUpdate(idx)"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <blank-modal ref="modalUpdateForm" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Modification</legend>
            </template>
            <template #modalBody>
                <form-input
                    ref="fInput"
                    v-if="indexSelected != undefined"
                    v-model="data"
                    :data="missionOrder.mission_order_form_data.data[indexSelected]"
                    @error="(val) => (inputHasError = val)"
                ></form-input>
            </template>
            <template #modalFooterExtra>
                <button :disabled="inputHasError" class="btn btn-primary" @click="save(data)">
                    Enregistrer
                    <div class="uk-margin-left uk-inline" uk-spinner="ratio:0.5" v-if="loading"></div>
                </button>
            </template>
        </blank-modal>
        <blank-modal ref="modalScan" :modal-id="$util.htmlId()" @close="onCloseScanner">
            <template #modalHeader>
                <legend class="uk-legend">Scan...</legend>
            </template>
            <template #modalBody v-if="scanning">
                <ScannerVue @find="onCodeFinded"></ScannerVue>
            </template>
        </blank-modal>
    </div>
</template>
<script>
import ScannerVue from '../commons/Scanner.vue'

export default {
    components: {
        ScannerVue,
    },
    props: {
        missionOrder: {
            required: true,
        },
    },
    data() {
        return {
            data: '',
            loading: false,
            userCanUpdate: false,
            indexSelected: undefined,
            scanning: false,
            inputHasError: false,
        }
    },
    methods: {
        onUpdate(index) {
            this.indexSelected = index
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalUpdateForm.$el).show()
            if (this.$refs.fInput) {
                this.$refs.fInput.initData()
            }
        },
        checkIfUserCanUpdate() {
            if (
                this.$store.state.login.user.userData.accountType.includes('workerAccount') &&
                (this.missionOrder.status.value === 'En cours' || this.missionOrder.status.value === 'Suspendu')
            )
                this.userCanUpdate = true
            else this.userCanUpdate = false
        },
        save(newVal) {
            if (newVal === null) {
                this.$store.dispatch('notifications/showWarning', 'Veuillez remplir ce champ')
            }
            const data = this.missionOrder.mission_order_form_data.data[this.indexSelected]
            const regex = data.field_definition.field_type.regex_validate_field
            let hasError = false
            if (regex) {
                if (data && data.field_definition.field_type.type === 'datetime_iso') {
                    newVal = new Date(newVal).toISOString()
                }
                if (data.required) {
                    hasError = true
                }
                if (data.field_definition.field_type.regex_validate_field) {
                    const regex = new RegExp(data.field_definition.field_type.regex_validate_field)
                    hasError = !regex.test(newVal)
                }
            }
            if (hasError) {
                this.$store.dispatch('notifications/showWarning', 'Valeur non valide')
                return
            }
            const payload = {
                id: data.id,
                list_value_id: data.field_definition.field_type.type == 'dropdown' ? newVal.id : null,
                value: data.field_definition.field_type.type == 'dropdown' ? null : newVal,
            }
            this.$http.updateFormMissionOrder(this.missionOrder.id, payload).then(() => {
                this.missionOrder.mission_order_form_data.data[this.indexSelected].data = newVal
                this.$store.dispatch('notifications/showInfo', 'Modification avec succees')
                this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalUpdateForm.$el).hide()
            })
        },
        onScanClicked(index) {
            this.indexSelected = index
            this.scanning = true
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalScan.$el).show()
        },
        onCloseScanner() {
            this.scanning = false
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalScan.$el).hide()
        },
        onCodeFinded(data) {
            this.onCloseScanner()
            this.save(data)
        },
    },
    computed: {
        dataVisible() {
            return this.missionOrder.mission_order_form_data.data.filter((d) => d.visible)
        },
    },
    mounted() {
        this.checkIfUserCanUpdate()
    },
}
</script>
<style scoped>
.tg {
    border-collapse: collapse;
    border-spacing: 0;
}

.tg td {
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 10px 5px;
    word-break: normal;
}

.tg .tg-u0o7 {
    border-color: inherit;
    font-weight: bold;
    text-align: left;
    text-decoration: underline;
    vertical-align: top;
}

.tg .tg-0pky {
    text-align: left;
    vertical-align: top;
}

.tg .tg-0lax {
    text-align: left;
    vertical-align: top;
}

.value {
    background: #f1f1f1;
}

.item-value {
    padding: 10px;
}

.item-required {
    background-color: rgba(255, 0, 0, 0.2);
}

table {
    width: 100%;
}

.fa-solid {
    cursor: pointer;
}
</style>
