<template>
    <div>
        <vk-modal-full :show.sync="show" :stuck="true" :center="true" class="modal-video-recorder">
            <div class="video-recorder-body">
                <video
                    id="video-recroding"
                    autoplay
                    muted
                    playsinline
                    ref="videoRecordRef"
                    class="video-recorder-display"
                    :hidden="!isRecording"
                ></video>
                <video
                    id="video-player"
                    ref="videoPlayerRef"
                    controls
                    class="video-recorder-display"
                    :hidden="status !== statuslist.VIDEO_STOP_RECORD"
                ></video>
            </div>

            <div class="d-flex justify-content-around align-items-center video-recorder-action">
                <vk-button @click="close" class="video-recorder-action-button">
                    <font-awesome-icon icon="fa-solid fa-close" size="xl" />
                </vk-button>
                <span class="timer-record" v-if="startTime">
                    {{ recordDuration }}
                </span>
                <vk-button
                    @click="initRecorder"
                    class="video-recorder-action-button"
                    v-if="!isRecording && status === statuslist.VIDEO_STOP_RECORD"
                >
                    <font-awesome-icon icon="fa-solid fa-refresh" size="xl" />
                </vk-button>
                <vk-button @click="stopRecord" class="video-recorder-action-button" v-if="isRecording">
                    <font-awesome-icon icon="fa-solid fa-stop" size="xl" />
                </vk-button>
                <vk-button
                    @click="saveRecord"
                    class="video-recorder-action-button"
                    v-if="status === statuslist.VIDEO_STOP_RECORD"
                >
                    <font-awesome-icon icon="fa-regular fa-floppy-disk" size="xl" />
                </vk-button>
            </div>
        </vk-modal-full>
    </div>
</template>

<script>
import * as VideoStatus from '@/Enum/RecordStatus'
import fixWebmDuration from 'fix-webm-duration'
import helper from '@/helpers/util'
import backendApi from '@/backend/api'

export default {
    name: 'MobileVideoRecorder',
    data: () => ({
        show: false,
        isRecording: false,
        status: VideoStatus.VIDEO_INITIAL,
        videoRecorder: null,
        recorded: null,
        mediaOptions: {
            video: {
                facingMode: 'environment',
                width: 720,
                height: 720,
                frameRate: {
                    ideal: 10,
                    max: 15,
                },
            },
            audio: true,
        },
        streamOptions: {
            mimeType: 'video/webm;codecs=vp9',
            videoBitsPerSecond: 300000,
            audioBitsPerSecond: 64000,
        },
        fileName: '',
        startTime: null,
        timerStart: null,
        duration: 0,
        intervalDuration: null,
        videoMaxSize: 25 * 1024 * 1024, // 25mb
    }),
    computed: {
        statuslist: () => VideoStatus,
        recordDuration() {
            return helper.millisecondsToHMS(this.duration)
        },
    },
    watch: {
        show() {
            if (this.show) this.initRecorder()
        },
    },
    methods: {
        open() {
            this.show = true
        },
        generateName(type) {
            this.fileName = `video_event_${new Date().getTime()}_.${type.split('/')[1]}`
        },
        close() {
            this.$emit('close')
            this.videoRecorder = null
            this.isRecording = false
            this.recorder = null
            this.status = VideoStatus.VIDEO_INITIAL
            this.show = false
        },
        async initRecorder() {
            try {
                let recordedSize = 0
                this.isRecording = true
                this.recorded = null
                // init stream recording
                const stream = await navigator.mediaDevices.getUserMedia(this.mediaOptions)
                // append video stream to video tag on dom
                this.$refs.videoRecordRef.srcObject = stream
                // create the recorder steam
                this.videoRecorder = new MediaRecorder(stream, this.streamOptions)

                let chunks = []
                this.videoRecorder.onstop = async () => {
                    /**
                     * startime is used to track duration of stream
                     * there is bug on the blob duration from MediaRecorder
                     * so fixWebmDuration fix the blob
                     */
                    const duration = new Date() - this.startTime
                    const blob = new Blob(chunks, { type: 'video/webm' })
                    this.recorded = await fixWebmDuration(blob, duration, { logger: false })
                    this.generateName(this.recorded.type)
                    /**
                     * append the blob as obect url to video
                     * to preview the recorded video
                     */

                    this.$refs.videoPlayerRef.src = URL.createObjectURL(this.recorded)
                    this.$refs.videoPlayerRef.load()
                    // reset startTime
                    this.startTime = null
                }

                this.videoRecorder.ondataavailable = (event) => {
                    if (recordedSize >= this.videoMaxSize) {
                        if (this.videoRecorder) this.stopRecord()
                    } else if (event.data && event.data.size > 0) {
                        recordedSize += event.data.size
                        chunks.push(event.data)
                    }
                }

                // start record
                this.startRecord()
            } catch (e) {
                backendApi.notificationOnError(e)
                this.close()
            }
        },
        startRecord() {
            this.videoRecorder.start(100)
            this.startTime = new Date()
            this.timerStart = Date.now()
            this.getDuration()
            this.status = this.statuslist.VIDEO_RECORDING
            this.recorded = null
        },
        stopRecord() {
            this.videoRecorder.stop()
            this.status = this.statuslist.VIDEO_STOP_RECORD
            this.videoRecorder = null
            this.$refs.videoRecordRef.srcObject = null
            this.isRecording = false
            clearInterval(this.intervalDuration)
            this.intervalDuration = null
        },
        saveRecord() {
            const videoFile = new File([this.recorded], this.fileName, { type: this.recorded.type })

            this.$emit('saved', videoFile)
            this.close()
        },
        getDuration() {
            this.intervalDuration = setInterval(() => {
                this.duration = Date.now() - this.timerStart
            }, 1000)
        },
    },
}
</script>

<style scoped>
.video-recorder-body {
    height: 90%;
    margin: 0 10px;
    padding: 10px 20px;
}

.video-recorder-display {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 5px;
}

.video-recorder-action {
    height: 10%;
}

.video-recorder-action-button {
    width: 70px;
    height: 40px;
    padding: 0;
    border-radius: 5px;
}

.timer-record {
    text-align: center;
    font-size: 24px;
    color: black;
}
</style>
