<template>
    <div class="card" v-if="contact.name">
        <div class="card-body">
            <h6 class="card-title fw-bold">Contact : {{ contact.name }}</h6>
            <ul class="list-group mt-3" v-if="contact.telephone">
                <li class="list-group-item list-group-item-dark">Numéros de téléphone</li>
                <li class="list-group-item">
                    {{ contact.telephone }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactDisplay',
    props: {
        contact: {
            required: false,
            default: () => ({
                createdAt: null,
                updatedAt: null,
                fullName: null,
                emails: [],
                phoneNumbers: [],
            }),
        },
    },
}
</script>

<style scoped></style>
