export default {
    namespaced: true,
    state: () => ({
        previousRoute: null,
    }),
    mutations: {
        UPDATE_PREVIOUS_ROUTE(store, routeName) {
            store.previousRoute = routeName
        },
    },
    actions: {},
    getters: {
        previousRouteInfo: (state) => {
            switch (state.previousRoute) {
                case 'mission_orders':
                    return {
                        wording: 'Liste des interventions',
                        name: state.previousRoute,
                    }
                case 'assignment_list':
                    return {
                        wording: 'Liste des affectations',
                        name: state.previousRoute,
                    }
                default:
                    return {
                        wording: 'Accueil',
                        name: 'root',
                    }
            }
        },
    },
}
