<template>
    <div>
        <SimpleAccordion>
            <template v-slot:accordionTitle> Date d'intervention et RdV </template>
            <template v-slot:accordionContent>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Date de début prévue</th>
                            <th scope="col">Date de début réelle</th>
                            <th scope="col">Date de fin prévue</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{
                                    orderMission.expectedStartDate
                                        ? $util.formatDate(orderMission.expectedStartDate, 'DD/MM/YYYY', false)
                                        : ''
                                }}
                            </td>
                            <td>
                                {{
                                    orderMission.realStartDate
                                        ? $util.formatDate(orderMission.realStartDate, 'DD/MM/YYYY', true)
                                        : ''
                                }}
                            </td>
                            <td>
                                {{
                                    orderMission.expectedEndDate
                                        ? $util.formatDate(orderMission.expectedEndDate, 'DD/MM/YYYY', false)
                                        : ''
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="table mt-2">
                    <thead>
                        <tr>
                            <th scope="col" v-if="orderMission.osG2DateRdvEntreprise">Date RDV Entreprise</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-if="orderMission.osG2DateRdvEntreprise">
                            <td>
                                {{
                                    orderMission.osG2DateRdvEntreprise
                                        ? `${orderMission.osG2DateRdvEntreprise.split(' ')[0]} ${orderMission.osG2HeureSouhaiteeEntreprise}`
                                        : ''
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="table mt-2">
                    <thead>
                        <tr>
                            <th scope="col" v-if="orderMission.osG2DateRdvClient">Date RDV Souhaitée</th>
                            <th scope="col" v-if="orderMission.osG2CreneauRdvClient">Créneau RDV Client</th>
                            <th scope="col" v-if="orderMission.osG2NomContactClient">Contact Client</th>
                            <th scope="col" v-if="orderMission.osG2TelMobileClient">Mobile Client</th>
                            <th
                                scope="col"
                                v-if="orderMission.osG2TelephoneContactClient || orderMission.osG2TelClient"
                            >
                                Tel. Client 1
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td v-if="orderMission.osG2DateRdvClient">
                                {{
                                    orderMission.osG2DateRdvClient
                                        ? `${orderMission.osG2DateRdvClient.split(' ')[0]} ${orderMission.osG2HeureSouhaiteeClient}`
                                        : ''
                                }}
                            </td>
                            <td v-if="orderMission.osG2CreneauRdvClient">
                                {{ orderMission.osG2CreneauRdvClient ? orderMission.osG2CreneauRdvClient : '' }}
                            </td>
                            <td v-if="orderMission.osG2NomContactClient">
                                {{ orderMission.osG2NomContactClient }}
                            </td>
                            <td v-if="orderMission.osG2TelMobileClient">
                                {{ orderMission.osG2TelMobileClient }}
                            </td>
                            <td
                                v-if="orderMission.osG2TelephoneContactClient || orderMission.osG2TelClient"
                                class="d-flex flex-column"
                            >
                                <span v-if="orderMission.osG2TelephoneContactClient">{{
                                    orderMission.osG2TelephoneContactClient
                                }}</span>
                                <span v-if="orderMission.osG2TelClient">{{ orderMission.osG2TelClient }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </SimpleAccordion>
    </div>
</template>
<script>
export default {
    props: {
        orderMission: {
            type: Object,
            require: true,
        },
    },
}
</script>
<style></style>
