export default {
    namespaced: true,
    state: () => ({
        tab: 'MAP',
    }),
    mutations: {
        SET_TAB(state, payload) {
            state.tab = payload
        },
    },
    actions: {
        changeTab(context, data) {
            context.commit('SET_TAB', data)
            localStorage.setItem('tab', data)
        },
    },
}
