<template>
    <div>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <li class="uk-parent">
                <a href="#" style="color: white">
                    <i class="fa-solid fa-left-right uk-margin-small-right"></i>
                    Import/Export des DQ
                </a>
                <ul class="uk-nav-sub">
                    <vk-nav-item
                        @click="
                            $router.push({ name: 'import-export-dq' })
                            $emit('nav:close')
                        "
                        title="Import/Export des DQ"
                    />
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {}
</script>

<style>
.uk-nav-default .uk-nav-sub a {
    color: #999 !important;
}
</style>
