const defaultParameters = () => ({
    device: {
        camera: {
            allowEditing: true,
        },
    },
})

let storedParameters

try {
    storedParameters = JSON.parse(localStorage.getItem('parameters'))
} catch (error) {
    storedParameters = null
}

const parameters = storedParameters ? storedParameters : defaultParameters()

localStorage.setItem('parameters', JSON.stringify(parameters))

export default {
    namespaced: true,
    state: () => parameters,
    mutations: {
        CAMERA_ALLOW_EDITING(store, payload) {
            store.device.camera.allowEditing = payload
            localStorage.setItem('parameters', JSON.stringify(store))
        },
    },
    actions: {
        setCameraAllowEditing(context, payload) {
            context.commit('CAMERA_ALLOW_EDITING', payload)
        },
    },
    getters: {},
}
