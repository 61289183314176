import backendApi from '../../backend/api'

export default {
    namespaced: true,
    state: () => ({
        list: [],
        listIsUpdating: false,
    }),
    mutations: {
        UPDATE_STATUS(store, payload) {
            store.list = payload
        },
    },
    actions: {
        updateStatusList(context) {
            context.state.listIsUpdating = true
            return backendApi
                .getStatus(context.rootState.login.user.token)
                .then((data) => {
                    context.commit('UPDATE_STATUS', data)
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                })
        },
    },
    getters: {},
}
