<template>
    <div>
        <Documents
            :mission-order="missionOrder"
            :documents="documents"
            :edit-document-name-func="editDocumentNameFunc"
            :delete-document-func="deleteDocumentFunc"
            :take-picture-func="takePictureFunc"
            :take-video-func="takeVideoFunc"
            @document:add="documentAddModal"
            @image:edit="imageEdit"
        />
        <BlankModal ref="docModal" :modal-id="docAddModalId">
            <template v-slot:modalHeader>Ajout de documents</template>
            <template v-slot:modalBody>
                <FileUpload :files-uploaded.sync="filesUploadedToAdd" />
            </template>
            <template v-slot:modalFooterExtra>
                <button
                    v-if="filesUploadedToAdd.length > 0"
                    class="btn btn-primary"
                    @click="addDocuments"
                    :disabled="addDocumentsInProgress"
                >
                    Ajouter les fichiers
                    <div v-if="addDocumentsInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                </button>
            </template>
        </BlankModal>
        <BlankModal ref="imgEditorModal" :modal-id="imgEditorModalId" :is-full-screen="true">
            <template v-slot:modalHeader>Edition d'image</template>
            <template v-slot:modalBody>
                <ImageEditor
                    ref="imageEditor"
                    :image-data-to-edit="imageToEdit"
                    :at-save="saveEditedImage"
                    :identifier="identifier"
                    :coms="commentsInput"
                />
            </template>
            <template v-slot:modalFooterExtra>
                <input placeholder="Votre Commentaire" v-model="coms" />
                <button class="btn btn-outline-info" @click="editorActionActivateCircle">Cerclage</button>
                <button class="btn btn-outline-info" @click="editorActionActivateArrow">Fleche</button>
                <button class="btn btn-outline-info" @click="editorActionActivateTrait">Traits</button>
                <button class="btn btn-outline-info" @click="editorActionUndo">Annuler</button>
                <button class="btn btn-outline-info" @click="editorActionSave">Sauvegarder</button>
            </template>
        </BlankModal>
    </div>
</template>

<script>
import Documents from '@/components/Documents'
import BlankModal from '@/components/BlankModal'
import ImageEditor from '@/components/ImageEditor'
import FileUpload from '@/components/FileUpload'
import random from '@/helpers/random'

export default {
    name: 'DocumentsViewAndLoad',
    components: { FileUpload, ImageEditor, BlankModal, Documents },
    data: () => ({
        docAddModalId: random.htmlId(),
        coms: '',
        identifier: '',
        imgEditorModalId: random.htmlId(),
        filesUploadedToAdd: [],
        imageToEdit: ImageEditor.props.imageDataToEdit.default(),
        addDocumentsInProgress: false,
    }),
    props: {
        missionOrder: {
            type: Object,
            required: true,
        },
        documents: {
            type: Array,
            required: true,
        },
        addDocumentsMethod: {
            type: Function,
            required: true,
        },
        saveEditedImageMethod: {
            type: Function,
            required: true,
        },
        editDocumentNameFunc: {
            type: Function,
            required: true,
        },
        deleteDocumentFunc: {
            type: Function,
            required: true,
        },
    },
    methods: {
        documentAddModal() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.docModal.$el).show()
        },
        imageEdit(file, identifier) {
            this.identifier = identifier
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                let img = new Image()
                img.addEventListener('load', () => {
                    this.imageToEdit = Object.assign(
                        {},
                        {
                            dataUrl: img.src,
                            width: img.width,
                            height: img.height,
                            originalName: file.name,
                        },
                    )
                })
                img.src = reader.result.toString()
            })
            reader.readAsDataURL(file)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.imgEditorModal.$el).show()
        },
        addDocuments() {
            this.addDocumentsInProgress = true
            this.addDocumentsMethod(this.filesUploadedToAdd)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.docModal.$el).hide()
                    this.filesUploadedToAdd = []
                })
                .finally(() => {
                    this.addDocumentsInProgress = false
                })
        },
        saveEditedImage({ imageData, originalName, identifier }) {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.imgEditorModal.$el).hide()
            fetch(imageData)
                .then((response) => response.blob())
                .then(
                    (blob) =>
                        new File(
                            [blob],
                            originalName.replace(/\.[^.]+$/, '') + '_annoté.' + blob.type.replace(/^[^/]+\//, ''),
                            { type: blob.type },
                        ),
                )
                .then((file) => this.saveEditedImageMethod(file, identifier))
        },
        takePictureFunc(image) {
            let imageName = image.webPath.replace(/^(.+\/)([^/]+)$/, '$2')
            if (!image.webPath.match(/\.[^./]+$/)) {
                imageName += image.format
            }

            return fetch(image.webPath)
                .then((response) => response.blob())
                .then((blob) => new File([blob], imageName, { type: blob.type }))
                .then((file) => this.saveEditedImageMethod(file))
        },
        takeVideoFunc(video) {
            return this.saveEditedImageMethod(video)
        },
        editorActionActivateCircle() {
            this.$refs.imageEditor.activateCircle()
        },
        editorActionActivateArrow() {
            this.$refs.imageEditor.activateArrow()
        },
        editorActionUndo() {
            this.$refs.imageEditor.undo()
        },
        editorActionSave() {
            this.$refs.imageEditor.save()
        },
        editorActionActivateTrait() {
            this.$refs.imageEditor.activateTrait()
        },
    },
    computed: {
        commentsInput() {
            return this.coms
        },
    },
}
</script>

<style scoped></style>
