<template>
    <div>
        <div class="box">
            <input type="file" :ref="ref" hidden @change="onChange" @click="onClick" />
            <img v-if="type == 'photo'" class="img" :src="getPhoto" @click="onImgClicked" />
            <div class="boxVideo" v-else>
                <video class="video" :src="photo.binary" @click="onImgClicked" :id="videoId"></video>
                <div class="boxPlay">
                    <i class="fa-solid play" :class="!isPlaying ? 'fa-play' : 'fa-pause'" @click="onPlay"></i>
                </div>
            </div>
            <i v-if="photo.file" class="close fa-solid fa-circle-xmark" @click="removePhoto"></i>
            <span
                v-if="makeMeasurement"
                class="metrage fa-solid fa-ruler"
                @click="openMettrage(numPhoto)"
                title="Effectuer un métré"
            ></span>

            <MobileRecorderChooser v-if="$capacitor.isNative" ref="modalMobileRecorderChooser" @choosed="choosed" />
            <MobileVideoRecorder v-if="$capacitor.isNative" ref="modalMobileVideoRecorder" @saved="onVideoRecorded" />
        </div>
        <div v-if="uploadProgress !== undefined">
            <ProgressBar :progress="uploadProgress" class="mt-2 mb-2" style="width: 100%" />
            <button
                v-if="abortController !== undefined"
                class="btn btn-warning mb-2"
                type="button"
                style="width: 100%"
                @click="abortUpload"
            >
                Annuler
            </button>
        </div>
    </div>
</template>

<script>
import gallery from '@/assets/gallery.png'
import signature from '@/assets/signature.png'
import store from '@/store'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import backendApi from '@/backend/api'
import MobileRecorderChooser from '@/components/modals/MobileRecorderChooser'
import MobileVideoRecorder from '@/components/modals/MobileVideoRecorder'
import { FileImage } from '@/Enum/FileType'
import ProgressBar from '@/components/ProgressBar'

export default {
    components: {
        ProgressBar,
        MobileRecorderChooser,
        MobileVideoRecorder,
    },
    props: {
        value: {
            require: true,
        },
        upload: {
            type: Boolean,
            require: false,
            default: false,
        },
        clickable: {
            type: Boolean,
            require: false,
            default: true,
        },
        signature: {
            type: Boolean,
            require: false,
            default: false,
        },
        event: {
            type: Boolean,
            required: false,
            default: false,
        },
        maxSize: {
            type: Number,
            required: false,
            default: 20000,
        },
        openMettrage: {
            type: Function,
            required: true,
        },
        makeMeasurement: {
            type: Boolean,
            require: false,
            default: false,
        },
        numPhoto: {
            type: Number,
            require: false,
            default: 1,
        },
    },
    data() {
        return {
            gallery,
            photo: {
                file: undefined,
                binary: undefined,
            },
            ref: '',
            pictureUploadInProgress: false,
            type: 'photo',
            isPlaying: false,
            videoId: '',
            videoPlayer: undefined,
            uploadProgress: undefined,
            abortController: undefined,
        }
    },
    methods: {
        onClick(e) {
            e.target.value = null
        },
        abortUpload() {
            if (!this.abortController) return
            this.abortController.abort('canceled by user')
            this.abortController = undefined
            this.uploadProgress = undefined
        },
        takePictureFunc(image) {
            let imageName = image.webPath.replace(/^(.+\/)([^/]+)$/, '$2')
            if (!image.webPath.match(/\.[^./]+$/)) {
                imageName += image.format
            }

            return fetch(image.webPath)
                .then((response) => response.blob())
                .then((blob) => new File([blob], imageName, { type: blob.type }))
                .then((file) => this.uploadFile(file))
        },
        takePicture: async function () {
            let retry, image
            do {
                retry = false
                image = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: store.state.parameters.device.camera.allowEditing,
                    resultType: CameraResultType.Uri,
                    webUseInput: true,
                    source: CameraSource.Camera,
                }).catch(async (error) => {
                    if (error.message === 'Unable to edit image') {
                        await store.dispatch('notifications/addNotification', {
                            status: 'warning',
                            message:
                                "Une erreur est survenue lors de la prise de photo. L'erreur détectée a été fixée et ne devrait plus survenir. L'application photo va être relancée dans quelques instants. Veuillez reprendre votre photo.",
                        })
                        store.commit('parameters/CAMERA_ALLOW_EDITING', false)
                        retry = true
                        await new Promise((resolve) => setTimeout(resolve, 7000))
                    } else if (error.message === 'User cancelled photos app') {
                        // do nothing
                    } else {
                        backendApi.notificationOnError(error)
                    }
                })
            } while (retry)

            if (!image || !image.webPath) {
                return
            }

            this.pictureUploadInProgress = true
            this.takePictureFunc(image)
            // .then(() => {
            //   store.dispatch('notifications/addNotification', { status: 'success', message: 'Photo ajoutée' })
            // })
            // .catch(backendApi.notificationOnError)
            // .finally(() => {
            //   this.pictureUploadInProgress = false;
            // })
        },
        onImgClicked() {
            if (this.clickable) {
                if (this.$capacitor.isNative) {
                    // this.takePicture()
                    this.$refs.modalMobileRecorderChooser.open()
                } else {
                    this.$refs[this.ref].click()
                }
            } else {
                this.$emit('click')
            }
        },
        onChange(e) {
            if (e.target.files[0].type.includes('image') || e.target.files[0].type.includes('video')) {
                if (e.target.files[0].size > this.maxSize * 1000) {
                    this.$store.dispatch('notifications/showWarning', 'Fichier trop volumineux')
                } else {
                    this.uploadFile(e.target.files[0])
                }
            } else {
                this.$store.dispatch('notifications/showWarning', 'Fichier non supporté')
            }
        },
        async uploadFile(file) {
            const b64 = await this.$util.fileTob64(file)
            this.type = 'photo'
            if (file.type.includes('video')) {
                this.type = 'video'
            }
            if (this.upload) {
                this.uploadProgress = 0
                this.abortController = new AbortController()
                this.$http
                    .uploadFile(
                        this.$store.state.login.user.token,
                        { file, event: this.event },
                        null,
                        (progressEvent) => {
                            this.uploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                        },
                        this.abortController,
                    )
                    .then((res) => {
                        this.photo.file = file
                        this.photo.binary = b64
                        this.photo.doc = res
                        this.$emit('input', this.photo)
                    })
                    .catch((e) => {
                        this.removePhoto()
                        this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                    })
                    .finally(() => {
                        this.uploadProgress = undefined
                        this.abortController = undefined
                    })
            } else {
                this.photo.file = file
                this.photo.binary = b64
                this.$emit('input', this.photo)
            }
        },
        removePhoto() {
            this.type = 'photo'
            this.$emit('input', undefined)
            this.photo = {
                file: undefined,
                binary: undefined,
            }
        },
        onPlay() {
            this.isPlaying = !this.isPlaying
            const player = document.getElementById(this.videoId)
            if (this.isPlaying) {
                player.play()
            } else {
                player.pause()
            }
        },
        choosed(type) {
            if (type == FileImage) this.takePicture()
            else this.$refs.modalMobileVideoRecorder.open()
        },
        onVideoRecorded(videoFile) {
            this.uploadFile(videoFile)
        },
    },
    computed: {
        getPhoto() {
            if (this.value) {
                if (typeof this.value === 'object') return this.value.binary
                return this.value
            }
            if (this.photo.binary) return this.photo.binary
            if (this.signature) return signature
            return gallery
        },
    },
    mounted() {
        this.ref = this.$util.htmlId(10)
        this.videoId = this.ref
    },
}
</script>

<style lang="sass" scoped>
.box
  cursor: pointer
  position: relative
  .img
    width: 100%
    height: 100%
    object-fit: cover
    border: 1px solid grey

  .boxVideo
    width: 100%
    height: 100%

  .video
    width: 100%
    height: 100%
    background-color: #124673

  .boxPlay
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    display: flex
    align-items: center
    justify-content: center
    background-color: #124673
    border-radius: 50%
    width: 40px
    height: 40px
  .play
    color: #fff
    font-size: 20px
    margin-left: 4px

  .close
    position: absolute
    background-color: #fff
    right: 5px
    top: 5px
    border-radius: 50%
  .metrage
    position: absolute
    background-color: #fff
    right: -14px
    bottom: -14px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    padding: 12px
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px
</style>
