import backendApi from '../../backend/api'
import { Geolocation } from '@capacitor/geolocation'

export default {
    namespaced: true,
    state: () => ({
        list: [],
        listIsUpdating: false,
        currentPosition: undefined,
    }),
    mutations: {
        UPDATE_USERS(store, payload) {
            store.list = payload
        },
    },
    actions: {
        updateUsersList(context) {
            context.state.listIsUpdating = true
            return backendApi
                .getUsersList(context.rootState.login.user.token)
                .then((data) => {
                    context.commit('UPDATE_USERS', data)
                })
                .catch(backendApi.notificationOnError)
                .catch(() => {
                    context.commit('UPDATE_USERS', [])
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                })
        },
        updateSuezUsersList(context) {
            context.state.listIsUpdating = true
            return backendApi
                .getSuezUsersList(context.rootState.login.user.token)
                .then((data) => {
                    context.commit('UPDATE_USERS', data)
                })
                .catch(backendApi.notificationOnError)
                .catch(() => {
                    context.commit('UPDATE_USERS', [])
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                })
        },
        updateSuezList(context) {
            context.state.listIsUpdating = true
            return backendApi
                .getSuezList(context.rootState.login.user.token)
                .then((data) => {
                    context.commit('UPDATE_USERS', data)
                })
                .catch(backendApi.notificationOnError)
                .catch(() => {
                    context.commit('UPDATE_USERS', [])
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                })
        },
        async updatePosition(context) {
            return new Promise((resolve, reject) => {
                Geolocation.getCurrentPosition()
                    .then((coordinates) => {
                        context.state.currentPosition = coordinates
                        resolve(coordinates)
                    })
                    .catch(reject)
            })
        },
    },
    getters: {
        getUserFromList: (state) => (id) => {
            return state.list.find((user) => user.id == id)
        },
        getPosition(state) {
            return state.currentPosition
        },
    },
}
