<template>
    <div>
        <SimpleAccordion>
            <template v-slot:accordionTitle> Commentaire externe de l'ordre de service </template>
            <template v-slot:accordionContent>
                <p class="p-20">{{ orderMission.osCommentaireExterne }}</p>
            </template>
        </SimpleAccordion>
    </div>
</template>
<script>
export default {
    props: {
        orderMission: {
            type: Object,
            require: true,
        },
    },
}
</script>
<style>
.p-20 {
    padding: 20px;
}
</style>
