import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import notifications from './modules/notifications'
import dashboard from './modules/dashboard'
import status from './modules/status'
import users from './modules/users'
import missionOrders from './modules/missionOrders'
import localNotifications from './modules/localNotifications'
import remoteNotifications from './modules/remoteNotifications'
import parameters from '@/store/modules/parameters'
import event from '@/store/modules/event'
import adminEvent from '@/store/modules/adminEvent'
import { adminForm } from '@/store/modules/adminForm'
import tabs from '@/store/modules/tabs'
import { LocalNotifications } from '@capacitor/local-notifications'
import absences from './modules/absences'
import { requestNotificationPermission } from '@/helpers/permissions'
import { PERMISSION_GRANTED } from '@/constantes'
import util from '@/helpers/util'
import { ANDROID_WEB, IOS_MOBILE, IOS_WEB } from '@/constantes/platform'
import missionOrderFilters from '@/store/modules/missionOrderFilters'
import breadcrumbs from './modules/breadcrumbs'
import osRefreshTime from './modules/osRefreshTime'
import domain from './modules/domain'
import { missionOrdersList } from './modules/missionOrdersList'

const platform = util.getPlatform()

Vue.use(Vuex)

export const modules = {
    login,
    notifications,
    dashboard,
    status,
    users,
    missionOrders,
    localNotifications,
    remoteNotifications,
    parameters,
    event,
    adminEvent,
    adminForm,
    tabs,
    absences,
    missionOrderFilters,
    breadcrumbs,
    osRefreshTime,
    missionOrdersList,
    domain,
}

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: modules,
})

store.watch(
    (state) => state.missionOrders.currentMissionOrder,
    () => {
        store
            .dispatch('missionOrders/clearMainChatTicker')
            .then(() => store.dispatch('missionOrders/updateMainChatTicker'))
        store
            .dispatch('missionOrders/clearDocumentsTicker')
            .then(() => store.dispatch('missionOrders/updateDocumentsTicker'))
    },
)

/**
 * Wrapped notification system to use only on mobile browser *  will use service worker
 */
if (![ANDROID_WEB, IOS_WEB].includes(platform)) {
    requestNotificationPermission()
        .then(({ display }) => {
            if (display !== 'granted' && ![IOS_WEB, IOS_MOBILE].includes(platform)) {
                store.dispatch('notifications/addNotification', {
                    status: 'warning',
                    message: 'Autorisez les notifications pour cette application si vous souhaitez en recevoir',
                })
            }
        })
        .catch((e) => console.log('from', e))

    store.watch(
        (state) => state.localNotifications.notifications,
        () => {
            LocalNotifications.checkPermissions().then(({ display }) => {
                if (display === PERMISSION_GRANTED) {
                    while (store.state.localNotifications.notifications.length) {
                        let notification = store.state.localNotifications.notifications.pop()
                        LocalNotifications.schedule({ notifications: [notification] })
                    }
                }
            })
        },
    )
}

store.watch(
    (state) => state.login.user.token,
    (token) => {
        if (!token) {
            store
                .dispatch('remoteNotifications/clearNotificationTicker')
                .then(() => store.commit('remoteNotifications/CLEAR_NOTIFICATIONS'))
        } else {
            store.dispatch('remoteNotifications/updateNotificationTicker')
        }
    },
)

store.watch(
    (state) => state.missionOrderFilters.dateRange,
    async () => {
        await store.dispatch('dashboard/updateDashboardData')
    },
    { deep: true },
)

store.watch(
    (state) => state.missionOrderFilters.selectedStatuses,
    async () => {
        await store.dispatch('dashboard/updateDashboardData')
    },
    { deep: true },
)

store.watch(
    (state) => state.missionOrderFilters.suezOperator.selected,
    async () => {
        await store.dispatch('dashboard/updateDashboardData')
    },
    { deep: true },
)

store.watch(
    (state) => state.missionOrderFilters.workerAdmin.selected,
    async () => {
        await store.dispatch('dashboard/updateDashboardData')
    },
    { deep: true },
)

store.watch(
    (state) => state.missionOrderFilters,
    async () => {
        await store.commit('missionOrdersList/clearMissionOrders')
        await store.dispatch('missionOrdersList/getMissionOrders')
    },
    { deep: true },
)

export default store
