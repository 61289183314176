/* eslint-disable no-console */
import { register } from 'register-service-worker'
import { PERMISSION_GRANTED } from '@/constantes'
import store from '@/store'
import util from '@/helpers/util'
import { ANDROID_WEB, IOS_WEB, IOS_MOBILE } from './constantes/platform'
const platform = util.getPlatform()

register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
        console.log(
            'App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB',
        )
        if ([ANDROID_WEB, IOS_WEB].includes(platform)) {
            initServiceWorkerNotification(registration)
        }
    },
    registered(serviceWorkerRegistration) {
        console.log('Service worker has been registered.')
        if (process.env.NODE_ENV === 'production') {
            setInterval(() => {
                serviceWorkerRegistration
                    .update()
                    .then(() => {
                        console.log('update !')
                    })
                    .catch((reason) => {
                        console.log(`update error: ${reason}`)
                    })
            }, 60000)
        }
    },
    cached() {
        console.log('Content has been cached for offline use.')
    },
    updatefound() {
        console.log('New content is downloading.')
    },
    updated(serviceWorkerRegistration) {
        console.log('New content is available; please refresh.')
        serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
        window.location.reload()
        // we can have a better approach with sending a message to user and propose to him to make a reloading by itself for update if wanted
    },
    offline() {
        console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
        console.error('Error during service worker registration:', error)
    },
})

const initServiceWorkerNotification = (registration) => {
    Notification.requestPermission()
        .then((data) => {
            if (data !== PERMISSION_GRANTED && ![IOS_WEB, IOS_MOBILE].includes(platform)) {
                store.dispatch('notifications/addNotification', {
                    status: 'warning',
                    message: 'Autorisez les notifications pour cette application si vous souhaitez en recevoir',
                })
            }
        })
        .catch((e) => console.log('e', e))

    store.watch(
        (state) => state.localNotifications.notifications,
        () => {
            if (Notification.permission === PERMISSION_GRANTED) {
                while (store.state.localNotifications.notifications.length) {
                    let notification = store.state.localNotifications.notifications.pop()
                    registration.showNotification(notification.title, notification)
                }
            }
        },
    )
}
