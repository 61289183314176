<template>
    <Editor :canvasWidth="wh.width" :canvasHeight="wh.height" ref="editor" :editor-id="editorId" />
</template>

<script>
import Editor from 'vue-image-markup'
import random from '@/helpers/random'
export default {
    name: 'ImageEditor',
    components: {
        Editor,
    },
    data: () => ({
        editorId: random.htmlId(),
        wh: {
            width: 0,
            height: 0,
        },
    }),
    props: {
        imageDataToEdit: {
            type: Object,
            default: () => ({
                dataUrl: '',
                width: 0,
                height: 0,
                originalName: '',
            }),
        },
        atSave: {
            type: Function,
            default: () => () => {},
        },
        identifier: {
            type: String,
            default: null,
        },
        coms: {
            type: String,
            default: null,
        },
    },
    watch: {
        imageDataToEdit: function () {
            this.refresh()
        },
        coms() {
            this.activateComment()
        },
    },
    computed: {},
    methods: {
        activateCircle() {
            let circleModeParams = { fill: 'transparent', stroke: 'red' }
            this.$refs.editor.set('circle', circleModeParams)
        },
        activateTrait() {
            let customizeFreeDrawing = { stroke: 'yellow', strokeWidth: '5' }
            this.$refs.editor.set('freeDrawing', customizeFreeDrawing)
        },
        activateComment() {
            let textModeOptions = {
                id: 'title',
                fill: 'red',
                fontFamily: 'Verdana',
                fontSize: 16,
                placeholder: this.coms,
            }
            this.$refs.editor.set('text', textModeOptions)
        },
        activateArrow() {
            let customizeArrow = { stroke: 'red' }
            this.$refs.editor.set('arrow', customizeArrow)
        },
        undo() {
            this.refresh()
        },
        refresh() {
            this.$refs.editor.clear()
            setTimeout(() => {
                let wh = {
                    width: this.imageDataToEdit.width,
                    height: this.imageDataToEdit.height,
                }

                let clientWidth = Math.ceil(this.$refs.editor.canvas.wrapperEl.parentNode.parentNode.clientWidth * 0.9)
                let clientHeight = Math.ceil(
                    this.$refs.editor.canvas.wrapperEl.parentNode.parentNode.clientHeight * 0.9,
                )

                let clientRatio = clientWidth / clientHeight
                let imageRatio = this.imageDataToEdit.width / this.imageDataToEdit.height

                if ((imageRatio < 1 && imageRatio < clientRatio) || (imageRatio > 1 && imageRatio < clientRatio)) {
                    wh.height = clientHeight
                    wh.width = Math.ceil((this.imageDataToEdit.width * clientHeight) / this.imageDataToEdit.height)
                } else {
                    wh.width = clientWidth
                    wh.height = Math.ceil((this.imageDataToEdit.height * clientWidth) / this.imageDataToEdit.width)
                }

                this.wh = wh

                this.$refs.editor.canvas.setDimensions(wh)
                this.$refs.editor.setBackgroundImage(this.imageDataToEdit.dataUrl)
            }, 250)
        },
        save() {
            this.$refs.editor.cancelCroppingImage()
            let imageData = this.$refs.editor.canvas.toDataURL('image/jpeg', 0.5)
            this.atSave({ imageData, originalName: this.imageDataToEdit.originalName, identifier: this.identifier })
        },
    },
}
</script>

<style scoped></style>
