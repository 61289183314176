'use strict'

import axios from 'axios'
import download from '@/helpers/download'
const qs = require('qs')
const FormData = require('form-data')
let store = () => import('@/store')

const TIMEOUT_AXIOS = 600

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_MODEL_API_ROOT,
    headers: {
        common: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    },
    timeout: process.env.NODE_ENV === 'production' ? 1000 * TIMEOUT_AXIOS : 1000 * TIMEOUT_AXIOS,
})

class backendApi {
    constructor(axios, store) {
        this.axios = axios
        this.store = undefined
        store().then((module) => {
            this.store = module.default
        })
    }

    setSupportAccess(username, token) {
        this.axios.defaults.headers.common['x-bypass-username'] = username
        this.axios.defaults.headers.common['x-bypass-token'] = token
    }

    deleteSupportAccess() {
        this.axios.defaults.headers.common['x-bypass-username'] = undefined
        this.axios.defaults.headers.common['x-bypass-token'] = undefined
    }

    getUserInfo(token) {
        return this.axios
            .get('/user', { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
    }

    loginUser(username, password) {
        return this.axios
            .post('/login', { username, password })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    loginUpdate(token) {
        return this.axios
            .get('/login', { headers: { Authorization: 'Bearer ' + token } })
            .then((response) => response.data)
    }

    getMissionOrders(token, serverParams, isSuez = false, isAdminFunc = false) {
        const params = qs.stringify(serverParams)
        const urlOs = isSuez || isAdminFunc ? '/mission-orders-operator-suez?' : '/mission-orders?'
        return this.axios
            .get(urlOs + params, {
                // params,
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    subContractingCompanies(token, serverParams) {
        const params = qs.stringify(serverParams)
        const subContractingCompanies = '/company-referential?'
        return this.axios
            .get(subContractingCompanies + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getImportExportDq(token, serverParams) {
        const params = qs.stringify(serverParams)
        const url = '/flow-import-export-dq?'
        return this.axios
            .get(url + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getImportSuezUsers(token, serverParams) {
        const params = qs.stringify(serverParams)
        const url = '/flow-import-user-suez?'
        return this.axios
            .get(url + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getImportExternalUsers(token, serverParams) {
        const params = qs.stringify(serverParams)
        const url = '/flow-import-user-worker?'
        return this.axios
            .get(url + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getLogFlagTSms(token, serverParams) {
        const params = qs.stringify(serverParams)
        const url = '/flow-flag-t-sms?'
        return this.axios
            .get(url + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    repositoryOfSuezRegions(token, serverParams) {
        const params = qs.stringify(serverParams)
        const url = '/sub-agency?'
        return this.axios
            .get(url + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getMissionOrder(token, missionOrderId) {
        return this.axios
            .get(`/mission-order/${missionOrderId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getMissionOrderMainChat(token, missionOrderId) {
        return this.axios
            .get(`/mission-order/${missionOrderId}/main-chat`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    addMessageToMissionOrderMainChat(token, missionOrderId, message) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/main-chat/new-message`,
                { message },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    addDocumentsToMissionOrder(token, missionOrderId, documents) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/add-documents`,
                { documents },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    renameMissionOrderDocument(token, missionOrderId, documentId, name) {
        return this.axios
            .post(
                `/mission-order/${missionOrderId}/document/${documentId}/rename`,
                { name },
                {
                    headers: { Authorization: 'Bearer ' + token },
                },
            )
            .then((response) => response.data)
    }

    deleteMissionOrderDocument(token, missionOrderId, documentId) {
        return this.axios
            .delete(`/mission-order/${missionOrderId}/document/${documentId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getDashboardData(token, filtersData) {
        const params = qs.stringify(filtersData)
        return this.axios
            .get('/dashboard?' + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getStatus(token) {
        return this.axios
            .get(`/status`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    editStatus(token, status) {
        return this.axios
            .post(`/status/${status.id}`, status, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    deleteStatus(token, statusId) {
        return this.axios
            .delete(`/status/${statusId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    createStatus(token, newStatus) {
        return this.axios
            .post(`/status`, newStatus, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }
    downloadDocument(
        token,
        identifier,
        originalName,
        autoDownload = true,
        downloadProgressCallback = () => {},
        abortController = new AbortController(),
    ) {
        return this.axios
            .get('/download/' + identifier, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then(async (response) => {
                return axios.create().get(response.data.link, {
                    onDownloadProgress: downloadProgressCallback,
                    signal: abortController.signal,
                    responseType: 'blob',
                })
            })
            .then(async (response) => {
                let blob = new Blob([response.data])
                if (autoDownload) {
                    await download.downloadBlob(blob, originalName)
                    if (this.store) {
                        this.store.dispatch('notifications/addNotification', {
                            status: 'success',
                            message: 'Fichier téléchargé dans votre dossier de téléchargement',
                        })
                    }
                }
                return new File([blob], originalName, { type: response.headers['content-type'] })
            })
            .catch(this.notificationOnError)
    }

    async uploadFile(
        token,
        data,
        identifier,
        uploadProgressCallback = () => {},
        abortController = new AbortController(),
    ) {
        const form = new FormData()
        form.append('file', data.file, data.file.name)
        const coordinates = await this.store.dispatch('users/updatePosition').catch(() => {})

        // set location
        if (coordinates) {
            form.append('lat', coordinates.coords.latitude)
            form.append('lng', coordinates.coords.longitude)
        }

        if (typeof identifier != 'function') {
            form.append('identifier', identifier)
        }

        if (data.event) {
            form.append('event', data.event)
        }

        return this.axios
            .post('/upload', form, {
                timeout: 0,
                onUploadProgress: typeof identifier == 'function' ? identifier : uploadProgressCallback,
                signal: abortController.signal,
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => response.data)
    }

    updateSelfInfos(token, userInfos) {
        return this.axios
            .patch(`/user`, userInfos, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getUsersList(token) {
        return this.axios
            .get(`/users`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getWorkerUsersList(token, serverParams) {
        const params = qs.stringify(serverParams)
        const url = '/users_worker?'
        return this.axios
            .get(url + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getSuezUsersList(token, serverParams) {
        const params = qs.stringify(serverParams)
        const url = '/users_suez?'
        return this.axios
            .get(url + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getSuezList(token) {
        return this.axios
            .get(`/users-suez-only`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    createUser(token, userData) {
        return this.axios
            .post(`/user`, userData, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    updateUser(token, userData) {
        return this.axios
            .patch(`/user/${userData.id}`, userData, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getMessageReadHistory(token) {
        return this.axios
            .get(`/message-read-history`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    getMessageReadHistoryFrom(token, from) {
        const params = qs.stringify({ from })
        return this.axios
            .get(`/message-read-history?` + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    deleteMessageReadHistory(token, messageReadHistoryId) {
        return this.axios
            .delete(`/message-read-history/${messageReadHistoryId}`, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }

    notificationOnError = (error) => {
        let message = 'error unknown'

        if (error?.message) {
            message = error?.message
        }

        if (error?.response?.data?.message) {
            message = error?.response?.data?.message
        }

        if (error?.response?.data?.exception?.message) {
            message = error?.response?.data?.exception?.message
        }

        error.message = message

        if (this.store) {
            this.store.dispatch('notifications/addNotification', { message, status: 'danger' })
        }

        throw error
    }
    async declareEvent(osId, data) {
        const form = new FormData()
        for (const key in data) {
            form.append(key, data[key])
        }

        const coordinates = await this.store.dispatch('users/updatePosition').catch(() => {})

        // set location
        if (coordinates) {
            form.append('lat', coordinates.coords.latitude)
            form.append('lng', coordinates.coords.longitude)
        }

        // form.append('file', file, file.name);
        return this.axios
            .post(`/event/${osId}`, form, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    getAllEventsByOsId(osId) {
        return this.axios
            .get(`/event/mission-order/${osId}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    addDomain(data) {
        return this.axios
            .post(`/new-event-domain`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteDomain(id) {
        return this.axios
            .delete(`/delete-event-domain/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateDomain(id, data) {
        return this.axios
            .put(`/update-event-domain/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllDomaines() {
        return this.axios
            .get(`/event-domain`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    getAllDomainesWithChild() {
        return this.axios
            .get(`/event-domain-with-child`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    addAction(data) {
        return this.axios
            .post(`/new-event-action`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    deleteAction(id) {
        return this.axios
            .delete(`/delete-event-action/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateAction(id, data) {
        return this.axios
            .put(`/update-event-action/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    getAllActions() {
        return this.axios
            .get(`/event-action`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    addFamily(data) {
        return this.axios
            .post(`/new-event-family`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteFamily(id) {
        return this.axios
            .delete(`/delete-event-family/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateFamily(id, data) {
        return this.axios
            .put(`/update-event-family/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllFamilies() {
        return this.axios
            .get(`/event-family`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    addType(data) {
        return this.axios
            .post(`/new-event-definition`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteType(id) {
        return this.axios
            .delete(`/delete-event-definition/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateType(id, data) {
        return this.axios
            .put(`/update-event-definition/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllTypes() {
        return this.axios
            .get(`/event-definition`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    addTypeStatus(typeId, statusId) {
        return this.axios
            .post(
                `/event-definition/${typeId}/status/${statusId}`,
                {},
                {
                    headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
                },
            )
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteTypeStatus(typeId, statusId) {
        return this.axios
            .delete(`/event-definition/${typeId}/status/${statusId}/delete`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    // FORM ADMIN
    addList(data) {
        return this.axios
            .post(`/new-list-of-value`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteList(id) {
        return this.axios
            .delete(`/delete-list-of-value/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllList() {
        return this.axios
            .get(`/list-of-value`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateList(id, data) {
        return this.axios
            .put(`/update-list-of-value/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    addListValue(data) {
        return this.axios
            .post(`/list-values`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteListValue(id) {
        return this.axios
            .delete(`/delete-list-values/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllListValue() {
        return this.axios
            .get(`/list-values`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateListValue(id, data) {
        return this.axios
            .put(`/update-list-values/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    addFieldType(data) {
        return this.axios
            .post(`/new-field-type`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteFieldType(id) {
        return this.axios
            .delete(`/delete-field-type/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllFieldType() {
        return this.axios
            .get(`/field-type`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateFieldType(id, data) {
        return this.axios
            .put(`/update-field-type/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    addFieldDefinition(data) {
        return this.axios
            .post(`/new-field-definition`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteFieldDefinition(id) {
        return this.axios
            .delete(`/delete-field-definition/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllFieldDefinition() {
        return this.axios
            .get(`/field-definition`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateFieldDefinition(id, data) {
        return this.axios
            .put(`/update-field-definition/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    addFormDefinition(data) {
        return this.axios
            .post(`/new-form-definition`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteFormDefinition(id) {
        return this.axios
            .delete(`/delete-form-definition/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllFormDefinition() {
        return this.axios
            .get(`/form-definition`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateFormDefinition(id, data) {
        return this.axios
            .put(`/edit-form-definition/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getFormDefinitionById(id) {
        return this.axios
            .get(`/form-definition/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    addFormField(listId, data) {
        return this.axios
            .post(`/new-form-field/${listId}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    deleteFormField(id) {
        return this.axios
            .delete(`/delete-form-field/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAllFormFieldByFormDefinition(formDefinitionId) {
        return this.axios
            .get(`/form-field/${formDefinitionId}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateFormField(id, data) {
        return this.axios
            .put(`/update-form-field/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    updateFormMissionOrder(missionOrderId, data) {
        return this.axios
            .put(`/update-mission-order-form-data/${missionOrderId}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    terminerChantier(missionOrderId, data) {
        return this.axios
            .post(`/complete-mission-order/${missionOrderId}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    exportExcel(serverParams) {
        const params = qs.stringify(serverParams)
        return this.axios
            .get(`/export-mission-orders?` + params, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    assigment(data) {
        return this.axios
            .put(`/update-affected-worker-users`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    assigmentList(token, serverParams) {
        const params = qs.stringify(serverParams)
        return this.axios
            .get('/mission-orders-assignment?' + params, {
                headers: { Authorization: 'Bearer ' + token },
            })
            .then((response) => response.data)
    }
    agencyByAccount(workerAccountId) {
        return this.axios
            .get(`/worker-account-agency/${workerAccountId}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    companyByAccount(workerAccountId) {
        return this.axios
            .get(`/worker-account-company/${workerAccountId}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    updateOrderPriority(OsId, data) {
        return this.axios
            .post(`/update-order-priority/${OsId}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    updateIsSignatureRequired(OsId, data) {
        return this.axios
            .post(`/update-is-signature-required/${OsId}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    workReport(OsId, data) {
        return this.axios
            .get(`/export-mission-order-work-report/${OsId}?documentType=${data}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    getAbsencesList() {
        return this.axios
            .get(`/absence-suez`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    addAbsences(data) {
        return this.axios
            .post(`/new-absence-suez`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    updateAbsences(id, data) {
        return this.axios
            .post(`/update-absence-suez/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    deleteAbsences(id) {
        return this.axios
            .delete(`/delete-absence-suez/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    getTechnicalDetails(missionOrderId) {
        return this.axios
            .get(`technical-details/status-change-dates/${missionOrderId}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    reImportOfQualifyingData(missionOrderId) {
        return this.axios
            .post(`/import-qualifying-data/${missionOrderId}`, [], {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    reExportOfQualifyingData(missionOrderId) {
        return this.axios
            .post(`/export-qualifying-data/${missionOrderId}`, [], {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    reImportExportOfQualifyingData(data) {
        return this.axios
            .post(`/import-export-qualifying-data`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    getRegions() {
        return this.axios
            .get(`/region`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    createUserSuez(data, isEdit, userId) {
        const url = isEdit ? `/update-user-suez/${userId}` : `/create-user-suez`
        return this.axios
            .post(url, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    createUserWorker(data, isEdit, userId) {
        const url = isEdit ? `/update-user-worker/${userId}` : `/create-user-worker`
        return this.axios
            .post(url, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    getUserById(userId) {
        return this.axios
            .get(`user/${userId}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
    }
    getRelaunchOsDays() {
        return this.axios
            .get('/number-of-days-exceeded', {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    saveRelaunchOsDays(numberDays) {
        return this.axios
            .patch('/update-number-of-days-exceeded', numberDays, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getRegionsByUser() {
        return this.axios
            .get(`/regions-by-user`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getAgenciesByRegions(regions) {
        const params = qs.stringify({ regions })
        return this.axios
            .get(`/delta-agencies-by-regions?` + params, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
    getSubagenciesByAgencies(agencies) {
        const params = qs.stringify({ agencies })
        return this.axios
            .get(`/sub-agencies-by-delta-agencies?` + params, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    getAgenciesByUser() {
        return this.axios
            .get(`/agencies-by-user`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    getListCodeStandards(serverParams) {
        const params = qs.stringify(serverParams)
        return this.axios
            .get(`standard-codes?` + params, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    updateStandardCode(id, data) {
        return this.axios
            .patch(`/update-standard-code/${id}`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    createStandardCode(data) {
        return this.axios
            .post(`/new-standard-code`, data, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    deleteStandardCode(id) {
        return this.axios
            .delete(`/delete-standard-code/${id}`, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }

    /**
     * @param token {string}
     * @param offset {number}
     * @param quantity {number}
     * @param filters {Record<string, unknown>}
     */
    getMissionOrderList(offset, quantity, filters) {
        const params = qs.stringify({ offset, quantity, filters })
        return this.axios
            .get(`/mission-order-list?` + params, {
                headers: { Authorization: 'Bearer ' + this.store.state.login.user.token },
            })
            .then((response) => response.data)
            .catch(this.notificationOnError)
    }
}

export default new backendApi(axiosInstance, store)
