export default {
    namespaced: true,
    // TODO: Tokony atao forme arbre satria izay le andrasana
    state: () => ({
        actions: [],
        familles: [],
        types: [],
        events: [],
        options: [],
    }),
    mutations: {},
    actions: {},
    getters: {
        getActionByCode: (state) => (code) => {
            const finded = state.actions.find((o) => o.code == code)
            return finded ? finded.label : 'Not found'
        },
        getFamilleByCode: (state) => (code) => {
            const finded = state.familles.find((o) => o.code == code)
            return finded ? finded.label : 'Not found'
        },
        getEventByCode: (state) => (code) => {
            const finded = state.events.find((o) => o.code == code)
            return finded ? finded.label : 'Not found'
        },
        getTypeByCode: (state) => (code) => {
            const finded = state.types.find((o) => o.code == code)
            return finded ? finded.label : 'Not found'
        },
        getOptionsEvent: (state) => {
            return state.options
        },
    },
}
