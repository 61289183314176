import {ActionContext, Module, Store} from "vuex";
import backendApi from "@/backend/api"
import store from "@/store";
import Vue from "vue";
import {statusEnum} from "@/Enum/Status";

export interface MissionOrder {
    "id": number,
    "property": {
        "address": {
            "formattedAddress": string,
            "lat": number,
            "lng": number,
        }
    },
    "status": {
        "value": string,
    },
    "g2_inter_code": string,
    "expectedStartDate": string,
    "expectedEndDate": string,
    "g2_inter_code_wording": string,
    "osNumeroG2": string,
    "is_signature_required": boolean | null,
    "osLibelleCodeStandard": string,
    "osNumero": string,
    "orderPriority": number | null,
    "osCodeStandard": string,
    "agency": {
        "name": string
    },
    "osCodeRegion": string,
    "osLibelleAgenceDelta": string,
    "osLibelleSousAgence": string,
    "osNomFournisseur": string,
    "osCodeAgenceFournisseur": string,
    "osCodeFournisseur": string,
    "osCommentaireExterne": string,
    "osDateHeureFinPrevue": string,
    "osLibelleRegion": string,
    "osDateHeureDebutPrevue": string,
    "refDemandeG2": string,
}

interface State {
    missionOrders: MissionOrder[]
    isLoading: boolean
    noMoreResult: boolean
}

const missionOrdersList: Module<State, typeof Store> = {
    namespaced: true,
    state: (): State  => ({
        missionOrders: [],
        isLoading: false,
        noMoreResult: false,
    }),
    mutations: {
        addMissionOrders(state: State, missionOrders: MissionOrder[]) {
            Vue.set(state, 'missionOrders', [...state.missionOrders, ...missionOrders])
        },
        clearMissionOrders(state: State) {
            Vue.set(state, 'missionOrders', [])
            state.noMoreResult = false
        }
    },
    actions: {
        async getMissionOrders({state, rootState, commit, dispatch}: ActionContext<State, typeof store>) {
            if (state.isLoading || state.noMoreResult) return
            const offset = state.missionOrders.length
            const quantity = 10
            const filters = {
                dateRange: rootState.missionOrderFilters.dateRange,
                selectedStatuses: (() => {
                    const selected = rootState.missionOrderFilters.selectedStatuses
                    const cleanSelected: Record<string, boolean> = {}
                    for (const key in statusEnum) {
                        cleanSelected[key] = selected[statusEnum[key as keyof typeof statusEnum]]
                    }
                    return cleanSelected
                })(),
                suezOperator: {
                    deltaAgencies: rootState.missionOrderFilters.suezOperator.selected.deltaAgencies.map((data: {code: string}) => data.code),
                    regions: rootState.missionOrderFilters.suezOperator.selected.regions.map((data: {code: string}) => data.code),
                    subAgencies: rootState.missionOrderFilters.suezOperator.selected.subAgencies.map((data: {code: string}) => data.code),
                },
                workerAdmin: {
                    providerAgencies: rootState.missionOrderFilters.workerAdmin.selected.providerAgencies.map((data: { id: number }) => data.id),
                },
                sortBy: rootState.missionOrderFilters.sortBy,
                fieldFilters: rootState.missionOrderFilters.fieldFilters,
            }

            state.isLoading = true

            backendApi
            .getMissionOrderList(offset, quantity, filters)
            .then((response: MissionOrder[]) => {
                if (response.length === 0) {
                    state.noMoreResult = true
                }
                commit('addMissionOrders', response)
            })
            .catch(() => {
                dispatch('notifications/addNotification', {status: 'warning', message: 'Erreur lors de la récupération des OS'}, {root: true})
            })
            .finally(() => {
                state.isLoading = false
            })
        },
    },
    getters: {},
}

export {
    missionOrdersList
}
