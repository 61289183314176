<template>
    <div>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <li class="uk-parent">
                <vk-nav-item-header title="Flux" style="color: white" />
                <ItemsImportExportDq
                    v-if="$store.state.login.user.userData.roles.includes('admin_func')"
                    @nav:close="$emit('nav:close')"
                ></ItemsImportExportDq>
                <ItemsImportUsers
                    v-if="$store.state.login.user.userData.roles.includes('admin_func')"
                    @nav:close="$emit('nav:close')"
                ></ItemsImportUsers>
                <ItemsLogFlagTsms
                    v-if="$store.state.login.user.userData.roles.includes('admin_func')"
                    @nav:close="$emit('nav:close')"
                ></ItemsLogFlagTsms>
            </li>
            <vk-nav-item-divider />
        </ul>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ItemsImportExportDq from './flows/ItemsImportExportDq.vue'
import ItemsLogFlagTsms from './flows/ItemsLogFlagTsms.vue'
import ItemsImportUsers from './flows/ItemsImportUsers.vue'

export default {
    components: {
        ItemsImportExportDq,
        ItemsLogFlagTsms,
        ItemsImportUsers,
    },
    emits: ['nav:close'],
    computed: {
        ...mapState('login', ['user']),
    },
    methods: {
        ...mapActions('login', ['logout']),
    },
}
</script>
