import backendApi from '../../backend/api'
import store from '@/store'
import MissionOrder from '@/components/MissionOrder'

export default {
    namespaced: true,
    state: () => ({
        currentMissionOrderUpdateIsPending: false,
        currentMissionOrderLastError: false,
        currentMissionOrder: MissionOrder.props.missionOrder.default(),
        updateMainChatTicker: null,
        intervalMainChatTicker: 10000,
        updateDocumentsTicker: null,
        intervalDocumentsTicker: 20000,
    }),
    mutations: {
        UPDATE_CURRENT_MISSION_ORDER(store, payload) {
            store.currentMissionOrder = payload
        },
        UPDATE_MAIN_CHAT(store, payload) {
            store.currentMissionOrder.mainChat = payload
        },
        UPDATE_DOCUMENTS(store, payload) {
            store.currentMissionOrder.documents = payload
        },
    },
    actions: {
        setCurrentMissionOrder(context, id) {
            context.state.currentMissionOrderUpdateIsPending = true
            return backendApi
                .getMissionOrder(context.rootState.login.user.token, id)
                .then((data) => {
                    context.commit('UPDATE_CURRENT_MISSION_ORDER', data)
                })
                .finally(() => {
                    context.state.currentMissionOrderUpdateIsPending = false
                })
        },
        updateMainChat(context) {
            let missionOrderId = context.state.currentMissionOrder.id
            if (!missionOrderId) {
                return Promise.resolve()
            }
            return backendApi.getMissionOrderMainChat(store.state.login.user.token, missionOrderId).then((data) => {
                context.commit('UPDATE_MAIN_CHAT', data)
            })
        },
        updateMainChatTicker(context) {
            if (!context.state.updateMainChatTicker && !!context.state.currentMissionOrder.id) {
                context.state.updateMainChatTicker = setInterval(
                    () => context.dispatch('updateMainChat'),
                    context.state.intervalMainChatTicker,
                )
            }
        },
        clearMainChatTicker(context) {
            if (context.state.updateMainChatTicker) {
                clearInterval(context.state.updateMainChatTicker)
                context.state.updateMainChatTicker = null
            }
        },
        updateDocuments(context) {
            let missionOrderId = context.state.currentMissionOrder.id
            if (!missionOrderId) {
                return Promise.resolve()
            }
            return backendApi.getMissionOrder(context.rootState.login.user.token, missionOrderId).then((data) => {
                context.commit('UPDATE_DOCUMENTS', data.documents)
            })
        },
        updateDocumentsTicker(context) {
            if (!context.state.updateDocumentsTicker && !!context.state.currentMissionOrder.id) {
                context.state.updateDocumentsTicker = setInterval(
                    () => context.dispatch('updateDocuments'),
                    context.state.intervalDocumentsTicker,
                )
            }
        },
        clearDocumentsTicker(context) {
            if (context.state.updateDocumentsTicker) {
                clearInterval(context.state.updateDocumentsTicker)
                context.state.updateDocumentsTicker = null
            }
        },
        reset(context) {
            context.state.currentMissionOrder = MissionOrder.props.missionOrder.default()
        },
    },
    getters: {},
}
