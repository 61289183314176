<template>
    <div>
        <input :max="maxDate" type="datetime-local" id="dt" @input="onDateChanged" style="position: absolute" />
        <input type="text" class="uk-input" id="ndt" readonly @click="openPicker" v-model="content" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        disableFuture: {
            required: false,
            default: false,
        },
    },
    data() {
        return {
            content: undefined,
        }
    },
    computed: {
        maxDateSelectionnable() {
            const now = new Date()
            const year = now.getFullYear()
            const month = (now.getMonth() + 1).toString().padStart(2, '0')
            const day = now.getDate().toString().padStart(2, '0')
            const hours = now.getHours().toString().padStart(2, '0')
            const minutes = now.getMinutes().toString().padStart(2, '0')

            return `${year}-${month}-${day}T${hours}:${minutes}`
        },

        maxDate() {
            return this.disableFuture ? this.maxDateSelectionnable : null
        },
    },
    methods: {
        openPicker() {
            document.getElementById('dt').showPicker()
        },
        onDateChanged() {
            const d = new Date(document.getElementById('dt').value)
            const current = new Date()

            if (d.getTime() <= current.getTime()) {
                document.getElementById('ndt').value = this.$util.formatDate(d, 'DD/MM/YYYY', true)
                this.$emit('input', d)
            } else {
                document.getElementById('dt').value = current.toISOString().slice(0, -8)
                document.getElementById('ndt').value = this.$util.formatDate(current, 'DD/MM/YYYY', true)
                this.$emit('input', current)
            }
        },
    },
    mounted() {
        //this.content = this.$util.formatDate(this.value, 'DD/MM/YYYY', true)
    },
    watch: {
        value(val) {
            if (this.value === null) {
                this.content = new Date()
                this.$emit('input', this.content)
            }
            this.content = this.$util.formatDate(val, 'DD/MM/YYYY', true)
        },
    },
}
</script>

<style>
#dt {
    height: 0;
    width: 0;
    border: none;
}

.open {
    width: 30px;
    height: 30px;
    background-color: grey;
    cursor: pointer;
    margin-left: 5px;
    border-radius: 5px;
}
</style>
