import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import store from '@/store'
import { BrowserMultiFormatReader } from '@zxing/library'
const codeReader = new BrowserMultiFormatReader()
const camera = {
    scan() {
        return new Promise((resolve, reject) => {
            Camera.getPhoto({
                quality: 90,
                allowEditing: store.state.parameters.device.camera.allowEditing,
                resultType: CameraResultType.DataUrl,
                webUseInput: true,
                source: CameraSource.Camera,
            })
                .then((image) => {
                    return codeReader.decodeFromImageUrl(image.dataUrl)
                })
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}

export default camera
