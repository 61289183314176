import { BrowserAuthError, InteractionRequiredAuthError, LogLevel, PublicClientApplication } from '@azure/msal-browser'

/**
 * @type {Configuration}
 */
export const azureConfig = {
    auth: {
        clientId: process.env.VUE_APP_B2C_CLIENT_ID,
        authority: `${process.env.VUE_APP_AUTHORITY_URL}${process.env.VUE_APP_SIGN_UP_SIGN_IN}`,
        knownAuthorities: [process.env.VUE_APP_AUTHORITY_DOMAINE],
        redirectUri: process.env.VUE_APP_B2C_REDIRECT_URI ?? window.location.href,
        postLogoutRedirectUri: process.env.VUE_APP_B2C_REDIRECT_URI ?? window.location.href,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message) => {
                console.log({
                    level,
                    message,
                })
            },
            logLevel: LogLevel.Error,
        },
    },
}

export class AzureLogin {
    /**
     * @type {string|null}
     */
    userToken = null
    /**
     * @type {boolean}
     */
    isReady = false
    /**
     * @type {PublicClientApplication}
     */
    msalInstance

    /**
     * @param msalConfig {Configuration}
     * @param handler {(AuthenticationResult) => Promise<void>}
     */
    constructor(msalConfig, handler) {
        this.msalConfig = msalConfig
        this.msalInstance = new PublicClientApplication(msalConfig)
        this.handler = handler
        this.msalInstance.initialize().then(async () => {
            this.isReady = true

            this.msalInstance
                .handleRedirectPromise()
                .then(async (tokenResponse) => {
                    if (!tokenResponse) {
                        return
                    }
                    this.userToken = tokenResponse.idToken
                    await this.handler(tokenResponse)
                    // Check if the tokenResponse is null
                    // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
                    // If the tokenResponse === null, you are not coming back from an auth redirect.
                })
                .catch((error) => {
                    if (error instanceof BrowserAuthError) {
                        window.location.reload()
                        return
                    }
                })

            await this.ssoSilent()
        })
    }

    async loginRedirect() {
        if (!this.isReady) {
            return
        }

        return await this.msalInstance.loginRedirect({ scopes: ['openid', 'profile', 'email'] }).then(() => {
            console.log('loginRedirect')
        })
    }

    async logoutRedirect() {
        if (!this.isReady) {
            return
        }

        await this.msalInstance.logoutRedirect().then(() => {
            console.log('logoutRedirect')
        })

        return this.userToken
    }

    async ssoSilent() {
        if (!this.isReady) {
            return
        }

        await this.msalInstance
            .ssoSilent({ scopes: ['openid', 'profile', 'email'] })
            .then(async (tokenResponse) => {
                this.userToken = tokenResponse.idToken
                await this.handler(tokenResponse)
                return tokenResponse
            })
            .catch((error) => {
                console.log(error)
            })
    }

    async acquireTokenSilent() {
        if (!this.isReady) {
            return
        }

        const request = {
            scopes: ['openid', 'profile', 'email'],
            account: this.msalInstance.getAllAccounts()[0] || undefined,
            forceRefresh: true,
            refreshTokenExpirationOffsetSeconds: 3600 * 24 * 7,
        }

        return await this.msalInstance
            .acquireTokenSilent(request)
            .then(async (tokenResponse) => {
                this.userToken = tokenResponse.idToken
                await this.handler(tokenResponse)
                return tokenResponse
            })
            .catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return await this.loginRedirect()
                }
            })
    }
}
