<template>
    <div class="p-20">
        <vue-good-table :columns="columns" :rows="rows">
            <template slot="loadingContent">
                <span class="vgt-loading__content">Téléchargement...</span>
            </template>
            <template v-slot:table-row="props">
                <div v-if="props.column.field === 'createdAt'">
                    {{ `${$util.formatDate(props.row.createdAt, 'DD/MM/YYYY', true)}` }}
                </div>
            </template>
        </vue-good-table>
    </div>
</template>
<script>
export default {
    props: {
        orderMissionId: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            columns: [
                {
                    label: 'Date de création',
                    field: 'createdAt',
                },
                {
                    label: 'Libellé',
                    field: 'event_definition.body',
                },
                {
                    label: 'Codes retours',
                    field: 'flagTSMS.errorCode',
                },
                {
                    label: 'Commentaire',
                    field: 'comment',
                },
            ],
            rows: [],
        }
    },
    methods: {
        getAllEventsByOsId() {
            this.$http
                .getAllEventsByOsId(this.orderMissionId)
                .then((res) => {
                    this.rows = res
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
    },
    mounted() {
        this.getAllEventsByOsId()
    },
}
</script>
