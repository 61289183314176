import backendApi from '../../backend/api'
import router from '../../router'
import { ROOT_BASE_NAME } from '@/constantes'
import Vue from 'vue'
import { removeAzureData } from '@/helpers/localstorage'

const blankUser = () => ({
    token: null,
    userData: {
        roles: [],
        accountType: null,
    },
    account: null,
})

const STATUS_INACTIF = 'Inactif'

const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : blankUser()

localStorage.setItem('user', JSON.stringify(localUser))

const logoutHandler = (context) => {
    localStorage.setItem('user', JSON.stringify(blankUser()))
    context.commit('UPDATE_USER', blankUser())
    if (router.currentRoute.name != ROOT_BASE_NAME) {
        router.replace({ name: ROOT_BASE_NAME })
    }
}

export default {
    namespaced: true,
    state: () => ({
        user: Object.assign({}, localUser),
        loginError: null,
        loginIsInProgress: false,
        loginSupportIsInProgress: false,
        displayFormSupport: false,
    }),
    mutations: {
        UPDATE_USER(store, payload) {
            store.user = payload
            localStorage.setItem('user', JSON.stringify(store.user))
        },
        UPDATE_USER_DATA(store, payload) {
            store.user.userData = payload
            localStorage.setItem('user', JSON.stringify(store.user))
        },
    },
    actions: {
        setDisplayFormSupport(context, payload) {
            context.state.displayFormSupport = payload
        },
        supportLogin(context, payload) {
            backendApi.setSupportAccess(payload.username, payload.token)
            context.state.loginSupportIsInProgress = true
            let user = Object.assign({}, blankUser)
            user.token = 'support'
            backendApi
                .getUserInfo(payload.token)
                .then((payload) => {
                    user.userData = payload
                    context.commit('UPDATE_USER', user)
                    context.dispatch('notifications/showWarning')
                    context.dispatch('dashboard/updateDashboardData', null, { root: true })
                    window.scroll(0, 0)
                })
                .catch(() => {})
                .finally(() => {
                    context.state.loginSupportIsInProgress = false
                })
        },
        login(context, payload) {
            context.state.loginIsInProgress = true
            let user = Object.assign({}, blankUser)
            backendApi
                .loginUser(payload.username, payload.password)
                .then((payload) => {
                    user.token = payload.token

                    return payload
                })
                .then((payload) => backendApi.getUserInfo(payload.token))
                .then((payload) => {
                    user.userData = payload
                    context.commit('UPDATE_USER', user)
                    if (user.userData.userStatus.value == STATUS_INACTIF) {
                        context.commit('UPDATE_USER', blankUser())
                        context.dispatch(
                            'notifications/showWarning',
                            "Profil inactif, veuillez contacter l'exploitant Suez de votre secteur",
                            { root: true },
                        )
                    }
                    context.dispatch('dashboard/updateDashboardData', null, { root: true })
                    window.scroll(0, 0)
                })
                .catch(() => {})
                .finally(() => {
                    context.state.loginIsInProgress = false
                })
        },
        loginAzure(context) {
            context.state.loginIsInProgress = true
            let user = Object.assign({}, blankUser())
            Vue.prototype.$azureHandler
                .loginRedirect()
                .then((payload) => {
                    if (payload) {
                        user.token = payload.idToken
                        user.account = payload.account
                        return {
                            token: payload.idToken,
                        }
                    }
                })
                .then((payload) => {
                    if (payload && payload.token) return backendApi.getUserInfo(payload.token)
                })
                .then((payload) => {
                    if (payload) {
                        user.userData = payload
                        context.commit('UPDATE_USER', user)
                        if (user.userData.userStatus.value == STATUS_INACTIF) {
                            context.commit('UPDATE_USER', blankUser())
                            context.dispatch(
                                'notifications/showWarning',
                                "Profil inactif, veuillez contacter l'exploitant Suez de votre secteur",
                                { root: true },
                            )
                        }
                        context.dispatch('dashboard/updateDashboardData', null, { root: true })
                        window.scroll(0, 0)
                    }
                })
                .catch(backendApi.notificationOnError)
                .finally(() => {
                    context.state.loginIsInProgress = false
                })
        },
        loginUpdate(context) {
            backendApi
                .loginUpdate(context.state.user.token)
                .then((payload) => {
                    let user = context.state.user
                    user.token = payload.token

                    context.commit('UPDATE_USER', user)
                    context.dispatch('dashboard/updateDashboardData', null, { root: true })
                })
                .catch((error) => {
                    if (error?.response?.status !== undefined) {
                        if (error?.response?.status === 401) {
                            context.dispatch('logout')
                        }
                    }
                })
        },
        loginAzureUpdate(context, payload) {
            return Vue.prototype.$azureHandler
                .acquireTokenSilent()
                .then((res) => {
                    let user = context.state.user
                    user.token = res.idToken

                    context.commit('UPDATE_USER', user)
                    if (!payload?.init) context.dispatch('dashboard/updateDashboardData', null, { root: true })
                })
                .catch(() => {
                    if (!payload?.init) context.dispatch('logout', { fromCheck: true })
                })
        },
        updateUserInfos(context) {
            backendApi.getUserInfo(context.state.user.token).then((payload) => {
                context.commit('UPDATE_USER_DATA', payload)
            })
        },
        logout(context, payload) {
            backendApi.deleteSupportAccess()
            if (process.env.VUE_APP_AUTH_AZURE === 'true' && !payload?.fromCheck) {
                Vue.prototype.$azureHandler
                    .logoutRedirect()
                    .then(() => {
                        logoutHandler(context)
                    })
                    .catch(backendApi.notificationOnError)
                    .finally(() => {
                        context.state.displayFormSupport = false
                    })
            } else {
                removeAzureData()
                logoutHandler(context)
            }
        },
        // handleResponse redirect auth azure
        handleResponse(context, payload) {
            let user = Object.assign({}, blankUser())
            user.token = payload.idToken
            user.account = payload.account

            backendApi
                .getUserInfo(payload.idToken)
                .then((payload) => {
                    if (payload) {
                        user.userData = payload
                        context.commit('UPDATE_USER', user)
                        if (user.userData.userStatus.value == STATUS_INACTIF) {
                            context.commit('UPDATE_USER', blankUser())
                            context.dispatch(
                                'notifications/showWarning',
                                "Profil inactif, veuillez contacter l'exploitant Suez de votre secteur",
                                { root: true },
                            )
                        }
                        context.dispatch('dashboard/updateDashboardData', null, { root: true })
                        window.scroll(0, 0)
                    }
                })
                .catch(backendApi.notificationOnError)
                .catch(() => {
                    Vue.prototype.$azureHandler.logoutRedirect()
                })
        },
    },
    getters: {
        isWorker(state) {
            if (!state.user.userData.accountType) return false
            return state.user.userData.accountType === 'workerAccount'
        },
        isSuez(state) {
            if (!state.user.userData.accountType) return false
            return state.user.userData.accountType === 'agencyAccount'
        },
        isAdminFunc(state) {
            if (!state.user.userData.accountType) return false
            return state.user.userData.accountType === 'superManagerAccount'
        },
        isWorkerAdmin(state, getters) {
            if (!getters.isWorker) return false
            return !!state.user.userData.roles.find((item) => item === 'admin')
        },
        isSuezAdmin(state, getters) {
            if (!getters.isSuez) return false
            return !!state.user.userData.roles.find((item) => item === 'admin')
        },
        displayFormSupport(state) {
            return state.displayFormSupport
        },
    },
}
