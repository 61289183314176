<template>
    <div>
        <fieldset class="uk-fieldset">
            <div class="uk-margin">
                <label :for="id">{{ data.field_definition.wording }}</label>
                <input
                    v-model="def"
                    :id="id"
                    class="uk-input"
                    type="text"
                    v-if="data.field_definition.field_type.type === 'text_250'"
                />
                <input
                    v-model="def"
                    :id="id"
                    class="uk-input"
                    type="number"
                    v-else-if="data.field_definition.field_type.type === 'integer'"
                />
                <input
                    v-model="def"
                    :id="id"
                    class="uk-input"
                    type="number"
                    v-else-if="data.field_definition.field_type.type === 'year'"
                />
                <input
                    v-model="def"
                    :id="id"
                    class="uk-input"
                    type="number"
                    v-else-if="data.field_definition.field_type.type === 'decimal_2'"
                />
                <input
                    v-model="def"
                    :id="id"
                    class="uk-input"
                    type="number"
                    v-else-if="data.field_definition.field_type.type === 'decimal_3'"
                />
                <input
                    v-model="def"
                    :id="id"
                    class="uk-input"
                    type="text"
                    v-else-if="data.field_definition.field_type.type === 'matricule_compteur'"
                />
                <input
                    v-model="def"
                    :id="id"
                    class="uk-input"
                    type="text"
                    v-else-if="data.field_definition.field_type.type === 'matricule_emetteur'"
                />
                <input
                    v-model="def"
                    :id="id"
                    class="uk-input"
                    type="text"
                    v-else-if="data.field_definition.field_type.type === 'matricule_telereleve'"
                />
                <date-picker
                    v-model="def"
                    :id="id"
                    v-else-if="data.field_definition.field_type.type === 'datetime_iso'"
                    :disableFuture="isKeyNotFutured"
                ></date-picker>
                <select
                    v-model="def"
                    :id="id"
                    class="uk-select"
                    v-else-if="data.field_definition.field_type.type === 'dropdown'"
                >
                    <option
                        v-for="(it, idx) in data.field_definition.field_type.list_of_value.listValues"
                        :key="idx"
                        :value="it"
                    >
                        {{ it.wording }}
                    </option>
                </select>
                <input v-model="def" :id="id" class="uk-input" type="text" v-else />
            </div>
            <div class="input-error" v-if="error"></div>
        </fieldset>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: '',
            def: undefined,
            error: false,
        }
    },
    props: {
        value: {
            required: true,
        },
        data: {
            required: false,
            default: '',
        },
    },
    methods: {
        initData() {
            this.id = this.$util.htmlId()
            if (this.data.field_definition.field_type.type !== 'dropdown') {
                this.def = this.data.data
            } else {
                this.def = this.data.field_definition.field_type.list_of_value.listValues.find((d) => {
                    return d.id === this.data.data.id
                })
            }
        },
    },
    mounted() {
        this.initData()
    },
    computed: {
        isDateTimeIso() {
            return this.data && this.data.field_definition.field_type.type === 'datetime_iso'
        },
        isDropdown() {
            return this.data && this.data.field_definition.field_type.type === 'dropdown'
        },
        isKeyNotFutured() {
            return ['DatePoseEmetteur', 'DateIndexCpt'].includes(this.data.field_definition.g2_code)
        },
    },
    watch: {
        def(val) {
            let oldVal
            if (this.isDropdown) {
                oldVal = { ...val }
            } else {
                oldVal = val + ''
            }
            if (this.isDateTimeIso) {
                val = new Date(val).toISOString()
            }
            let hasError = false
            if (!val && this.data.required) {
                hasError = true
            }
            if (val && this.data.field_definition.field_type.regex_validate_field) {
                const regex = new RegExp(this.data.field_definition.field_type.regex_validate_field)
                hasError = !regex.test(val)
            }
            this.$emit('input', oldVal)
            this.error = hasError
            this.$emit('error', this.error)
        },
        data() {
            this.initData()
        },
    },
}
</script>

<style>
.input-error {
    color: #f00;
    margin-top: -20px;
    font-size: 14px;
}
</style>
