const msalToken = 'msal.token.keys'
const msalAccount = 'msal.account.keys'
const msalKey = `${msalToken}.${process.env.VUE_APP_B2C_CLIENT_ID}`
export const removeAzureData = () => {
    // get token keys
    const session = localStorage.getItem(msalKey)
    // get account keys
    const account = localStorage.getItem(msalAccount)
    const data = session ? JSON.parse(session) : null
    const dataAccount = session ? JSON.parse(account) : null

    // clear token data if exist
    if (data) {
        if (data.idToken.length > 0) data.idToken.forEach((key) => localStorage.removeItem(key))
        if (data.refreshToken.length > 0) data.refreshToken.forEach((key) => localStorage.removeItem(key))
    }

    // clear account data if exist
    if (dataAccount && dataAccount.length > 0) dataAccount.forEach((key) => localStorage.removeItem(key))

    // clear keys
    localStorage.removeItem(msalKey)
    localStorage.removeItem(msalAccount)
}
