<template>
    <div>
        <div class="file-drop-area mb-3">
            <span class="choose-file-button">Choisissez des fichiers</span>
            <span class="file-message">ou faites un glissé-déposé ici</span>
            <input class="file-input" type="file" @change="addFilesInProgress" multiple />
        </div>
        <div v-if="Object.keys(filesInProgress).length > 0" class="file-upload-in-progress">
            <h5>Fichiers en cours de transfert</h5>
            <ul class="list-group">
                <li v-for="(fileInfo, key) in filesInProgress" :key="'filesInProgress-' + key" class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center text-break flex-wrap gap-3">
                        <span>{{ fileInfo.file.name }}</span>
                        <button type="button" class="btn btn-danger" @click="() => abort(fileInfo, key)">
                            Supprimer
                        </button>
                    </div>
                    <div>
                        <ProgressBar :progress="fileInfo.progress" class="mt-2 mb-2" style="width: 100%" />
                    </div>
                    <div v-if="fileInfo.progress === 100">
                        <div class="btn btn-warning">
                            <div class="uk-margin-left uk-inline" uk-spinner="ratio:0.5">
                                Fichier en cours de traitement...
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div v-if="Object.keys(filesUploaded).length > 0 && displayUploadedFiles" class="file-uploaded mb-3">
            <h5>Fichiers transférés</h5>
            <ul class="list-group">
                <li
                    v-for="(file, key) in filesUploaded"
                    :key="'filesUploaded-' + file.id"
                    class="list-group-item d-flex justify-content-between align-items-center text-break flex-wrap gap-3"
                >
                    {{ file.originalName }}
                    <button type="button" class="btn btn-danger" @click="removeUploadedFile(key)">Supprimer</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
const crypto = require('crypto')
import backendApi from '@/backend/api'
import store from '@/store'
import ProgressBar from '@/components/ProgressBar'
export default {
    name: 'FileUpload',
    components: { ProgressBar },
    props: {
        displayUploadedFiles: {
            required: false,
            default: () => true,
        },
        filesUploaded: {
            required: false,
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        filesInProgress: {},
    }),
    methods: {
        abort(fileInfo, key) {
            fileInfo.controller.abort('canceled by user')
            this.$delete(this.filesInProgress, key)
        },
        addFilesInProgress({ target }) {
            for (let i = 0; i < target.files.length; i++) {
                let randomId = crypto.randomBytes(32).toString('hex').replace('//')
                const controller = new AbortController()
                this.$set(this.filesInProgress, randomId, { file: target.files[i], progress: 0, controller })
                backendApi
                    .uploadFile(
                        store.state.login.user.token,
                        { file: target.files[i] },
                        null,
                        this.updateProgressCallback(randomId),
                        controller,
                    )
                    .then((data) => {
                        setTimeout(() => {
                            this.$delete(this.filesInProgress, randomId)
                            this.filesUploaded.push(data)
                        }, 150 /* more user-friendly display */)
                    })
                    .catch(backendApi.notificationOnError)
                    .catch(() => {
                        this.$delete(this.filesInProgress, randomId)
                    })
            }
            target.value = null
        },
        updateProgressCallback(fileInProgressId) {
            return (progressEvent) => {
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                this.filesInProgress[fileInProgressId].progress = percentCompleted
            }
        },
        removeUploadedFile(key) {
            this.$delete(this.filesUploaded, key)
        },
    },
}
</script>

<style scoped>
.file-uploaded,
.file-upload-in-progress {
    padding: 25px;
    border: 1px dashed rgba(0, 0, 0, 0.4);
}

.file-drop-area {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 100%;
    padding: 25px;
    border: 1px dashed rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    transition: 0.2s;
}

.choose-file-button {
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 8px 15px;
    margin-right: 10px;
    font-size: 12px;
    text-transform: uppercase;
}

.file-message {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
}
</style>
