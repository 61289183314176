const defaultState = () => ({
    osRefreshTime: localStorage.getItem('osRefreshTime') ? parseInt(localStorage.getItem('osRefreshTime')) : 5,
})

export default {
    namespaced: true,
    state: () => defaultState(),
    mutations: {
        UPDATE_OS_REFRESH_TIME_DATA(store, payload) {
            store.osRefreshTime = parseInt(payload)
            localStorage.setItem('osRefreshTime', payload.toString())
        },
    },
    actions: {
        async setOsRefreshTimeData(context, data) {
            let osRefreshTime = parseInt(data)
            if (osRefreshTime < 5) {
                await context.dispatch(
                    'notifications/addNotification',
                    {
                        status: 'warning',
                        message: 'Le temps de rafraîchissement ne peut pas être inférieur à 5 minutes',
                    },
                    { root: true },
                )
                return
            }
            context.commit('UPDATE_OS_REFRESH_TIME_DATA', osRefreshTime)
        },
    },
    getters: {
        getOsRefreshTimeData: (state) => state.osRefreshTime,
    },
}
