const crypto = require('crypto')
import download from '@/helpers/download'
import { Capacitor } from '@capacitor/core'
import { ANDROID_WEB, IOS_WEB, WEB } from '../constantes/platform'

let store = () => import('@/store')
store().then((module) => {
    store = module.default
})
const DATE_VAL = {
    D: 'day',
    M: 'month',
    Y: 'year',
}
const COMMON_FORMAT = {
    'YYYY-MM-DD': { sep: '-', val: [{ year: 'numeric' }, { month: '2-digit' }, { day: '2-digit' }] },
    'DD/MM/YYYY': { sep: '/', val: [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }] },
    'DD-MM-YYYY': { sep: '-', val: [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }] },
}

const util = {
    htmlId(length = 32) {
        let id = ''

        while (id.length < length) {
            id += crypto
                .randomBytes(Math.ceil(length * 1.25))
                .toString('hex')
                .replace(/^[^a-z]+/g, '')
        }

        return id.substring(0, length)
    },
    splitFormat(format) {
        const ret = []
        let form = ''
        let isSeparator = false
        for (const c of format) {
            // the char is formatted
            if (DATE_VAL[c]) {
                // the last is separator
                if (isSeparator && form !== '') {
                    // push cause the last is separator
                    ret.push({
                        val: form,
                        sep: true,
                    })
                    // init the form
                    form = ''
                }
                isSeparator = false
                form += c
            }
            // the char is separator
            else {
                // the last is formatted
                if (!isSeparator && form !== '') {
                    // push cause the last is formatted
                    ret.push({
                        val: form,
                        sep: false,
                    })
                    // empty the form
                    form = ''
                }
                isSeparator = true
                form += c
            }
        }
        if (form !== '') {
            ret.push({
                val: form,
                sep: isSeparator,
            })
        }
        return ret
    },
    formatDateWithSeparator(dt, regex, separator = '/') {
        if (typeof dt === 'string') dt = new Date(dt)

        function format(m) {
            let f = new Intl.DateTimeFormat('fr', m)
            return f.format(dt)
        }

        return regex.map(format).join(separator)
    },
    formatTime(dt) {
        if (typeof dt === 'string') dt = new Date(dt)
        const shortTime = new Intl.DateTimeFormat('fr', {
            timeStyle: 'short',
        })
        return shortTime.format(dt)
    },
    formatDate(dt, format = 'DD/MM/YYYY', withHour = false) {
        const isCommon = COMMON_FORMAT[format]
        let ret = undefined
        if (isCommon) ret = this.formatDateWithSeparator(dt, isCommon.val, isCommon.sep)
        else {
            const sFormat = this.splitFormat(format)
            ret = ''
            for (const f of sFormat) {
                if (f.sep) ret += f.val
                else {
                    const key = {}
                    key[DATE_VAL[f.val[0]]] = f.val.length > 2 ? 'numeric' : `${f.val.length}-digit`
                    const fm = new Intl.DateTimeFormat('fr', key)
                    ret += fm.format(dt)
                }
            }
        }
        if (withHour) {
            const hour = this.formatTime(dt)
            ret += ` ${hour}`
        }
        return ret
    },
    fileTob64(file) {
        return new Promise((resolve, reject) => {
            const r = new FileReader()
            try {
                r.onload = () => {
                    resolve(r.result)
                }
                r.readAsDataURL(file)
            } catch (error) {
                reject(error)
            }
        })
    },
    async downloadBase64(b64, name = `${Date.now()}.png`) {
        fetch(b64)
            .then((res) => res.blob())
            .then(async (blob) => {
                await download.downloadBlob(blob, name)
                if (store) {
                    store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Fichier téléchargé dans votre dossier de téléchargement',
                    })
                }
            })
    },
    base64ToFile(b64, filename) {
        let arr = b64.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, { type: mime })
    },
    showMap(lat, lng) {
        window.open(`https://maps.google.com/?q=${lat},${lng}`, '_blank').focus()
    },
    itinerary(latLngInit, latLngDest) {
        window.open(`https://www.google.com/maps/dir/${latLngInit}/${latLngDest}`, '_blank').focus()
    },
    millisecondsToHMS(milliseconds) {
        const seconds = Math.floor((milliseconds / 1000) % 60)
        const minutes = Math.floor((milliseconds / (1000 * 60)) % 60)
        const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24)

        const formattedHours = hours.toString().padStart(2, '0')
        const formattedMinutes = minutes.toString().padStart(2, '0')
        const formattedSeconds = seconds.toString().padStart(2, '0')

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    },
    getPlatform() {
        const platform = Capacitor.getPlatform()
        if (platform !== WEB) return platform
        else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) return IOS_WEB
        else if (/Android/.test(navigator.userAgent)) return ANDROID_WEB
        else return WEB
    },
}

export default util
