<template>
    <div class="accordion" id="documentAccordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="documentListTitle">
                <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#documentList"
                    aria-expanded="false"
                >
                    <span class="me-3">Pièces jointes</span>
                    <button class="btn btn-primary btn-sm" @click.prevent="$emit('document:add')" v-if="!isAdminFunc">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </button>
                    <button
                        v-if="$capacitor.isNative"
                        class="btn btn-primary btn-sm ms-3"
                        @click.prevent="$refs.modalMobileRecorderChooser.open()"
                        :disabled="pictureUploadInProgress"
                    >
                        <font-awesome-icon icon="fa-solid fa-camera" />
                        <div class="d-inline-block" v-if="pictureUploadInProgress">
                            <span class="fs-7 ms-2">Envoi en cours</span>
                            <div class="spinner-border text-white spinner-border-sm ms-2 me-2" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </button>

                    <MobileRecorderChooser
                        v-if="$capacitor.isNative"
                        ref="modalMobileRecorderChooser"
                        @choosed="choosed"
                    />
                    <MobileVideoRecorder
                        v-if="$capacitor.isNative"
                        ref="modalMobileVideoRecorder"
                        @saved="onVideoRecorded"
                    />
                </button>
                <div v-if="Object.keys(downloadsInProgress).length > 0">
                    <div v-for="(downloadInProgress, identifier) in downloadsInProgress" :key="identifier" class="p-3">
                        <h5>
                            Téléchargement de : {{ downloadInProgress.name }}
                            <button
                                type="button"
                                class="ms-3 btn btn-danger btn-sm"
                                @click="cancelDownload(identifier)"
                            >
                                Annuler
                            </button>
                        </h5>
                        <ProgressBar :progress="downloadInProgress.progress" />
                    </div>
                    <hr />
                </div>
            </h2>
            <div
                id="documentList"
                class="accordion-collapse collapse"
                aria-labelledby="documentListTitle"
                data-bs-parent="#documentAccordion"
                style="overflow-x: scroll"
            >
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" v-if="!columns.name.hidden">Nom</th>
                            <th scope="col" v-if="!columns.sendBy.hidden">Envoyé par</th>
                            <th scope="col" v-if="!columns.createdAt.hidden">le</th>
                            <th scope="col" v-if="!columns.preview.hidden">Prévisualisation</th>
                            <th scope="col" v-if="!columns.actions.hidden">Actions</th>
                            <th scope="col" v-if="!columns.geo.hidden">Géo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="document in documents" :key="`document-${document.id}`">
                            <td v-if="!columns.name.hidden" style="word-break: break-all">
                                <div v-if="shouldShowEditName(document)">
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="form-control btn-outline-warning text-black"
                                            v-model="toEditNewName"
                                        />
                                        <button class="btn btn-warning" type="button" @click="editNameAction(document)">
                                            <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                                        </button>
                                    </div>
                                </div>
                                <div class="m-2" v-else>
                                    {{ document.originalName }}
                                </div>
                                <SimpleAccordion v-if="hiddenFields.length > 1" class="mt-2">
                                    <template v-slot:accordionTitle>Plus d'infos</template>
                                    <template v-slot:accordionContent>
                                        <div class="p-2">
                                            <div v-if="hiddenFields.includes('sendBy')" class="mb-3">
                                                Envoyé par : {{ ownedByDisplay(document) }}
                                            </div>
                                            <div v-if="hiddenFields.includes('createdAt')" class="mb-3">
                                                Le : {{ document.createdAt | humanReadableDate }}
                                            </div>
                                            <div
                                                v-if="hiddenFields.includes('preview') && document.preview"
                                                class="mb-3"
                                            >
                                                <img v-if="document.preview" alt="preview" :src="document.preview" />
                                            </div>
                                        </div>
                                    </template>
                                </SimpleAccordion>
                            </td>
                            <td v-if="!columns.sendBy.hidden" style="word-break: break-all">
                                {{ ownedByDisplay(document) }}
                            </td>
                            <td v-if="!columns.createdAt.hidden" style="word-break: break-all">
                                {{ document.createdAt | humanReadableDate }}
                            </td>
                            <td v-if="!columns.preview.hidden">
                                <img v-if="document.preview" alt="preview" :src="document.preview" />
                            </td>
                            <td v-if="!columns.actions.hidden">
                                <div class="d-flex flex-column gap-3">
                                    <button
                                        class="btn btn-primary"
                                        @click="
                                            downloadDocument(
                                                document.randomIdentifier,
                                                document.originalName,
                                                document.size,
                                            )
                                        "
                                    >
                                        <font-awesome-icon icon="fa-solid fa-file-arrow-down" />
                                    </button>
                                    <button
                                        v-if="
                                            document.mimeType.includes('image/') &&
                                            $store.state.login.user.userData.id == document.ownedBy.id
                                        "
                                        class="btn btn-secondary d-flex flex-row gap-2"
                                        @click="editDocument(document.randomIdentifier, document.originalName)"
                                    >
                                        <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                                        <div
                                            uk-spinner="ratio:0.5"
                                            v-if="showInProgressEdit === document.randomIdentifier"
                                        ></div>
                                    </button>
                                    <button
                                        v-if="$store.state.login.user.userData.id == document.ownedBy.id"
                                        class="btn btn-warning"
                                        @click="editName(document)"
                                    >
                                        <font-awesome-icon icon="fa-solid fa-pen" />
                                    </button>
                                    <div
                                        class="btn-group"
                                        v-if="$store.state.login.user.userData.id == document.ownedBy.id"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-warning dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <font-awesome-icon icon="fa-regular fa-trash-can" />
                                        </button>
                                        <ul class="dropdown-menu bg-transparent border-0">
                                            <li>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger"
                                                    @click="deleteDocument(document)"
                                                >
                                                    Confirmer
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td v-if="!columns.geo.hidden">
                                <img
                                    v-if="document.lat && document.lng"
                                    style="cursor: pointer"
                                    src="@/assets/aim.png"
                                    alt=""
                                    width="100"
                                    @click="$util.showMap(document.lat, document.lng)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'
import ProgressBar from '@/components/ProgressBar'
import SimpleAccordion from '@/components/SimpleAccordion'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import MobileRecorderChooser from '@/components/modals/MobileRecorderChooser'
import MobileVideoRecorder from '@/components/modals/MobileVideoRecorder'
import { FileImage } from '@/Enum/FileType'
import { mapGetters } from 'vuex'

export default {
    name: 'Documents',
    components: { SimpleAccordion, ProgressBar, MobileRecorderChooser, MobileVideoRecorder },
    emits: ['document:add', 'image:edit'],
    data: () => ({
        showInProgressEdit: '',
        downloadsInProgress: {},
        toEditNameDocumentId: null,
        toEditNewName: '',
        pictureUploadInProgress: false,
        videoRecorder: null,
    }),
    props: {
        missionOrder: {
            type: Object,
            required: true,
        },
        documents: {
            required: false,
            default: () => [],
        },
        editDocumentNameFunc: {
            required: false,
            type: Function,
            default: () => Promise.resolve(),
        },
        deleteDocumentFunc: {
            required: false,
            type: Function,
            default: () => Promise.resolve(),
        },
        takePictureFunc: {
            required: false,
            type: Function,
            default: () => Promise.resolve(),
        },
        takeVideoFunc: {
            required: false,
            type: Function,
            default: () => Promise.resolve(),
        },
    },
    computed: {
        ...mapGetters('login', ['isAdminFunc', 'isSuez', 'isWorkerAdmin', 'isWorker']),
        columns: function () {
            return {
                name: {
                    hidden: this.shouldBeHidden('xs'),
                },
                sendBy: {
                    hidden: this.shouldBeHidden('xxl'),
                },
                createdAt: {
                    hidden: this.shouldBeHidden('md'),
                },
                preview: {
                    hidden: this.shouldBeHidden('sm'),
                },
                actions: {
                    hidden: this.shouldBeHidden('xs'),
                },
                geo: {
                    hidden: true,
                },
            }
        },
        hiddenFields: function () {
            let hiddenFields = []
            Object.keys(this.columns).forEach((key) => {
                if (this.columns[key].hidden) {
                    hiddenFields.push(key)
                }
            })

            return hiddenFields
        },
    },
    methods: {
        shouldBeHidden: function (at) {
            const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga']
            if (!bp.includes(at)) {
                return false
            }

            return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
        },
        ownedByDisplay(document) {
            if (!document.ownedBy) {
                return 'legacy - unknown'
            }

            if (this.isSuez && !document.ownedBy.roles.includes('admin') && document.ownedBy.workerAccount) {
                return `Agent - ${this.missionOrder.agency.name}`
            }

            return `${document.ownedBy.username} - ${document.ownedBy.userInfos.lastName} ${document.ownedBy.userInfos.firstName}`
        },
        downloadDocument(identifier, name, size) {
            if (this.downloadsInProgress[identifier] !== undefined) {
                return // prevent multi-time download
            }

            // record download start
            this.$set(this.downloadsInProgress, identifier, {
                name,
                progress: 0,
                abort: new AbortController(),
            })

            // start download
            backendApi.downloadDocument(
                store.state.login.user.token,
                identifier,
                name,
                true,
                (progressEvent) => {
                    if (this.downloadsInProgress[identifier] === undefined) {
                        return
                    }
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / size)
                    this.downloadsInProgress[identifier].progress = percentCompleted
                    if (percentCompleted >= 100) {
                        setTimeout(() => {
                            this.$delete(this.downloadsInProgress, identifier)
                        }, 150 /* more user-friendly display */)
                    }
                },
                this.downloadsInProgress[identifier].abort,
            )
        },
        cancelDownload(identifier) {
            if (this.downloadsInProgress[identifier] === undefined) {
                return // cancel not possible
            }
            this.downloadsInProgress[identifier].abort.abort()
            setTimeout(() => {
                this.$delete(this.downloadsInProgress, identifier)
            }, 150 /* more user-friendly display */)
        },
        editDocument(identifier, originalName) {
            //eto
            this.showInProgressEdit = identifier
            backendApi
                .downloadDocument(store.state.login.user.token, identifier, originalName, false)
                .then((file) => {
                    this.$emit('image:edit', file, identifier)
                })
                .finally(() => {
                    this.showInProgressEdit = ''
                })
        },
        editName(document) {
            if (this.toEditNameDocumentId === document.id) {
                this.toEditNameDocumentId = null
                return
            }
            this.toEditNameDocumentId = document.id
            this.toEditNewName = document.originalName
        },
        shouldShowEditName(document) {
            return this.toEditNameDocumentId === document.id
        },
        editNameAction(document) {
            this.editDocumentNameFunc(document, this.toEditNewName)
                .then(() => {
                    store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Nom du document modifié',
                    })
                    this.toEditNameDocumentId = null
                })
                .catch(backendApi.notificationOnError)
        },
        deleteDocument(document) {
            this.deleteDocumentFunc(document)
                .then(() => {
                    store.dispatch('notifications/addNotification', { status: 'success', message: 'Document supprimé' })
                })
                .catch(backendApi.notificationOnError)
        },
        takePicture: async function () {
            let retry, image
            do {
                retry = false
                image = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: store.state.parameters.device.camera.allowEditing,
                    resultType: CameraResultType.Uri,
                    webUseInput: true,
                    source: CameraSource.Camera,
                }).catch(async (error) => {
                    if (error.message === 'Unable to edit image') {
                        await store.dispatch('notifications/addNotification', {
                            status: 'warning',
                            message:
                                "Une erreur est survenue lors de la prise de photo. L'erreur détectée a été fixée et ne devrait plus survenir. L'application photo va être relancée dans quelques instants. Veuillez reprendre votre photo.",
                        })
                        store.commit('parameters/CAMERA_ALLOW_EDITING', false)
                        retry = true
                        await new Promise((resolve) => setTimeout(resolve, 7000))
                    } else if (error.message === 'User cancelled photos app') {
                        // do nothing
                    } else {
                        backendApi.notificationOnError(error)
                    }
                })
            } while (retry)

            if (!image || !image.webPath) {
                return
            }

            this.pictureUploadInProgress = true
            this.takePictureFunc(image)
                .then(() => {
                    store.dispatch('notifications/addNotification', { status: 'success', message: 'Photo ajoutée' })
                })
                .catch(backendApi.notificationOnError)
                .finally(() => {
                    this.pictureUploadInProgress = false
                })
        },
        onVideoRecorded(videoFile) {
            this.pictureUploadInProgress = true
            store.dispatch('notifications/addNotification', { status: 'primary', message: "Vidéo en cours d'upload" })
            this.takeVideoFunc(videoFile)
                .then(() => {
                    store.dispatch('notifications/addNotification', { status: 'success', message: 'Vidéo ajoutée' })
                })
                .catch(backendApi.notificationOnError)
                .finally(() => {
                    this.pictureUploadInProgress = false
                })
        },
        choosed(type) {
            if (type == FileImage) this.takePicture()
            else this.$refs.modalMobileVideoRecorder.open()
        },
    },
}
</script>
