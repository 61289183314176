<template>
    <vk-modal-full :show.sync="user.token === null">
        <vk-grid collapse class="uk-child-width-1-2@s uk-flex-middle">
            <div
                class="uk-background-cover uk-background-blend-darken uk-flex uk-flex-center uk-flex-middle"
                :style="{ backgroundImage: 'url(' + background + ')' }"
                v-vk-height-viewport
            >
                <vk-navbar-logo
                    slot="center"
                    class="uk-padding-large"
                    style="color: white; background: radial-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0) 50%)"
                >
                    <div class="uk-text-center">
                        <img
                            alt="S"
                            src="../assets/sinistros-logo-minimal.png"
                            style="width: 1.3rem; margin-bottom: 0.9rem; margin-right: 0.07rem"
                        /><span>INISTROS</span>
                    </div>
                </vk-navbar-logo>
            </div>
            <div>
                <div class="uk-margin-large-left uk-margin-large-right uk-margin-large-top uk-margin-large-bottom">
                    <h1>Connexion</h1>
                    <form v-if="!$azureAuth">
                        <div class="uk-margin">
                            <input
                                id="username"
                                name="username"
                                class="uk-input uk-form-width-large uk-width uk-border-rounded"
                                type="text"
                                v-model="username"
                                placeholder="Nom d'utilisateur"
                            />
                        </div>
                        <div class="uk-margin">
                            <input
                                id="password"
                                name="password"
                                class="uk-input uk-form-width-large uk-width uk-border-rounded"
                                type="password"
                                v-model="password"
                                placeholder="Mot de passe"
                            />
                        </div>
                        <div class="uk-align-right">
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="login({ username, password })"
                                :disabled="$store.state.login.loginIsInProgress"
                            >
                                Se connecter
                                <div
                                    class="ms-2"
                                    uk-spinner="ratio:0.5"
                                    v-if="$store.state.login.loginIsInProgress"
                                ></div>
                            </button>
                        </div>
                        <div class="uk-align-right">Je n'ai pas encore de compte. <a href="#">Ouvrir</a></div>
                    </form>
                    <div v-else>
                        <form v-show="$store.state.login.displayFormSupport">
                            <div class="uk-margin">
                                <input
                                    v-model="byPassUsername"
                                    id="byPassUsername"
                                    name="byPassUsername"
                                    class="uk-input uk-form-width-large uk-width uk-border-rounded"
                                    type="text"
                                />
                            </div>
                            <div class="uk-margin">
                                <textarea
                                    v-model="byPassToken"
                                    id="byPassToken"
                                    name="byPassToken"
                                    class="uk-input uk-form-width-large uk-width uk-border-rounded"
                                    type="text"
                                >
                                </textarea>
                            </div>
                            <div class="uk-align-right">
                                <button
                                    type="button"
                                    class="btn btn-info"
                                    @click="supportLogin({ username: byPassUsername, token: byPassToken })"
                                    :disabled="$store.state.login.loginSupportIsInProgress"
                                >
                                    Action Support
                                    <div
                                        class="ms-2"
                                        uk-spinner="ratio:0.5"
                                        v-if="$store.state.login.loginSupportIsInProgress"
                                    ></div>
                                </button>
                            </div>
                        </form>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="loginAzure"
                            :disabled="$store.state.login.loginIsInProgress"
                        >
                            Se connecter
                            <div class="ms-2" uk-spinner="ratio:0.5" v-if="$store.state.login.loginIsInProgress"></div>
                        </button>
                        <button
                            type="button"
                            class="btn btn-light btn-login-support"
                            @click="setDisplayFormSupport(!displayFormSupport)"
                        >
                            {{ displayFormSupportButtonAction }}
                        </button>
                    </div>
                </div>
            </div>
        </vk-grid>
    </vk-modal-full>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import background from '../assets/networked.jpg'

export default {
    name: 'LoginModal',
    data: () => {
        return {
            background,
            username: '',
            password: '',
            byPassUsername: '',
            byPassToken: '',
        }
    },
    computed: {
        ...mapState('login', ['user']),
        ...mapGetters('login', ['displayFormSupport']),
        displayFormSupportButtonAction() {
            return this.displayFormSupport ? 'Retour' : 'Equipe Support'
        },
    },
    methods: {
        ...mapActions('login', ['login', 'loginAzure', 'supportLogin', 'setDisplayFormSupport']),
        resetLoginSupportForm() {
            this.byPassUsername = ''
            this.byPassToken = ''
        },
    },
}
</script>

<style scoped>
.btn-login-support {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 10;
}
</style>
