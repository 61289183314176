<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Historique des évènements déjà saisis</span>
            </template>
            <template #accordionContent>
                <event-content class="m-3" :events="datas"></event-content>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewEvent" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">{{ titleDlg }}</legend>
            </template>
            <template #modalBody>
                <new-event-modal
                    :terminerChantier="isTerminateChantier"
                    :declareMyArrival="isDeclareMyArrival"
                    :createInventory="isCreateInventory"
                    :makeMarkup="isMakeMarkup"
                    :makeMarking="isMakeMarking"
                    :makeMeasurement="isMakeMeasurement"
                    :resumeIntervention="isResumeIntervention"
                    :definitelyEnd="isDefinitelyEnd"
                    :isDeclareEvent="isDeclareEvent"
                    ref="newEvent"
                    @add-event="onSaveEvent"
                    :order-mission="orderMission"
                    @no-terminer-chantier="onNoTerminateChantier"
                    @mounted="() => (eventMounted = true)"
                >
                </new-event-modal>
            </template>
            <template #modalFooterExtra v-if="eventMounted">
                <button
                    v-if="isEventSubmitable && !isTerminateChantier"
                    type="button"
                    class="btn btn-primary"
                    @click="$refs.newEvent.submit()"
                >
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
                <button
                    v-if="isEventSubmitable && isTerminateChantier"
                    type="button"
                    :disabled="loading"
                    class="btn btn-primary"
                    @click="$refs.newEvent.submit('comment')"
                >
                    Enregistrer et ajouter un nouveau commentaire
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
                <button
                    v-if="isEventSubmitable && isTerminateChantier"
                    type="button"
                    :disabled="loading"
                    class="btn btn-primary"
                    @click="$refs.newEvent.submit('terminated')"
                >
                    Enregistrer et terminer le chantier
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import NewEventModal from '../modals/NewEventModal.vue'
import EventContent from './EventContent.vue'
export default {
    components: {
        NewEventModal,
        EventContent,
    },
    props: {
        orderMission: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            eventMounted: false, // to check that newEvent component is mounted
            loading: false,
            datas: [],
            isTerminateChantier: false,
            isDeclareMyArrival: false,
            isCreateInventory: false,
            isMakeMarkup: false,
            isMakeMarking: false,
            isMakeMeasurement: false,
            isResumeIntervention: false,
            isDefinitelyEnd: false,
            isDeclareEvent: false,
        }
    },
    methods: {
        async openAddEventModal() {
            this.isTerminateChantier = false
            this.isDeclareMyArrival = false
            this.isCreateInventory = false
            this.isMakeMarkup = false
            this.isMakeMarking = false
            this.isMakeMeasurement = false
            this.isResumeIntervention = false
            this.isDefinitelyEnd = false
            this.isDeclareEvent = true
            this.$refs.newEvent.clearInput()

            await this.$nextTick()
            this.$refs.newEvent.makeEventToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        /**
         * @param afterSubmit {("comment" | "terminated") | undefined}
         */
        onSaveEvent(data, afterSubmit) {
            // teminer chantier
            if (this.isTerminateChantier) {
                this.loading = true
                this.$http
                    .terminerChantier(this.orderMission.id, data)
                    .then((res) => {
                        this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).hide()
                        this.formatData(res)
                        this.$emit('event-added', { afterSubmit })
                    })
                    .catch((e) => {
                        this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                    })
                    .then(() => {
                        this.loading = false
                    })
            } else {
                // creer event
                this.loading = true
                this.$http
                    .declareEvent(this.orderMission.id, data)
                    .then((res) => {
                        this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).hide()
                        this.formatData(res)
                        this.$emit('event-added', { afterSubmit })
                        if (this.isResumeIntervention) {
                            this.$store.dispatch('notifications/addNotification', {
                                status: 'success',
                                message:
                                    'Votre modification a bien été prise en compte : Le statut a changé, il est passé de "suspendu" à "en cours"',
                            })
                        }
                        if (this.isDefinitelyEnd) {
                            this.$store.dispatch('notifications/addNotification', {
                                status: 'success',
                                message:
                                    'Votre modification a bien été prise en compte : Le statut a changé, il est passé de "en cours" à "terminé"',
                            })
                        }
                        if (this.isDeclareMyArrival) {
                            this.$store.dispatch('notifications/addNotification', {
                                status: 'success',
                                message:
                                    'Votre modification a bien été prise en compte : Le statut a changé, il est passé de "À faire" à "en cours"',
                            })
                        }
                    })
                    .catch((e) => {
                        this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                        // refresh on defintely end error
                        if (this.isDefinitelyEnd) {
                            this.$store.dispatch('notifications/addNotification', {
                                status: 'success',
                                message:
                                    'Votre modification a bien été prise en compte : Le statut a changé, il est passé de "en cours" à "terminé"',
                            })
                            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).hide()
                            this.$emit('event-added', { afterSubmit })
                        }
                    })
                    .then(() => {
                        this.loading = false
                    })
            }
        },
        definitelyEnd() {
            this.isTerminateChantier = false
            this.isDeclareMyArrival = false
            this.isCreateInventory = false
            this.isMakeMarkup = false
            this.isMakeMarking = false
            this.isMakeMeasurement = false
            this.isResumeIntervention = false
            this.isDefinitelyEnd = true
            this.isDeclareEvent = false
            this.$refs.newEvent.makeDefinitelyEndToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        terminerChantier() {
            this.isTerminateChantier = true
            this.isDeclareMyArrival = false
            this.isCreateInventory = false
            this.isMakeMarkup = false
            this.isMakeMarking = false
            this.isMakeMeasurement = false
            this.isResumeIntervention = false
            this.isDefinitelyEnd = false
            this.isDeclareEvent = false
            this.$refs.newEvent.makeTerminateToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        declareMyArrival() {
            this.isDeclareMyArrival = true
            this.isCreateInventory = false
            this.isMakeMarkup = false
            this.isMakeMarking = false
            this.isMakeMeasurement = false
            this.isTerminateChantier = false
            this.isResumeIntervention = false
            this.isDefinitelyEnd = false
            this.isDeclareEvent = false
            this.$refs.newEvent.declareMyArrivalToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        createInventory() {
            this.isTerminateChantier = false
            this.isDeclareMyArrival = false
            this.isCreateInventory = true
            this.isMakeMarkup = false
            this.isMakeMarking = false
            this.isMakeMeasurement = false
            this.isResumeIntervention = false
            this.isDeclareEvent = false
            this.$refs.newEvent.createInventoryToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        makeMarkup() {
            this.isTerminateChantier = false
            this.isCreateInventory = false
            this.isMakeMarkup = true
            this.isMakeMarking = false
            this.isDeclareMyArrival = false
            this.isMakeMeasurement = false
            this.isResumeIntervention = false
            this.isDefinitelyEnd = false
            this.isDeclareEvent = false
            this.$refs.newEvent.makeMarkupToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        makeMarkingAndPique() {
            this.isTerminateChantier = false
            this.isCreateInventory = false
            this.isMakeMarkup = false
            this.isMakeMarking = true
            this.isMakeMeasurement = false
            this.isDeclareMyArrival = false
            this.isResumeIntervention = false
            this.isDefinitelyEnd = false
            this.isDeclareEvent = false
            this.$refs.newEvent.makeMarkingAndPiqueToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        makeMeasurement() {
            this.isTerminateChantier = false
            this.isCreateInventory = false
            this.isMakeMarkup = false
            this.isMakeMarking = false
            this.isMakeMeasurement = true
            this.isDeclareMyArrival = false
            this.isResumeIntervention = false
            this.isDefinitelyEnd = false
            this.isDeclareEvent = false
            this.$refs.newEvent.makeMeasurementToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        resumeIntervention() {
            this.isTerminateChantier = false
            this.isCreateInventory = false
            this.isMakeMarkup = false
            this.isMakeMarking = false
            this.isMakeMeasurement = false
            this.isDeclareMyArrival = false
            this.isResumeIntervention = true
            this.isDefinitelyEnd = false
            this.isDeclareEvent = false
            this.$refs.newEvent.resumeInterventionToDefault()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
        getAllEventsByOsId() {
            this.$http.getAllEventsByOsId(this.orderMission.id).then((res) => {
                this.formatData(res)
            })
        },
        formatData(res) {
            this.datas = res.map((r) => {
                if (r.photo_1) r.photo_1 = `${process.env.VUE_APP_MODEL_API_ROOT}/uploads/${r.photo_1}`
                if (r.photo_2) r.photo_2 = `${process.env.VUE_APP_MODEL_API_ROOT}/uploads/${r.photo_2}`
                return r
            })
        },
        onNoTerminateChantier() {
            this.$store.dispatch('notifications/showWarning', 'Pas d`évenement fin de chantier disponible')
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).show()
        },
    },
    computed: {
        titleDlg() {
            if (this.isUpdate) return 'Modification évènement'
            if (this.isTerminateChantier) return 'Info de fin de chantier'
            if (this.isCreateInventory) return 'Effectuer un état des lieux'
            if (this.isMakeMarkup) return 'Réaliser le balisage'
            if (this.isDeclareMyArrival) return 'Déclarer mon arrivée'
            if (this.isMakeMarking) return 'Réaliser le marquage/piquetage'
            if (this.isMakeMeasurement) return 'Réaliser un métré'
            if (this.isResumeIntervention) return "Reprendre l'intervention"
            if (this.isDefinitelyEnd) return 'Terminer un chantier'
            return 'Nouvel évènement'
        },

        isEventSubmitable() {
            return this.$refs.newEvent.isSubmitable
        },
    },
    mounted() {
        this.getAllEventsByOsId()
    },
}
</script>

<style></style>
