<template>
    <div ref="accordion" class="accordion" :id="htmlRandomId + 'Accordion'">
        <div class="accordion-item">
            <h2 class="accordion-header" :id="htmlRandomId + 'Title'">
                <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#' + htmlRandomId"
                    aria-expanded="false"
                >
                    <slot name="accordionTitle">{{ accordionTitle }}</slot>
                </button>
            </h2>
            <div
                class="accordion-collapse collapse"
                :id="htmlRandomId"
                :aria-labelledby="htmlRandomId + 'Title'"
                :data-bs-parent="'#' + htmlRandomId + 'Accordion'"
            >
                <Chat :chat="chat" :missionOrder="missionOrder" class="m-3" @message:new="messageNew" />
            </div>
        </div>
    </div>
</template>

<script>
import Chat from '@/components/Chat'
export default {
    name: 'ChatAccordion',
    components: { Chat },
    emits: ['chat:accordion:open', 'message:new'],
    data: () => ({
        htmlRandomId:
            Math.random()
                .toString(36)
                .replace(/^[^a-z]+/g, '') +
            Math.random()
                .toString(36)
                .replace(/^[^a-z]+/g, ''),
    }),
    props: {
        accordionTitle: {
            required: false,
            default: () => '',
        },
        chat: {
            required: false,
            default: () => Chat.props.chat.default(),
        },
        missionOrder: {
            required: false,
        },
    },
    methods: {
        messageNew(message, callback) {
            this.$emit('message:new', message, callback)
        },
    },
    mounted() {
        this.$refs.accordion.addEventListener('show.bs.collapse', () => {
            this.$emit('chat:accordion:open')
        })
    },
}
</script>

<style scoped></style>
