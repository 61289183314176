<template>
    <div ref="accordion" class="accordion" :id="htmlRandomId + 'Accordion'">
        <div class="accordion-item">
            <h2 class="accordion-header" :id="htmlRandomId + 'Title'">
                <button
                    class="accordion-button"
                    :class="{ collapsed: collapsed }"
                    type="button"
                    :data-bs-toggle="collapse"
                    :data-bs-target="'#' + htmlRandomId"
                    :aria-expanded="ariaExpanded"
                >
                    <slot name="accordionTitle"></slot>
                </button>
            </h2>
            <div
                class="accordion-collapse"
                :class="{ show: defaultOpened, collapse: collapse }"
                :id="htmlRandomId"
                :aria-labelledby="htmlRandomId + 'Title'"
                :data-bs-parent="'#' + htmlRandomId + 'Accordion'"
            >
                <slot name="accordionContent"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import random from '@/helpers/random'

export default {
    name: 'SimpleAccordion',
    props: {
        defaultOpened: {
            type: Boolean,
            default: false,
        },
        alwaysOpen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ariaExpanded() {
            return this.defaultOpened
        },
        collapsed() {
            return !this.defaultOpened
        },
        collapse() {
            return !this.alwaysOpen ? 'collapse' : ''
        },
    },
    data: () => ({
        htmlRandomId: random.htmlId(),
    }),
}
</script>

<style scoped></style>
