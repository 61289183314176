<template>
    <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
        <vk-nav-item-header title="Interventions" style="color: white" />
        <li>
            <a
                href="#"
                @click.prevent="
                    $router.push({ name: 'mission_orders' })
                    $emit('nav:close')
                "
                style="color: white"
                ><vk-icon icon="nut" class="uk-margin-small-right" />Liste des interventions</a
            >
        </li>
        <li v-if="$store.state.login.user.userData.roles.includes('admin')">
            <a
                href="#"
                @click.prevent="
                    $router.push({ name: 'assignment_list' })
                    $emit('nav:close')
                "
                style="color: white"
                ><vk-icon icon="nut" class="uk-margin-small-right" />Affectation des interventions</a
            >
        </li>
        <vk-nav-item-divider v-if="$store.getters['login/isWorkerAdmin']" />
        <ItemManageUsers v-on="$listeners" v-if="$store.getters['login/isWorkerAdmin']" />
    </ul>
</template>

<script>
import ItemManageUsers from '@/components/menuItems/ItemManageUsers'
export default {
    name: 'ItemsWorkerAccount',
    components: { ItemManageUsers },
}
</script>

<style scoped>
.uk-nav-default .uk-nav-sub a {
    color: #999 !important;
}
</style>
