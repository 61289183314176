import {ActionContext, Module, Store} from "vuex";
import store from "@/store";

interface List {
    domain: string
    fieldTypes: object[]
    listValues: ListValue[]
}

interface ListOfValue {
    domain: string
    id:number
}

interface ListValue {
    g2_value: number
    id:number
    list_of_value: ListOfValue
    wording: string
}

interface FieldType {
    id: number
    list_of_value: null | {
        domain: string
        listValues: Omit<ListValue, 'list_of_value'>[]
    }
    regex_validate_field: string | null
    type: string
}

interface FormDefinition {
    deletable:boolean
    g2_inter_code: string
    id: number
    wording: string
}

interface FieldDefinition {
    field_type: FieldType
    g2_code: string
    id: number
    scannable: boolean
    wording: string
}

interface FormField {
    editable:boolean
    field_definition: FieldDefinition
    form_definition: FormDefinition
    form_field_order: number | null
    id: number
    required: boolean
    visible: boolean
}

interface State {
    list: List[],
    listValues: ListValue[],
    fieldTypes: FieldType[],
    fieldDefinitions: FieldDefinition[],
    formDefinitions: FormDefinition[],
    formFields: FormField[],
}

const adminForm: Module<State, typeof Store> = {
    namespaced: true,
    state: (): State => ({
        list: [],
        listValues: [],
        fieldTypes: [],
        fieldDefinitions: [],
        formDefinitions: [],
        formFields: [],
    }),
    mutations: {
        SET_LIST(state: State, payload: List[]) {
            state.list = payload
        },
        SET_LIST_VALUES(state: State, payload: ListValue[]) {
            state.listValues = payload
        },
        SET_FIELD_TYPES(state: State, payload: FieldType[]) {
            state.fieldTypes = payload
        },
        SET_FIELD_DEFINITIONS(state: State, payload: FieldDefinition[]) {
            state.fieldDefinitions = payload
        },
        SET_FORM_DEFINITIONS(state: State, payload: FormDefinition[]) {
            state.formDefinitions = payload
        },
        SET_FORM_FIELDS(state: State, payload: FormField[]) {
            state.formFields = payload
        },
    },
    actions: {
        setList(context: ActionContext<State, typeof store>, payload: List[]) {
            context.commit('SET_LIST', payload)
        },
        setListValues(context: ActionContext<State, typeof store>, payload: ListValue[]) {
            context.commit('SET_LIST_VALUES', payload)
        },
        setFieldTypes(context: ActionContext<State, typeof store>, payload: FieldType[]) {
            context.commit('SET_FIELD_TYPES', payload)
        },
        setFieldDefinitions(context: ActionContext<State, typeof store>, payload: FieldDefinition[]) {
            context.commit('SET_FIELD_DEFINITIONS', payload)
        },
        setFormFields(context: ActionContext<State, typeof store>, payload: FormField[]) {
            context.commit('SET_FORM_FIELDS', payload)
        },
        setFormDefinition(context: ActionContext<State, typeof store>, payload: FormDefinition[]) {
            context.commit('SET_FORM_DEFINITIONS', payload)
        },
    },
    getters: {
        getDefinitions: (state: State) => {
            return state.formDefinitions
        },
    },
}

export {
    adminForm
}
