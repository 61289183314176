<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Détails techniques</span>
            </template>
            <template #accordionContent>
                <hr />
                <strong class="p-20">Dates de changement de statuts</strong>
                <StatusChangeDates :orderMissionId="orderMission.id"></StatusChangeDates>
                <hr />
                <strong class="p-20">Pièces jointes</strong>
                <Attachments :documents="orderMission.documents"></Attachments>
                <hr />
                <strong class="p-20">Formulaire</strong>
                <Forms class="m-3" :missionOrder="orderMission"></Forms>
                <hr />
                <strong class="p-20">Points temporels</strong>
                <TimePoints :orderMissionId="orderMission.id"></TimePoints>
                <hr />
            </template>
        </simple-accordion>
    </div>
</template>

<script>
import StatusChangeDates from './technicalDetails/StatusChangeDates.vue'
import Attachments from './technicalDetails/Attachments.vue'
import Forms from './technicalDetails/Forms.vue'
import TimePoints from './technicalDetails/TimePoints.vue'

export default {
    components: {
        StatusChangeDates,
        Attachments,
        Forms,
        TimePoints,
    },
    props: {
        orderMission: {
            type: Object,
            require: true,
        },
    },
}
</script>
