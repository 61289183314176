import backendApi from '../../backend/api'

const defaultState = {
    missionOrders: [],
    updateInProgress: false,
}

export default {
    namespaced: true,
    state: () => Object.assign({}, defaultState),
    mutations: {
        UPDATE_DASHBOARD_DATA(store, payload) {
            for (const property in payload) {
                store[property] = payload[property]
            }
        },
    },
    actions: {
        updateDashboardData(context) {
            context.state.updateInProgress = true

            const missionOrderFilterData = context.rootState.missionOrderFilters

            const params = {
                dateRange: {
                    start: missionOrderFilterData.dateRange.startDate,
                    end: missionOrderFilterData.dateRange.endDate,
                },
                statuses: (() => {
                    const statuses = []
                    for (const status in missionOrderFilterData.selectedStatuses) {
                        if (missionOrderFilterData.selectedStatuses[status]) {
                            statuses.push(status)
                        }
                    }
                    return statuses
                })(),
                suezOperator: {
                    regions: missionOrderFilterData.suezOperator.selected.regions.map((item) => item.code),
                    deltaAgencies: missionOrderFilterData.suezOperator.selected.deltaAgencies.map((item) => item.code),
                    subAgencies: missionOrderFilterData.suezOperator.selected.subAgencies.map((item) => item.code),
                },
                workerAdmin: {
                    providerAgencies: missionOrderFilterData.workerAdmin.selected.providerAgencies.map(
                        (item) => item.id,
                    ),
                },
            }

            return backendApi
                .getDashboardData(context.rootState.login.user.token, params)
                .then((data) => {
                    context.commit('UPDATE_DASHBOARD_DATA', data)
                })
                .catch(() => {})
                .finally(() => {
                    context.state.updateInProgress = false
                })
        },
    },
    getters: {},
}
