<template>
    <div>
        <simple-accordion>
            <template #accordionTitle>
                <span class="me-3">Formulaire pour les données qualifiantes</span>
            </template>
            <template #accordionContent>
                <FormContent class="m-3" :missionOrder="orderMission"></FormContent>
            </template>
        </simple-accordion>
        <blank-modal ref="modalNewFormulaire" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Nouvelle formulaire</legend>
            </template>
            <template #modalBody>
                <fieldset class="uk-fieldset">
                    <div class="uk-margin">
                        <label for="formDefinition">Form définition</label>
                        <select id="formDefinition" class="uk-select" v-model="form.form_definition_id">
                            <option v-for="(it, idx) in formDefinitions" :key="idx" :value="it">
                                {{ it.wording }}
                            </option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label for="data">Data</label>
                        <input id="data" class="uk-input" type="text" v-model="form.data" required />
                    </div>
                </fieldset>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import FormContent from './FormContent.vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        FormContent,
    },
    props: {
        orderMission: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            form: {
                form_definition_id: '',
                data: '',
            },
        }
    },
    methods: {
        onSave() {
            const data = {
                data: this.form.data,
                form_definition_id: this.form.form_definition_id.id,
            }
            this.loading = true
            this.$http
                .createFormOrder(this.orderMission.id, data)
                .then((res) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalNewEvent.$el).hide()
                    this.datas = res
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getAllForms() {
            this.$http.getAllEventsByOsId(this.orderMission.id).then((res) => {
                this.datas = res
            })
        },
        async getAllFormDefinition() {
            const rows = await this.$http.getAllFormDefinition()
            await this.$store.dispatch('adminForm/setFormDefinition', rows)
        },
    },
    computed: {
        ...mapGetters({
            formDefinitions: 'adminForm/getDefinitions',
        }),
    },
    mounted() {
        this.getAllForms()
        this.getAllFormDefinition()
    },
}
</script>
