import { canceled, inProgress, statusSymbol, suspended, toDo } from '@/Enum/Status'
import backendApi from '@/backend/api'
import login from '@/store/modules/login'

Date.prototype.addDays = function (d) {
    return new Date(this.valueOf() + 864e5 * d)
}

/**
 * @typedef {{
 * dateRange: {
 *     endDate: Date,
 *     startDate: Date,
 * },
 * selectedStatuses: {},
 * suezOperator: {
 *     isLoading: boolean,
 *     list: {
 *         subAgencies: *[],
 *         deltaAgencies: *[],
 *         regions: *[],
 *     },
 *     selected: {
 *         subAgencies: *[],
 *         deltaAgencies: *[],
 *         regions: *[],
 *     },
 * },
 * workerAdmin: {
 *     list: {
 *         providerAgencies: *[],
 *     },
 *     selected: {
 *         providerAgencies: *[],
 *     },
 *     isLoading: boolean,
 * },
 * sortBy: {
 *      field: null | string,
 *      order: null | string,
 * },
 * fieldFilters: {
 *     number: null | string,
 *     providerAgency: null | string,
 *     providerName: null | string,
 *     g2CodeWording: null | string,
 *     formattedAddress: null | string,
 *     g2Reference: null | string,
 *     expectedStartDate: null | string,
 * },
 * }} State
 */

/**
 * @return {State}
 */
const defaultState = () => ({
    dateRange: {
        startDate: new Date(),
        endDate: new Date().addDays(5),
    },
    selectedStatuses: (() => {
        let selectedStatuses = { ...statusSymbol }

        Object.keys(selectedStatuses).forEach(function (key) {
            selectedStatuses[key] = false
        })

        selectedStatuses[inProgress] = true
        selectedStatuses[toDo] = true
        selectedStatuses[suspended] = true

        return selectedStatuses
    })(),
    suezOperator: {
        list: {
            regions: [],
            deltaAgencies: [],
            subAgencies: [],
        },
        selected: {
            regions: [],
            deltaAgencies: [],
            subAgencies: [],
        },
        isLoadingData: false,
    },
    workerAdmin: {
        list: {
            providerAgencies: [],
        },
        selected: {
            providerAgencies: [],
        },
        isLoadingData: false,
    },
    sortBy: {
        field: null,
        order: null,
    },
    fieldFilters: {
        number: null,
        providerAgency: null,
        providerName: null,
        g2CodeWording: null,
        formattedAddress: null,
        g2Reference: null,
        expectedStartDate: null,
    },
})

/**
 * @param {State} state
 * @param rootState
 */
const recordState = (state, rootState) => {
    const userId = rootState.login?.user?.userData?.id || 'default'
    localStorage.setItem(`missionOrderFilters_${userId}`, JSON.stringify(state))
}

/**
 * @return {State}
 */
const loadState = () => {
    const userId = login.state()?.user?.userData?.id || 'default'
    let state = localStorage.getItem(`missionOrderFilters_${userId}`)
    if (state) {
        /**
         * @type {typeof State}
         */
        const parsed = JSON.parse(state)

        if (!parsed.dateRange.startDate || !parsed.dateRange.endDate) {
            parsed.dateRange.startDate = null
            parsed.dateRange.endDate = null
        } else {
            parsed.dateRange.endDate = new Date(parsed.dateRange.endDate)
            parsed.dateRange.startDate = new Date(parsed.dateRange.startDate)
        }

        return parsed
    }
    return defaultState()
}

/**
 * @type {Module<State>}
 */
export default {
    namespaced: true,
    state: () => loadState(),
    mutations: {
        /**
         * @param state {State}
         * @param payload {State.dateRange}
         */
        setDateRange(state, payload) {
            state.dateRange = Object.assign(state.dateRange, payload)
        },
        /**
         * @param state {State}
         * @param payload {State.selectedStatuses}
         */
        setSelectedStatuses(state, payload) {
            state.selectedStatuses = Object.assign(state.selectedStatuses, payload)
        },
        /**
         * @param state {State}
         */
        clearDateRange(state) {
            state.dateRange.endDate = null
            state.dateRange.startDate = null
        },
        /**
         * @param state {State}
         */
        resetStateToDefault(state) {
            Object.assign(state, defaultState())
        },

        /**
         * @param state {State}
         * @param regions {State.suezOperator.list.regions}
         */
        setRegions(state, regions) {
            state.suezOperator.list.regions = regions
        },

        /**
         * @param state {State}
         * @param agencies {State.suezOperator.list.agencies}
         */
        setDeltaAgencies(state, agencies) {
            state.suezOperator.list.deltaAgencies = agencies
        },

        /**
         * @param state {State}
         * @param subAgencies {State.suezOperator.list.subAgencies}
         */
        setSubAgencies(state, subAgencies) {
            state.suezOperator.list.subAgencies = subAgencies
        },

        /**
         * @param state {State}
         * @param payload {State.suezOperator.isLoading}
         */
        setLoadingDataForSuezOperator(state, payload) {
            state.suezOperator.isLoadingData = payload
        },

        /**
         * @param state {State}
         * @param selected {State.suezOperator.selected.regions}
         */
        mutateRegionsForSuezOperator(state, selected) {
            state.suezOperator.selected.regions = selected
        },

        /**
         * @param state {State}
         * @param selected {State.suezOperator.selected.deltaAgencies}
         */
        mutateDeltaAgenciesForSuezOperator(state, selected) {
            state.suezOperator.selected.deltaAgencies = selected
        },

        /**
         * @param state {State}
         * @param selected {State.suezOperator.selected.subAgencies}
         */
        mutateSubAgenciesForSuezOperator(state, selected) {
            state.suezOperator.selected.subAgencies = selected
        },

        /**
         * @param state {State}
         * @param providerAgencies {State.workerAdmin.list.providerAgencies}
         */
        setProviderAgencies(state, providerAgencies) {
            state.workerAdmin.list.providerAgencies = providerAgencies
        },

        /**
         * @param state {State}
         * @param selected {State.workerAdmin.selected.providerAgencies}
         */
        setSelectedProviderAgencies(state, selected) {
            state.workerAdmin.selected.providerAgencies = selected
        },

        /**
         * @param state {State}
         * @param payload {boolean}
         */
        setLoadingDataForWorkerAdmin(state, payload) {
            state.workerAdmin.isLoadingData = payload
        },

        /**
         * @param state {State}
         * @param payload {State.sortBy}
         */
        setSortBy(state, payload) {
            state.sortBy = payload
        },

        /**
         * @param state {State}
         * @param payload {{field: string, value: string}}
         */
        setFieldFilters(state, payload) {
            state.fieldFilters[payload.field] = payload.value
        },
    },
    actions: {
        /**
         * @param context
         */
        recordState({ state, dispatch, rootState }) {
            recordState(state, rootState)
            dispatch(
                'notifications/addNotification',
                {
                    status: 'success',
                    message: 'Filtres enregistrés',
                },
                { root: true },
            )
        },
        async loadDataForSuezOperator({ commit, state, dispatch }) {
            commit('setLoadingDataForSuezOperator', true)
            return await backendApi
                .getRegionsByUser()
                .then((response) => {
                    commit('setRegions', response)
                })
                .catch((error) => {
                    dispatch(
                        'notifications/addNotification',
                        {
                            status: 'danger',
                            message: 'Erreur de chargement des régions : ' + error.message,
                        },
                        { root: true },
                    )
                })
                .then(() => backendApi.getAgenciesByRegions(state.suezOperator.list.regions.map((item) => item.id)))
                .then((response) => {
                    commit('setDeltaAgencies', response)
                })
                .catch((error) => {
                    dispatch(
                        'notifications/addNotification',
                        {
                            status: 'danger',
                            message: 'Erreur de chargement des agences delta : ' + error.message,
                        },
                        { root: true },
                    )
                })
                .then(() =>
                    backendApi.getSubagenciesByAgencies(state.suezOperator.list.deltaAgencies.map((item) => item.id)),
                )
                .then((response) => {
                    commit('setSubAgencies', response)
                })
                .catch((error) => {
                    dispatch(
                        'notifications/addNotification',
                        {
                            status: 'danger',
                            message: 'Erreur de chargement des sous agences : ' + error.message,
                        },
                        { root: true },
                    )
                })
                .finally(() => commit('setLoadingDataForSuezOperator', false))
        },
        setRegionsForSuezOperator({ commit, state, dispatch }, selected) {
            commit('mutateRegionsForSuezOperator', selected)
            const deltaAgencies = state.suezOperator.selected.deltaAgencies.filter((item) =>
                selected.map((region) => region.id).includes(item.region.id),
            )
            dispatch('setDeltaAgenciesForSuezOperator', deltaAgencies)
        },
        setDeltaAgenciesForSuezOperator({ commit, state, dispatch }, selected) {
            commit('mutateDeltaAgenciesForSuezOperator', selected)
            const subAgencies = state.suezOperator.selected.subAgencies.filter((item) =>
                selected.map((deltaAgency) => deltaAgency.id).includes(item.deltaAgency.id),
            )
            dispatch('setSubAgenciesForSuezOperator', subAgencies)
        },
        setSubAgenciesForSuezOperator({ commit }, selected) {
            commit('mutateSubAgenciesForSuezOperator', selected)
        },

        async loadDataForWorkerAdmin({ commit, dispatch }) {
            commit('setLoadingDataForWorkerAdmin', true)
            return await backendApi
                .getAgenciesByUser()
                .then((response) => {
                    commit('setProviderAgencies', response)
                })
                .catch((error) => {
                    dispatch(
                        'notifications/addNotification',
                        {
                            status: 'danger',
                            message: 'Erreur de chargement des agences fournisseur : ' + error.message,
                        },
                        { root: true },
                    )
                })
                .finally(() => commit('setLoadingDataForWorkerAdmin', false))
        },
    },
    getters: {
        selectedStatuses: (state, getters, rootState, rootGetters) => {
            const isWorkerAdmin = rootGetters['login/isWorkerAdmin']
            const isWorker = rootGetters['login/isWorker']
            if (isWorker && !isWorkerAdmin) {
                const statuses = Object.assign({}, state.selectedStatuses)
                delete statuses[canceled]

                return statuses
            }

            return Object.assign({}, state.selectedStatuses)
        },
        regionsList(state) {
            return state.suezOperator.list.regions
        },
        deltaAgenciesList(state) {
            return state.suezOperator.list.deltaAgencies.filter((item) =>
                state.suezOperator.selected.regions.map((region) => region.id).includes(item.region.id),
            )
        },
        subAgenciesList(state) {
            return state.suezOperator.list.subAgencies.filter((item) =>
                state.suezOperator.selected.deltaAgencies
                    .map((deltaAgency) => deltaAgency.id)
                    .includes(item.deltaAgency.id),
            )
        },
        providerAgenciesList(state) {
            return state.workerAdmin.list.providerAgencies
        },
    },
}
