<template>
    <div class="card">
        <h5 class="card-title p-1 small">
            <div
                v-if="
                    isSuez &&
                    message.fromUser.accountType === 'workerAccount' &&
                    !message.fromUser.roles.includes('admin')
                "
            >
                {{ messageSenderFormattedForSuezOperator }}
            </div>
            <div v-else>
                De {{ message.fromUser.username }} - {{ message.fromUser.userInfos.firstName }}
                {{ message.fromUser.userInfos.lastName }}
            </div>
        </h5>
        <h6 class="card-subtitle mb-2 text-muted p-1 small">Le {{ message.createdAt | humanReadableDate }}</h6>
        <hr class="mt-0 mb-1" />
        <p class="card-text p-1 small" style="white-space: pre-line">{{ message.value }}</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ChatMessage',
    props: {
        message: {
            required: false,
            default: () => ({}),
        },
        missionOrder: {
            required: false,
        },
    },
    computed: {
        ...mapGetters('login', ['isSuez']),
        messageSenderFormattedForSuezOperator() {
            return 'Agent - ' + this.missionOrder.agency.name
        },
    },
}
</script>

<style scoped></style>
