import backendApi from '../../backend/api'

export default {
    namespaced: true,
    state: () => ({
        domains: [],
    }),
    mutations: {
        SET_DOMAINS(state, domains) {
            state.domains = domains
        },
    },
    actions: {
        async updateDomains(context) {
            backendApi
                .getAllDomainesWithChild()
                .then((domains) => {
                    context.commit('SET_DOMAINS', domains)
                })
                .catch(() => {})
        },
    },
    getters: {},
}
