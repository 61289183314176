<template>
    <div>
        <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
            <vk-nav-item-header title="Interventions" style="color: white" />
            <li>
                <a
                    href="#"
                    @click.prevent="
                        $router.push({ name: 'mission_orders' })
                        $emit('nav:close')
                    "
                    style="color: white"
                    ><vk-icon icon="nut" class="uk-margin-small-right" />Liste des interventions</a
                >
            </li>
            <vk-nav-item-divider />
            <li class="uk-parent">
                <vk-nav-item-header title="Administration" style="color: white" />
                <a href="#" style="color: white">
                    <i class="fa-regular fa-calendar uk-margin-small-right"></i>
                    Evenement
                </a>
                <ul class="uk-nav-sub">
                    <vk-nav-item
                        @click="
                            $router.push({ name: 'event' })
                            $emit('nav:close')
                        "
                        title="Administrer l'évenement"
                    />
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    emits: ['nav:close'],
    computed: {
        ...mapState('login', ['user']),
    },
    methods: {
        ...mapActions('login', ['logout']),
    },
}
</script>

<style>
.uk-nav-default .uk-nav-sub a {
    color: #999 !important;
}
</style>
