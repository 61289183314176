<template>
    <div>
        <div class="card mb-3" style="max-height: 50vh; overflow-x: hidden; overflow-y: scroll">
            <ChatMessage
                :missionOrder="missionOrder"
                class="m-3"
                v-for="(message, index) in chat.messages"
                :key="'-' + index"
                :message="message"
            />
        </div>
        <div v-if="canSendMessage && !$store.getters['login/isAdminFunc']">
            <div class="mb-3">
                <textarea
                    :disabled="newMessageIsSending"
                    class="form-control"
                    rows="5"
                    placeholder="Votre message"
                    v-model="newMessage"
                ></textarea>
            </div>
            <div class="text-end">
                <button :disabled="newMessageIsSending" class="btn btn-primary" @click="sendNewMessage">
                    Envoyer
                    <div v-if="newMessageIsSending" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ChatMessage from '@/components/ChatMessage'
import * as Status from '@/Enum/Status'
const STATUS_CAN_SEND_MESSAGE = [Status.ended, Status.permanentlySuspended, Status.canceled]
export default {
    name: 'Chat',
    components: { ChatMessage },
    emits: ['message:new'],
    data: () => ({
        newMessage: '',
        newMessageIsSending: false,
    }),
    props: {
        chat: {
            required: false,
            default: () => ({
                id: null,
                messages: [],
            }),
        },
        missionOrder: {
            required: false,
        },
    },
    methods: {
        sendNewMessage() {
            this.newMessageIsSending = true
            setTimeout(() => {
                this.$emit('message:new', this.newMessage, this.newMessageResult)
            }, 150 /* slow down process a little => more user-friendly */)
        },
        newMessageResult(isSuccess) {
            if (isSuccess) {
                this.newMessage = ''
            }
            this.newMessageIsSending = false
        },
    },
    computed: {
        canSendMessage() {
            return !STATUS_CAN_SEND_MESSAGE.includes(this.missionOrder.status.value)
        },
    },
}
</script>

<style scoped></style>
