<template>
    <div>
        <div class="d-flex justify-content-end">
            <div class="margin" v-if="canDeclareEvent">
                <icon type="ADD_EVENT" @click="onAddEventClick"></icon>
            </div>
            <div class="margin" v-if="$store.getters['login/isSuez'] || $store.getters['login/isAdminFunc']">
                <icon type="REPORT" @click="downloadWorkReport"></icon>
            </div>
            <div class="margin" v-if="$store.getters['login/isWorker'] && isTerminable">
                <icon type="TERMIN" @click="confirmTermin"></icon>
            </div>
            <div class="margin" v-if="$store.getters['login/isWorker'] && missionOrder.status.value === 'À faire'">
                <icon type="ARRIVE" @click="declareMyArrival"></icon>
            </div>
            <div class="margin" v-if="$store.getters['login/isWorker'] && isInProgress && !isSiteSituationReportDone">
                <icon type="ETATLI" @click="createInventory"></icon>
            </div>
            <div
                class="margin"
                v-if="
                    $store.getters['login/isWorker'] && isInProgress && isSiteSituationReportDone && !isSiteMarkupDone
                "
            >
                <icon type="BALISE" @click="makeMarkup"></icon>
            </div>
            <div
                class="margin"
                v-if="
                    $store.getters['login/isWorker'] &&
                    isInProgress &&
                    isMarkingAndPicketingCanBeTriggered &&
                    isSiteMarkupDone &&
                    !isSiteMarkingPicketingDone
                "
            >
                <icon type="MARQUA" @click="makeMarkingAndPique" style="margin-right: 8px"></icon>
            </div>
            <div class="margin" v-if="$store.getters['login/isWorker'] && isInProgress && isSiteMarkingPicketingDone">
                <icon type="METRES" @click="makeMeasurement"></icon>
            </div>
            <div class="margin" v-if="$store.getters['login/isWorker'] && canRestartIntervention">
                <icon type="REPREN" @click="resumeIntervention"></icon>
            </div>
        </div>

        <div class="m-1 mb-3">
            <div class="mb-3" v-if="$store.getters['login/isWorkerAdmin'] && isTerminated">
                <a class="btn btn-success" target="_blank" :href="perfeauGeneraleLink(missionOrder.osNumero)">
                    Voir l'OS dans perfeau
                </a>
            </div>
            Libellé d'intervention : {{ missionOrder.g2_inter_code_wording }} <br />
            Numéro de l'ordre de service : {{ missionOrder.osNumero }} <br />
            Statut de l'ordre de service : {{ missionOrder.status.value }} <br />
            <div v-if="$store.getters['login/isSuez'] && statusSignatureRequired">
                Signature requise :
                <input
                    class="uk-checkbox"
                    :disabled="missionOrder.status.value === 'Terminé'"
                    type="checkbox"
                    v-model="missionOrder.is_signature_required"
                    @change="updateIsSignatureRequired()"
                />
            </div>
        </div>
        <AddressAccordion class="mb-3" :order-mission="missionOrder" />
        <InterventionDatesAccordion class="mb-3" :order-mission="missionOrder" />
        <ExternalCommentAccordion class="mb-3" :order-mission="missionOrder" />
        <ContactAccordion class="mb-3" :contacts="contactSuez" />
        <DocumentsViewAndLoad
            class="mb-3"
            :mission-order="missionOrder"
            :documents="missionOrder.documents"
            :add-documents-method="addDocuments"
            :save-edited-image-method="saveEditedImage"
            :edit-document-name-func="editDocumentName"
            :delete-document-func="deleteDocument"
        />
        <ChatAccordion
            class="mb-3"
            :chat="missionOrder.mainChat"
            :missionOrder="missionOrder"
            accordion-title="Messagerie"
            @message:new="recordNewMessageToMainChat"
            @chat:accordion:open="updateMainChat"
        />
        <EventAccordion class="mb-3" :order-mission="missionOrder" @event-added="onRefresh" ref="event">
        </EventAccordion>
        <FormAccordion class="mb-3" :order-mission="missionOrder"> </FormAccordion>
        <TechnicalDetail class="mb-3" :order-mission="missionOrder" v-if="$store.getters['login/isAdminFunc']">
        </TechnicalDetail>
        <div style="display: flex; justify-content: end; margin-right: 1rem">
            <div class="c-col"></div>
            <div class="" v-if="missionOrder.signature">
                <img :src="signatureB64" />
                <!-- <i v-if="!loading" class="fa-regular fa-circle-down" @click="downloadDocument(props.row.signature.randomIdentifier, props.row.signature.originalName, props.row.signature.size)"></i>
                <div v-if="loading" class="loading uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div> -->
            </div>
        </div>

        <blank-modal @close="onCloseEvent" ref="modalConfirm" :modal-id="$util.htmlId()" close-text="Non">
            <template #modalHeader>
                <legend class="uk-legend">Confirmation</legend>
            </template>
            <template #modalBody> Voulez vous signaler une information sur la fin du chantier ? </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="terminerChantier">Oui</button>
            </template>
        </blank-modal>
        <blank-modal ref="modalSignature" :modal-id="$util.htmlId()">
            <template #modalHeader> </template>
            <template #modalBody>
                <signature></signature>
            </template>
        </blank-modal>
        <blank-modal ref="workReport" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <p>
                    Rapport de travaux <br />
                    Objet: {{ missionOrder.osNumero }}
                </p>
            </template>
            <template #modalBody>
                <div class="c-center">
                    <icon
                        style="margin-right: 20px"
                        type="DOC"
                        :loading="loadingWord"
                        @click="downloadReport('Word2007')"
                    ></icon>
                    <icon
                        style="margin-left: 20px"
                        type="PDF"
                        :loading="loadingPdf"
                        @click="downloadReport('PDF')"
                    ></icon>
                </div>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import ChatAccordion from '@/components/ChatAccordion'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import backendApi from '@/backend/api'
import store from '@/store'
import DocumentsViewAndLoad from '@/components/DocumentsViewAndLoad'
import ContactAccordion from '@/components/ContactAccordion'
import EventAccordion from '@/components/events/EventAccordion'
import FormAccordion from '@/components/forms/FormAccordion'
import * as Status from '@/Enum/Status'
import AddressAccordion from '@/components/AddressAccordion'
import InterventionDatesAccordion from '@/components/InterventionDatesAccordion'
import ExternalCommentAccordion from '@/components/ExternalCommentAccordion'
import TechnicalDetail from '@/components/forms/TechnicalDetail'
import { PerfeauUrlGenerator } from '@/helpers/perfeau/perfeauUrlGenerator'

const STATUS_SIGNATURE_REQUIRED = [Status.inProgress, Status.toDo, Status.suspended, Status.ended]
export default {
    name: 'MissionOrder',
    components: {
        ContactAccordion,
        DocumentsViewAndLoad,
        ChatAccordion,
        EventAccordion,
        FormAccordion,
        AddressAccordion,
        InterventionDatesAccordion,
        ExternalCommentAccordion,
        TechnicalDetail,
    },
    data() {
        return {
            EVENT_STATUS: ['À faire', 'En cours', 'Suspendu'],
            loadingPdf: false,
            loadingWord: false,
            signatureB64: undefined,
            contactSuez: {
                name: this.missionOrder.osNomResponsable,
                telephone: '',
            },
        }
    },
    props: {
        missionOrder: {
            required: false,
            default: () => ({
                id: null,
                property: {
                    id: null,
                    name: '',
                    address: {
                        id: null,
                        formattedAddress: '',
                    },
                },
                mainChat: {
                    id: null,
                },
                status: [],
                notes: [],
                documents: [],
                assignedToSuperManager: null,
                assignedToWorker: null,
                closedAt: null,
                closedReason: null,
                createdAt: '',
                updatedAt: '',
            }),
        },
    },
    methods: {
        perfeauGeneraleLink: PerfeauUrlGenerator.generateOsLinkUrl,
        ...mapActions('notifications', ['addNotification']),
        ...mapActions('missionOrders', ['updateMainChat']),
        ...mapMutations('missionOrders', ['UPDATE_DOCUMENTS']),
        /**
         * @param data {{afterSubmit: ('comment' | 'terminated') | undefined}}
         */
        onRefresh(data) {
            this.$emit('refresh', data)
        },
        updateIsSignatureRequired() {
            const data = { is_signature_required: this.missionOrder.is_signature_required }
            this.$http
                .updateIsSignatureRequired(this.missionOrder.id, data)
                .then(() => {
                    this.$store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Mis à jour effectuée avec succès',
                    })
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        getSignature(document) {
            backendApi
                .downloadDocument(store.state.login.user.token, document.randomIdentifier, document.originalName, false)
                .then(async (file) => {
                    const b64 = await this.$util.fileTob64(file)
                    this.signatureB64 = b64
                })
        },
        recordNewMessageToMainChat(message, newMessageResult) {
            backendApi
                .addMessageToMissionOrderMainChat(store.state.login.user.token, this.missionOrder.id, message)
                .then(() => {
                    this.updateMainChat()
                    this.addNotification({
                        message: 'Message envoyé',
                        status: 'success',
                    })
                    newMessageResult(true)
                })
                .catch((error) => {
                    newMessageResult(false)
                    backendApi.notificationOnError(error).catch(() => {})
                })
        },
        addDocuments(filesUploadedToAdd) {
            return backendApi
                .addDocumentsToMissionOrder(store.state.login.user.token, this.missionOrder.id, filesUploadedToAdd)
                .then((data) => {
                    this.UPDATE_DOCUMENTS(data.documents)
                })
                .catch(backendApi.notificationOnError)
        },
        saveEditedImage(file, identifier) {
            return backendApi
                .uploadFile(store.state.login.user.token, { file: file }, identifier)
                .then((data) =>
                    backendApi.addDocumentsToMissionOrder(store.state.login.user.token, this.missionOrder.id, [data]),
                )
                .then((data) => {
                    this.UPDATE_DOCUMENTS(data.documents)
                })
                .catch(backendApi.notificationOnError)
        },
        editDocumentName(document, newName) {
            return backendApi
                .renameMissionOrderDocument(store.state.login.user.token, this.missionOrder.id, document.id, newName)
                .then((data) => {
                    this.UPDATE_DOCUMENTS(data.documents)
                })
                .catch(backendApi.notificationOnError)
        },
        deleteDocument(document) {
            return backendApi
                .deleteMissionOrderDocument(store.state.login.user.token, this.missionOrder.id, document.id)
                .then((data) => {
                    this.UPDATE_DOCUMENTS(data.documents)
                })
                .catch(backendApi.notificationOnError)
        },
        confirmTermin() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalConfirm.$el).show()
        },
        terminerChantier() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalConfirm.$el).hide()
            const found =
                this.missionOrder.mission_order_form_data &&
                this.missionOrder.mission_order_form_data.data.find((m) => m.required && !m.data)
            if (found) {
                this.$store.dispatch(
                    'notifications/showWarning',
                    'Veuillez remplir tous les champs requis dans le formulaire',
                )
            } else {
                this.$refs.event.terminerChantier()
            }
        },
        onCloseEvent() {
            const found =
                this.missionOrder.mission_order_form_data &&
                this.missionOrder.mission_order_form_data.data.find((m) => m.required && !m.data)
            if (found) {
                this.$store.dispatch(
                    'notifications/showWarning',
                    'Veuillez remplir tous les champs requis dans le formulaire',
                )
            } else {
                this.$refs.event.definitelyEnd()
            }
        },
        createInventory() {
            this.$refs.event.createInventory()
        },
        makeMarkup() {
            this.$refs.event.makeMarkup()
        },
        declareMyArrival() {
            this.$refs.event.declareMyArrival()
        },
        makeMarkingAndPique() {
            this.$refs.event.makeMarkingAndPique()
        },
        makeMeasurement() {
            this.$refs.event.makeMeasurement()
        },
        resumeIntervention() {
            this.$refs.event.resumeIntervention()
        },
        onAddEventClick() {
            this.$refs.event.openAddEventModal()
        },
        downloadWorkReport() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.workReport.$el).show()
        },
        downloadReport(format) {
            format === 'Word2007' ? (this.loadingWord = true) : (this.loadingPdf = true)
            this.$http
                .workReport(this.missionOrder.id, format)
                .then((res) => {
                    const b64 =
                        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + res.file
                    const reportName =
                        this.missionOrder.osNumero + '_' + this.$util.formatDate(new Date(), 'DD/MM/YYYY', true)
                    this.$util.downloadBase64(b64, `${reportName}.${format === 'Word2007' ? 'docx' : 'pdf'}`)
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.workReport.$el).hide()
                    this.loadingWord = false
                    this.loadingPdf = false
                })
                .catch((e) => {
                    this.loadingWord = false
                    this.loadingPdf = false
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
    },
    computed: {
        ...mapGetters('login', ['isSuez', 'isWorker', 'isAdminFunc']),
        ...mapState('adminEvent', {
            eventList: 'types',
        }),
        isSiteSituationReportDone() {
            return !!this.missionOrder.events.findLast((item) => item.event_definition.code === 'ETATLI')
        },
        isSiteMarkupDone() {
            return !!this.missionOrder.events.findLast((item) => item.event_definition.code === 'BALISE')
        },
        isSiteMarkingPicketingDone() {
            return !!this.missionOrder.events.findLast((item) => item.event_definition.code === 'MARQUA')
        },
        isInProgress() {
            return this.missionOrder.status.value === Status.inProgress
        },
        isTerminated() {
            return this.missionOrder.status.value === Status.ended
        },
        canRestartIntervention() {
            return Status.suspended === this.missionOrder.status.value
        },
        canDeclareEvent() {
            if (this.isAdminFunc) {
                return false
            }

            return (
                this.eventList.filter((item) => {
                    if (item.event_family.event_action.code !== 'EVT') {
                        return false
                    }

                    if (this.isSuez && !item.available_for_agency_profile) {
                        return false
                    }

                    if (this.isWorker && !item.available_for_worker_profile) {
                        return false
                    }

                    if (this.isAdminFunc) {
                        return false
                    }

                    return !!item.status.find((status) => {
                        return (
                            this.missionOrder.status.value === status.value || this.missionOrder.status.id === status.id
                        )
                    })
                }).length > 0
            )
        },
        statusSignatureRequired() {
            return STATUS_SIGNATURE_REQUIRED.includes(this.missionOrder.status.value)
        },
        isMarkingAndPicketingCanBeTriggered() {
            return ['ON', 'OPTIONAL'].includes(this.missionOrder.standardCode.markingAndPicketingValue)
        },
        isMarkingAndPicketingRequired() {
            return this.missionOrder.standardCode.markingAndPicketingValue === 'ON'
        },
        isTerminable() {
            if (!this.isInProgress) {
                return false
            }

            if (!this.isMarkingAndPicketingRequired && this.isSiteMarkupDone) {
                return true
            }

            return this.isMarkingAndPicketingRequired && this.isSiteMarkingPicketingDone
        },
    },
    mounted() {
        if (this.missionOrder && this.missionOrder.signature) {
            this.getSignature(this.missionOrder.signature)
        }
    },
}
</script>

<style scoped>
.margin {
    margin: 4px;
}
</style>
