import backendApi from '../../backend/api'

export default {
    namespaced: true,
    state: () => ({
        list: [],
        listIsUpdating: false,
    }),
    mutations: {
        UPDATE_ABSENCES(store, payload) {
            store.list = payload
        },
    },
    actions: {
        updateAbsencesList(context) {
            context.state.listIsUpdating = true
            return backendApi
                .getAbsencesList()
                .then((data) => {
                    context.commit('UPDATE_ABSENCES', data)
                })
                .catch(backendApi.notificationOnError)
                .catch(() => {
                    context.commit('UPDATE_ABSENCES', [])
                })
                .finally(() => {
                    context.state.listIsUpdating = false
                })
        },
    },
}
